import React, { useState } from 'react'
import ApiBaseUrl from '../../http/ApiBaseUrl';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { MultiSelect } from 'react-multi-select-component';
import NavVertical from '../navVertical/NavVertical';
import { useEffect } from 'react';
import axios from 'axios';
import { ExportToExcel } from '../modules/ExportToExcel/ExportToExcel';
import Axios from 'axios';
import moment from 'moment';

const FiltrePriseRendezVous = () => {
    const requestConfig = {
        headers: {
            Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
            'Content-Type' : 'application/json; charset=UTF-8',
        },
    }
    const [selectedVilles, setSelectedVilles] = useState([]);
    const [fields, setFields] = useState({}); 
    const [errors, setErrors] = useState([]); 
    const [is_empty_event_type, set_is_empty_event_type] = useState(false);
    const [is_empty_filter_start_date, set_is_empty_filter_start_date] = useState(false);
    const [is_empty_filter_end_date, set_is_empty_filter_end_date] = useState(false);
    const [is_empty_selected_villes, set_is_empty_selected_villes] = useState(false);
    const [filteredCheckouts, setFilteredCheckouts] = useState([]);
    const [filteredEventType, setFilteredEventType] = useState();
    const [filteredPeriod, setFilteredPeriod] = useState();
    const [filterInProgress, setFilterInProgress] = useState(false);
    const [villes, setVilles] = useState([]);



    const onGetVilles = () => {
        axios.get(ApiBaseUrl + "villes", requestConfig)
          .then((response) => {
            const resp = response.data;
            if (resp.success) {
                setVilles(resp.data)
            } else {
                console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
            // if (error.message === "Request failed with status code 401") {
            //   this.setState({
            //     isSessionExpiration: true,
            //   });
            // }
          });
    };

    useEffect(() => {
        onGetVilles();
    }, []);

    const FilterAndExportHandleChange = (e) => {
        let _fields = fields;
        _fields[e.target.name] = e.target.value;
        // _fields["selected_villes"] = selectedVilles;
        _fields['filter_type'] = 'intervalle_date';

        setFields(_fields);
        console.log('fields', _fields)
    };
    
    const ValidateFilterAndExportForm = () => {
        let _fields = fields;
        _fields["selected_villes"] = selectedVilles;
        let _errors = errors;
        var isValidForm = true;

        if (!_fields["event_type"]) {
            isValidForm = false;
            _errors["event_type"] = "Ce champ est requis.";
            set_is_empty_event_type(true);
            setTimeout(() => {
                set_is_empty_event_type(false);
            }, 5000);
        }
        if (!_fields["filter_start_date"]) {
            isValidForm = false;
            _errors["filter_start_date"] = "Ce champ est requis.";
            set_is_empty_filter_start_date(true);
            setTimeout(() => {
                set_is_empty_filter_start_date(false);
            }, 5000);
        }
        if (!_fields["filter_end_date"]) {
            isValidForm = false;
            _errors["filter_end_date"] = "Ce champ est requis.";
            set_is_empty_filter_end_date(true);
            setTimeout(() => {
                set_is_empty_filter_end_date(false);
            }, 5000);
        }
        if (!_fields["selected_villes"] || selectedVilles?.length === 0) {
            isValidForm = false;
            _errors["selected_villes"] = "Ce champ est requis.";
            set_is_empty_selected_villes(true);
            setTimeout(() => {
                set_is_empty_selected_villes(false);
            }, 5000);
        }

        setErrors(_errors);

        return isValidForm;
    };
    
    const onSubmitFilterAndExportForm = () => {
        if (ValidateFilterAndExportForm()) {
            setFilterInProgress(true);
            let data = fields;
            data['filter_type'] = "intervalle_date";
            console.log('data', data)
        
            Axios.post(`${ApiBaseUrl}filter-event-to-export`, data, requestConfig)
                .then((response) => {
                    const rep = response.data;
                    setFilterInProgress(false);
                    if (rep.success) {
                        setFilteredCheckouts(rep.data);
                        setFilteredEventType(rep.event_type);
                        setFilteredPeriod(rep.period);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    setFilterInProgress(false);
                });
        }
    };

    const options = {
        noDataText: "Aucune donnée disponible",
    };

    const ExportFilteredCheckoutsToExcel = () => {
        const exportLabels = [
            { label: "Prénom et Nom", value: "fullname" },
            { label: "Téléphone", value: "telephone" },
            { label: "Date de RDV", value: "date" },
            { label: "Horaire", value: "horaire" },
            { label: "Antenne Campus France", value: "antenne" },
            { label: "Numéro de Compte Étude France", value: "numero_compte_etude_fr"},
            { label: "Numéro dossier d'auto-évaluation", value: "numero_dossier_auto_evaluation"},
        ];
    
        return (
            <div className="filtered-checkout-export-btn-container row">
                <div
                    className="col-lg-4 linkable export-filtered-checkout-to-excel"
                    disabled={filteredCheckouts?.length === 0 && true}
                >
                    <ExportToExcel
                        filteredPeriod={filteredPeriod}
                        filteredEventType={filteredEventType}
                        isFilteredCheckout={true}
                        exportData={filteredCheckouts}
                        exportLabels={exportLabels}
                    />
                </div>
            </div>
        );
    };

    const horaireFormatter = (cell, row) => {
        return moment(cell)?.format("H:mm")
    };

    return (
        <div className="component-orientation-remplissage-manager">
            <HeaderOutilsEtudiant isAdminDashboard={true} />
    
            <section id="cd_container" className="s-h-primo-1">
                <div className="bordure-bas">
                    <div className="row p-block-20 headering-container">
                        <div className="col-md-12 d-flex justify-content-center">
                        <div className="logo-soushaulle">
                            <p className="mb-0 bold color-white mb-3">
                                Filtrer les prises de rendez-vous
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="ligne mt-3 mb-5"></div>
                </div>
    
                <div className="row orm-row">
                    <div className="nav-container nav-dashboard-color">
                        <NavVertical />
                    </div>
                    <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                        <div className="row page-content-row">
                            <div className="col-lg-6 offset-lg-3 mparticipant-col">
                                <div className="mparticipants-filter-container">
                                    <form id="multiple-generation-form">
                                        <div className="v2-admin-modal-body">
                                            <h5 className="filter-mparticipant-form-label">
                                                Filtrage des prises de Rendez-vous
                                            </h5>
                                            <div className="v2-admin-modal-body-content">
                                                <div className="row v2-admin-modal-row">
                                                    <div className="col-md-12 o-form-col">
                                                        <div className="form-group o-form-group">
                                                            <label htmlFor="session_type">
                                                                Choisir un type de RDV{" "}
                                                                <span className="required-icon">*</span>
                                                            </label>
                                                            <select
                                                                onChange={FilterAndExportHandleChange}
                                                                name="event_type"
                                                                className="form-control oe-form-control v2-select-form-control"
                                                                id="session_type"
                                                            >
                                                                <option>
                                                                --Choisir un type de rencontre--
                                                                </option>
                                                                <option value="Orientation Collective">
                                                                {" "}
                                                                Session d'orientation collective{" "}
                                                                </option>
                                                                <option value="Aide au remplissage">
                                                                {" "}
                                                                Atelier d'aide au remplissage{" "}
                                                                </option>
                                                                <option value="Rendez-vous individuel d'informations">
                                                                {" "}
                                                                Rendez-vous individuel d'informations{" "}
                                                                </option>
                                                                <option value="Rendez-vous de complément de dossier">
                                                                {" "}
                                                                Rendez-vous de complément de dossier{" "}
                                                                </option>
                                                                <option value="Séance de préparation au séjour en France">
                                                                {" "}
                                                                Séance de préparation au séjour en France{" "}
                                                                </option>
                                                                <option value="Aide à la construction du projet">
                                                                Aide à la construction du projet
                                                                </option>
                                                                <option value="Entretien Individuel d'orientation">
                                                                Entretien Individuel d'orientation
                                                                </option>
                                                                <option value="Rendez-vous dépôt et retrait dossier recours">
                                                                Rendez-vous dépôt et retrait dossier recours
                                                                </option>
                                                            </select>
                                                        </div>
                                                        {is_empty_event_type && (
                                                            <div className="alert alert-danger oe-alert-danger">
                                                                {errors?.event_type}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-12 o-form-col">
                                                        <div className="form-group o-form-group">
                                                            <label htmlFor="date_debut_filtre">
                                                                Date de début
                                                            </label>
                                                            <input
                                                                onChange={FilterAndExportHandleChange}
                                                                type="date"
                                                                placeholder="Date de début"
                                                                className="form-control oe-form-control"
                                                                name="filter_start_date"
                                                                id="date_debut_filtre"
                                                            />
                                                        </div>
                                                        {is_empty_filter_start_date && (
                                                            <div className="alert alert-danger oe-alert-danger">
                                                                {errors?.filter_start_date}
                                                            </div>
                                                        )}
                                                    </div>
                    
                                                    <div className="col-md-12 o-form-col">
                                                        <div className="form-group o-form-group">
                                                            <label htmlFor="date_fin_filtre">
                                                                Date de fin
                                                            </label>
                                                            <input
                                                                onChange={FilterAndExportHandleChange}
                                                                type="date"
                                                                placeholder="Date de fin"
                                                                className="form-control oe-form-control"
                                                                name="filter_end_date"
                                                                id="date_fin_filtre"
                                                            />
                                                        </div>
                                                        {is_empty_filter_end_date && (
                                                            <div className="alert alert-danger oe-alert-danger">
                                                                {errors.filter_end_date}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-md-12 o-form-col">
                                                        <div className="form-group o-form-group">
                                                            <label htmlFor="date_debut_filtre">
                                                                Antenne Campus France
                                                            </label>
                                                            <MultiSelect
                                                                options={villes && villes?.map((ville) => {
                                                                    return {
                                                                        label: `${ville?.name}`,
                                                                        value: `${ville?.id}`,
                                                                    };
                                                                })}
                                                                className="form-control"
                                                                id="candidats-select-dropdown"
                                                                value={selectedVilles}
                                                                onChange={(e) => setSelectedVilles(e)}
                                                                labelledBy="Choisir une ou des antennes"
                                                                overrideStrings={{
                                                                    allItemsAreSelected:
                                                                        "Tous les candidats choisis",
                                                                    clearSearch: "Effacer la recherche",
                                                                    clearSelected: "Effacer la sélection",
                                                                    noOptions: "Aucun candidat",
                                                                    search: "Chercher",
                                                                    selectAll: "Tout sélectionner",
                                                                    selectAllFiltered:
                                                                        "Tout sélectionner (Filtré)",
                                                                    selectSomeItems: "Choisir...",
                                                                    create: "Créer",
                                                                }}
                                                            />
                                                        </div>
                                                        {is_empty_selected_villes && (
                                                            <div className="alert alert-danger oe-alert-danger">
                                                                {errors.selected_villes}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="v2-admin-modal-footer">
                                            <button
                                                className="v2-admin-modal-footer-close-btn btn btn-danger"
                                                // onClick={handleCloseExportFilterModal}
                                            >
                                                Fermer
                                            </button>
                                            <button
                                                className="btn btn-primary v2-admin-modal-footer-submit-btn"
                                                variant="primary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onSubmitFilterAndExportForm();
                                                }}
                                            >
                                                {filterInProgress
                                                ? "En cours..."
                                                : "Envoyer"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
            
                            <div className="col-md-12 orm-table-col">
                                {ExportFilteredCheckoutsToExcel()}
                                <div className="orm-table-container">
                                    <div className="row filter-to-export-row">
                                        <div className="col-lg-12 checkout-filter-results-textuals-col">
                                        <div className="checkout-filter-results-textuals-body">
                                            <div className="period-title">
                                                <span>{`Recherche effectuée sur la période du ${filteredPeriod ? filteredPeriod : "Aucune date définie"}`}</span>
                                            </div>
                                            <div className="event-type-type">
                                                <span>{`Type de séance : ${filteredEventType ? filteredEventType : "Aucune séance choisie"}`} </span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <BootstrapTable
                                        data={filteredCheckouts}
                                        striped={true}
                                        hover={true}
                                        condensed={true}
                                        options={options}
                                        pagination
                                        version="4"
                                    >
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey={true}
                                            hidden={true}
                                        >
                                            ID
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="270"
                                            dataField="event_type"
                                            dataSort
                                        >
                                            Type de Séance
                                        </TableHeaderColumn>

                                            <TableHeaderColumn
                                            width="250"
                                            dataField="fullname"
                                            dataSort
                                        >
                                            Prénom et Nom
                                        </TableHeaderColumn>

                                        <TableHeaderColumn width="150" dataField="telephone">
                                            Téléphone
                                        </TableHeaderColumn>

                                        <TableHeaderColumn width="150" dataField="date">
                                            Date de RDV
                                        </TableHeaderColumn>

                                        <TableHeaderColumn width="200" dataField="horaire" dataFormat={(cell, row) => horaireFormatter(cell, row)}>
                                            Horaire
                                        </TableHeaderColumn>

                                        <TableHeaderColumn width="200" dataField="antenne">
                                            Antenne Campus France
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="200"
                                            dataField="numero_compte_etude_fr"
                                        >
                                            Numéro de Compte Étude France
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="200"
                                            dataField="numero_dossier_auto_evaluation"
                                        >
                                            Numéro dossier d'auto-évaluation
                                        </TableHeaderColumn>

                                    </BootstrapTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      );
}

export default FiltrePriseRendezVous
