import React, {useState} from 'react';
import Modal from 'react-modal';
import ReturnBack from '../../../../img/v2/icons/ReturnBack.svg';
import InformationIcon from '../../../../img/v2/icons/InformationIcon.svg';
import { useEffect } from 'react';
import axios from 'axios';
import ApiBaseUrl from '../../../../http/ApiBaseUrl';

const JeMinformeIndexModal = (props) => {

    const [links, setLinks] = useState([]);
    // token: window.sessionStorage.getItem('token'),
    // userAvatar: window.sessionStorage.getItem('user_avatar'),
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    };

    const onGetJeMinformeData = () => {
        
        axios.get(`${ApiBaseUrl}je_minformes`, requestConfig)
            .then(response => {
                if(response.data.success){
                    setLinks(response.data.data);
                }else{
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                // if (error.message === 'Request failed with status code 401') {
                //     this.setState({
                //         SessionExpiration: true
                //     })
                // }
            })
    }

    useEffect(() => {
        Modal.setAppElement('body');
        onGetJeMinformeData();
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Modal
                isOpen={props?.jeMinformeModalIsOpen}
                className="Modal JeMinformeModal"
                overlayClassName="Overlay"
                style={customStyles}
            >
                <div className="v2-modal-content-container">
                    <div className="v2-modal-content">
                        <div className="v2-modal-header">
                            <div className="v2-modal-header-content">
                                <div className="v2-modal-return-back-or-close-btn-container">
                                    <button className="btn btn-danger v2-return-back-btn" onClick={props?.onCloseJeMinformeModal}>
                                        <span className="return-back-group">
                                            <span className="return-back-icon-container">
                                                <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                            </span>
                                            {
                                                window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                            }
                                            
                                        </span>
                                    </button>
                                </div>
                                <div className="v2-modal-header-title-container">
                                    <div className="modal-header-title-group">
                                        <div className="modal-header-title-icon-container">
                                            <img src={InformationIcon} alt="Return back" className="modal-header-title-icon" />
                                        </div>
                                        <div className="modal-header-title-label">Je m'informe</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="v2-modal-body">
                            <div className="v2-modal-body-content-container">
                                <div className="v2-modal-body-row row">
                                    {
                                        links?.map((link, index) => {
                                            return(
                                                <div className={index%2 === 0 ? "v2-modal-body-col col-md-12 reding" : "v2-modal-body-col col-md-12 blueing"} key={`oe-cf-links${index}`}>
                                                    <div className="v2-modal-body-item-container">
                                                        <div className="v2-modal-body-item">
                                                            <a href={link.link} target="_blank" rel="noopener noreferrer" className="btn btn-danger v2-modal-body-item-btn-on-full-width">
                                                                <span className="sub-service-item-label-on-full-width">{link.label}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </Modal>
        </>
    );
}

const customStyles = {
    content: {
        // position: "absolute",
        // top: "20%",
        // width: "100%",
        // heigth: "auto",
        // left: "0",
        // right: "0",
        // margin: "auto",
    },
};

export default JeMinformeIndexModal