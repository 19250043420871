import React from 'react';
import Modal from 'react-modal';
import ReturnBack from '../../../../img/v2/icons/ReturnBack.svg';
import UniversityIcon from '../../../../img/v2/icons/UniversityIcon.svg';
import { useEffect } from 'react';

const SearchUniversityIndexModal = (props) => {
    useEffect(() => {
        Modal.setAppElement('body');
    });

    const openLinkInNewTab = (url) => {
        window.open(url, '_blank');
    }


    return (
        <>
            <Modal
                isOpen={props?.searchUniversityModalIsOpen}
                className="Modal"
                overlayClassName="Overlay"
                style={customStyles}
            >
                <div className="v2-modal-content-container">
                    <div className="v2-modal-content">
                        <div className="v2-modal-header">
                            <div className="v2-modal-header-content">
                                <div className="v2-modal-return-back-or-close-btn-container">
                                    <button className="btn btn-danger v2-return-back-btn" onClick={props?.onCloseSearchUniversityModal}>
                                        <span className="return-back-group">
                                            <span className="return-back-icon-container">
                                                <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                            </span>
                                            {
                                                window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                            }
                                            
                                        </span>
                                    </button>
                                </div>
                                <div className="v2-modal-header-title-container">
                                    <div className="modal-header-title-group">
                                        <div className="modal-header-title-icon-container">
                                            <img src={UniversityIcon} alt="Return back" className="modal-header-title-icon" />
                                        </div>
                                        <div className="modal-header-title-label">Je cherche une école ou une université</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="v2-modal-body">
                            <div className="v2-modal-body-content-container">
                                <div className="v2-modal-body-row row">
                                    <div className="v2-modal-body-col col-md-6 reding">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button className="btn btn-danger v2-modal-body-item-btn" onClick={props?.onOpenSubSearchUniversityModal}>
                                                    <span className="sub-service-item-label">Universités & Établissements publics</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="v2-modal-body-col col-md-6 blueing">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={() => openLinkInNewTab('https://www.senegal.campusfrance.org/comment-choisir-son-ecole-de-formation') } className="btn btn-danger v2-modal-body-item-btn">
                                                    <span className="sub-service-item-label">Établissements privés</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </Modal>

            <Modal
                isOpen={props?.publicSchoolOpenModal}
                className="Modal"
                overlayClassName="Overlay"
                style={customStyles}
            >
                <div className="v2-modal-content-container">
                    <div className="v2-modal-content">
                        <div className="v2-modal-header">
                            <div className="v2-modal-header-content">
                                <div className="v2-modal-return-back-or-close-btn-container">
                                    <button className="btn btn-danger v2-return-back-btn" onClick={props?.onCloseSubSearchUniversityModal}>
                                        <span className="return-back-group">
                                            <span className="return-back-icon-container">
                                                <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                            </span>
                                            {
                                                window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                            }
                                            
                                        </span>
                                    </button>
                                </div>
                                <div className="v2-modal-header-title-container">
                                    <div className="modal-header-title-group">
                                        <div className="modal-header-title-icon-container">
                                            <img src={UniversityIcon} alt="Return back" className="modal-header-title-icon" />
                                        </div>
                                        <div className="modal-header-title-label">Je cherche une école ou une université</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="v2-modal-body">
                            <div className="v2-modal-body-content-container">
                                <div className="v2-modal-body-row row">
                                    <div className="v2-modal-body-col col-md-6 reding">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={() => openLinkInNewTab('https://cataloguelm.campusfrance.org/licence/#/catalog?lang=fr')} className="btn btn-danger v2-modal-body-item-btn">
                                                    <span className="sub-service-item-label">LICENCES</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="v2-modal-body-col col-md-6 blueing">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={() => openLinkInNewTab('https://cataloguelm.campusfrance.org/master/#/catalog?lang=fr')} className="btn btn-danger v2-modal-body-item-btn">
                                                    <span className="sub-service-item-label">MASTERS</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </Modal>
        </>
    );
}

const customStyles = {
    content: {
        // position: "absolute",
        // top: "20%",
        // width: "100%",
        // heigth: "auto",
        // left: "0",
        // right: "0",
        // margin: "auto",
    },
};

export default SearchUniversityIndexModal