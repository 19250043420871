import React, {Component} from 'react';
import './ListeNewsScrapped.scss'
import ApiBaseUrl from '../../http/ApiBaseUrl';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import $ from 'jquery'
import toast from 'react-hot-toast';
import NavVertical from '../navVertical/NavVertical';
import BackendBaseurl from '../../http/BackendBaseUrl';



export default class ListeNewsScrapped extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: false,
          news: [],
          newsInfos: [],
          isScrappingInProgress: false,
          isSuccessScrapping: false,
          isErrorScrapping: false,
          is_empty_news_image: false,
          is_empty_news_title: false,
          is_empty_news_url: false,
          addArticleInProgress: false,
          isAddArticleRequestError: false,
          fields: {},
          errors: {},
          editArticleInProgress: false,
          is_empty_is_online: false,
          editStatusInProgress: false
        };

        this.editArticleHandleChange = this.editArticleHandleChange.bind(this)
    }

    componentDidMount = () => {
      this.onGetNewsScrapped()

      console.log(this.state.ApiBaseUrl)
    
    }

    onGetNewsScrapped = () => {
      this.setState({
        isPageLoading: true
      })
      var config = this.state.requestConfig;
  
      axios.get(ApiBaseUrl + 'last_news_scrappeds', config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })
  
          if (response.data.success) {
            this.setState({
              news: response.data.data,
            })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          this.setState({
            isPageLoading: false,
          })
          console.log(error)
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    goBack = (e) => {
      e.preventDefault()
      this.props.history.goBack();
    }

    onShowNews = (row, e) => {
      this.setState({
        newsInfos: row
      })
    }

    onDeleteNewsItem = (e,itemID) => {
      e.preventDefault()
      if(window.confirm('Confirmez-vous la suppression de cet article ?')){
        
        var config = this.state.requestConfig
        axios.delete(`${ApiBaseUrl}last_news_scrappeds/${itemID}`, config)
          .then(response => {
            if(response.data.success){
              this.onGetNewsScrapped()
              toast.success('Article supprimé avec succès.',{
                duration: 5000,
                position: 'top-center',
              })
            }else{
              console.log(response)
              toast.error('Une erreur est survenue lors de la suppression.',{
                duration: 5000,
                position: 'top-center',
              })
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Une erreur est survenue lors de la suppression.',{
              duration: 5000,
              position: 'top-center',
            })
            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }
          })
      }
      
    }

    actionsFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button data-toggle="modal" data-target=".edit-article-modal" className="action-btn oe-edit-btn color-white" onClick={(e) => this.onGetNewsInfos(e, row)}>
            <FeatherIcon icon="edit" className="edit-icon" />
          </button>

          <button className="action-btn oe-delete-btn color-white" onClick={(e) => this.onDeleteNewsItem(e, row.id)}>
            <FeatherIcon icon="trash" className="trash-icon" />
          </button>
          
        </div>
      )
    }

    onGetNewsInfos = (e, item) => {
      e.preventDefault()
      this.setState({
        newsInfos: item
      })
      console.log(item)
    }

    newsUrlFormatter = (cell, row) => {
      return(
        <a href={cell} className="show-news-link badge badge-warning" target="_blank" rel="noopener noreferrer">Voir l'article</a>
      )
    }

    newsImageUrlFormatter = (cell, row) => {
      if(row?.is_manuel_adding === false || row?.is_manuel_adding === 0){
        return(
          <img src={cell} className="image-news-link" alt={row.news_title} height="100" width="100" />
        )
      }else{
        return(
          <img src={`${BackendBaseurl}${cell}`} className="image-news-link" alt={row.news_title} height="100" width="100" />
        )
      }
      
    }

    onExecuteScrappingCommandLine = (e) => {
      e.preventDefault()
      this.setState({
        isScrappingInProgress: true
      })

      
      var config = this.state.requestConfig

      axios.get(`${ApiBaseUrl}execute-news-scrapping-command`, config)
        .then(response => {
          this.setState({
            isScrappingInProgress: false
          })
          if(response.data.success){
            toast.success('Articles scrappés avec succès.',{
              duration: 5000,
              position: 'top-center',
            })
            this.onGetNewsScrapped()
          }else{
            console.log(response)
            toast.error('Un probléme est survenu lors de l\'exécution de la requête.',{
              duration: 5000,
              position: 'top-center',
            })
          }
        })
        .catch(error => {
          toast.error('Un probléme est survenu lors de l\'exécution de la requête.',{
            duration: 5000,
            position: 'top-center',
          })
          console.log(error)
          this.setState({
            isScrappingInProgress: false
          })
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    addArticleHandleChange = (e) => {
      let fields = this.state.fields

      if(e.target.name === 'news_image'){
        fields[e.target.name] = e.target.files[0]
      }else{
        fields[e.target.name] = e.target.value
      }

      this.setState({
        fields
      })

      console.log(fields)
    }

    onValidateAddArticleForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['news_title']){
        isValidForm = false
        this.setState({
          is_empty_news_title: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_news_title: false
          })
        }, 5000);
        
        errors['news_title'] = 'Ce champ est obligatoire'
      }

      if(!fields['news_url']){
        isValidForm = false
        this.setState({
          is_empty_news_url: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_news_url: false
          })
        }, 5000);
        
        errors['news_url'] = 'Ce champ est obligatoire'
      }

      if(!fields['news_image']){
        isValidForm = false
        this.setState({
          is_empty_news_image: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_news_image: false
          })
        }, 5000);
        
        errors['news_image'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    getFormData = object => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);

      return formData;
    }, new FormData());
    
    onSubmitAddArticle = (e) => {
      e.preventDefault()
      if(this.onValidateAddArticleForm()){
        this.setState({
          addArticleInProgress: true
        })

        let data = this.getFormData(this.state.fields);


        axios.post(`${ApiBaseUrl}last_news_scrappeds`, data)
          .then(response => {
            this.setState({
              addArticleInProgress: false
            })
            if(response.data.success){
              this.onGetNewsScrapped()
              document.getElementById('add-news-form').reset()
              $('.close').click()
              toast.success('Article ajouté avec succès', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                // icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              addArticleInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              // Styling
              style: {},
              className: '',
              // Custom Icon
              // icon: '👏',
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              // Aria
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }
    }

    editArticleHandleChange = (e) => {
      e.persist()
      var name = e.target.name
      var value = e.target.value
      // console.log(value)
      this.setState((prevState) => ({
        newsInfos: { ...prevState.newsInfos, [name]: name !== 'news_image' ? value : e.target.files[0] }
      }))
    }

    onValidateEditArticleForm = () => {
      let fields = this.state.newsInfos
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['news_title']){
        isValidForm = false
        this.setState({
          is_empty_news_title: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_news_title: false
          })
        }, 5000);
        
        errors['news_title'] = 'Ce champ est obligatoire'
      }

      if(!fields['news_url']){
        isValidForm = false
        this.setState({
          is_empty_news_url: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_news_url: false
          })
        }, 5000);
        
        errors['news_url'] = 'Ce champ est obligatoire'
      }
      if(!fields['is_online']){
        isValidForm = false
        this.setState({
          is_empty_is_online: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_online: false
          })
        }, 5000);
        
        errors['is_online'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitEditArticle = (e) => {
      e.preventDefault()

      if(this.onValidateEditArticleForm()){
        this.setState({
          editArticleInProgress: true
        })

        let data = {};

        if(this.state.newsInfos.news_image){
          data = this.getFormData(this.state.newsInfos);
        }else{
          data = this.state.newsInfos;
        }

        axios.post(`${ApiBaseUrl}update_last_news_scrappeds/${this.state.newsInfos.id}`, data)
          .then(response => {
            this.setState({
              editArticleInProgress: false
            })
            if(response.data.success){
              this.onGetNewsScrapped()
              $('.close').click()
              $('#edit_news_image').val('')
              this.setState({
                newsInfos: []
              })
              toast.success('Article modifié avec succès', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de la modification.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                // icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              editArticleInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              // Styling
              style: {},
              className: '',
              // Custom Icon
              // icon: '👏',
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              // Aria
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }

    }

    isOnlineFormatter = (cell, row) => {
      if(cell === 1 || cell === true || cell === '1'){
        return <span data-toggle="modal" data-target=".edit-article-status-modal" className="badge badge-success linkable" onClick={(e) => this.onGetNewsInfos(e, row)}>en ligne</span>
      }

      return <span data-toggle="modal" data-target=".edit-article-status-modal" className="badge badge-danger linkable" onClick={(e) => this.onGetNewsInfos(e, row)}>non publié</span>
    }

    editArticleStatusHandleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value
    }

    onValidateEditArticleStatusForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['is_online']){
        isValidForm = false
        this.setState({
          is_empty_is_online: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_online: false
          })
        }, 5000);
        
        errors['is_online'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitEditArticleStatus = (e) => {
      e.preventDefault();
      if(this.onValidateEditArticleStatusForm()){
        this.setState({
          editStatusInProgress: true
        });
        let data = this.state.fields;
        axios.post(`${ApiBaseUrl}switch-news-online-status/${this.state.newsInfos.id}`, data)
          .then(response => {
            this.setState({
              editStatusInProgress: false
            })
            if(response.data.success){
              this.onGetNewsScrapped()
              $('.close').click();
              $('#edit_only_is_online').val()
              this.setState({
                newsInfos: [],
                fields: {}
              })
              toast.success('Le statut de l\'articlea été modifié avec succès', {
                duration: 5000,
                position: 'top-center',
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de la modification.', {
                duration: 5000,
                position: 'top-center',
              });
            }
          })
          .catch(error => {
            this.setState({
              editStatusInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de la modification.', {
              duration: 5000,
              position: 'top-center',
            });
          })
      }
    }
                    


    render() {
      const options = {
        noDataText: 'Aucun article trouvé.'
        // withoutNoDataText: true, // this will make the noDataText hidden, means only showing the table header
      };

      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        return(
          <div className="component-liste-conseillers-oe">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />
            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="mb-0 bold color-white mb-3">Liste des articles</p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

                <div className="row lc-container">
                  <div className="nav-container nav-dashboard-color">
                      <NavVertical />
                  </div>
                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-10 col-md-12 content-position-after-header px-md-3 px-0">
                    <div className="row page-content-row">
                      <div className="col-md-6 offset-md-3 orm-add-btn-col">
                        {!this.state.isScrappingInProgress ?
                          <button className="orm-add-btn btn btn-primary" title="Cliquer pour sauvegarder les nouvels articles" onClick={(e) => this.onExecuteScrappingCommandLine(e)}>
                            <FeatherIcon icon="rotate-cw" className="add-plus-icon" />
                          </button> : 
                          <button className="orm-add-btn btn btn-primary">
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        }
                        <button className="orm-add-btn btn btn-primary scrapping-siblings" data-toggle="modal" data-target=".add-article-modal">
                          <FeatherIcon icon="plus" className="add-plus-icon" />
                        </button>
                      </div>

                      <div className="col-md-12 cm-col oe-liste-conseiller-table-container">
                        <BootstrapTable data={this.state.news} 
                          options={options}
                          striped 
                          hover 
                          version='4'
                          pagination>
                            <TableHeaderColumn isKey dataField='id' hidden={true}>News ID</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataField='news_title'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Titre de l'article</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.isOnlineFormatter.bind(this)}
                              dataField='is_online'>
                              Statut</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.newsUrlFormatter.bind(this)}
                              dataField='news_url'>
                              URL</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataFormat={this.newsImageUrlFormatter.bind(this)}
                              dataField='news_image_url'>
                              Image</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.actionsFormatter.bind(this)} dataField='id'>Actions</TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            

            {/* Add article modal */}
            <div className="modal fade add-article-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Ajouter un article</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form id="add-news-form">
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="news_title">Titre de l'article</label>
                          <input onChange={this.addArticleHandleChange} type="text" placeholder="Titre de l'article" className="form-control add-event-form-control" name="news_title" id="news_title" />
                        
                          {this.state.is_empty_news_title &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.news_title } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="news_url">URL de l'article</label>
                          <input onChange={this.addArticleHandleChange} type="text" placeholder="URL de l'article" className="form-control add-event-form-control" name="news_url" id="news_url" />
                        
                          {this.state.is_empty_news_url &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.news_url } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="is_online">Statut</label>
                          <select onChange={this.addArticleHandleChange} className="form-control add-event-form-control" name="is_online" id="is_online">
                            <option value="placeholder" disabled selected >--Choisir--</option>
                            <option value={1} >Publier en ligne</option>
                            <option value={0}>Ne pas publier</option>
                          </select>
                        
                          {this.state.is_empty_is_online &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_online } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="news_image">Image illustrative</label>
                          <input onChange={this.addArticleHandleChange} type="file" className="form-control add-event-form-control file-type-input" name="news_image" id="news_image" />
                          <span className="image-ration-requirement">Taille d'image conseillée : 250px/200px</span>
                          {this.state.is_empty_news_image &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.news_image } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.addArticleInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitAddArticle(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Ajouter</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <div className="modal fade edit-article-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Modifier un article</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_news_title">Titre de l'article</label>
                          <input value={this.state.newsInfos.news_title} onChange={(e) => this.editArticleHandleChange(e)} type="text" placeholder="Titre de l'article" className="form-control add-event-form-control" name="news_title" id="edit_news_title" />
                        
                          {this.state.is_empty_news_title &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.news_title } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_news_url">URL de l'article</label>
                          <input value={this.state.newsInfos.news_url} onChange={(e) => this.editArticleHandleChange(e)} type="text" placeholder="URL de l'article" className="form-control add-event-form-control" name="news_url" id="edit_news_url" />
                        
                          {this.state.is_empty_news_url &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.news_url } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_is_online">Statut</label>
                          <select onChange={(e) => this.editArticleHandleChange(e)} className="form-control add-event-form-control" name="is_online" id="edit_is_online">
                            <option value="placeholder" disabled >--Choisir--</option>
                            {this.state.newsInfos?.is_online ? (
                                <>
                                  <option value={1} selected>Publier en ligne</option>
                                  <option value={0}>Ne pas publier</option>
                                </>
                                
                              ) : (
                                <>
                                  <option value={1}>Publier en ligne</option>
                                  <option value={0} selected>Ne pas publier</option>
                                </>
                              )
                            }
                          </select>
                        
                          {this.state.is_empty_is_online &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_online } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_news_image">Image illustrative</label>
                          <input onChange={(e) => this.editArticleHandleChange(e)} type="file" className="form-control add-event-form-control file-type-input" name="news_image" id="edit_news_image" />
                          <span className="image-ration-requirement">Taille d'image conseillée : 250px/200px</span>
                          <div className="current-image-container">
                            {
                              this.state.newsInfos.is_manuel_adding ?
                                <img src={`${this.state.ApiBaseUrl}${this.state.newsInfos.news_image_url}`} alt={this.state.newsInfos.news_title} className="news-current-image" /> 
                                :
                                <img src={`${this.state.newsInfos.news_image_url}`} alt={this.state.newsInfos.news_title} className="news-current-image" />
                            }
                          </div>
                          {this.state.is_empty_news_image &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.news_image } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.editArticleInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitEditArticle(e) }>
                              <FeatherIcon icon="edit" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <div className="modal fade edit-article-status-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Modifier le statut de {this.state.newsInfos?.news_title} </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-12 add-event-col">
                          <label htmlFor="edit_only_is_online">Statut</label>
                          <select onChange={(e) => this.editArticleStatusHandleChange(e)} className="form-control add-event-form-control" name="is_online" id="edit_only_is_online">
                            <option value="placeholder" disabled >--Choisir--</option>
                            {this.state.newsInfos.is_online ? (
                                <>
                                  <option value={1} selected>Publier en ligne</option>
                                  <option value={0}>Ne pas publier</option>
                                </>
                                
                              ) : (
                                <>
                                  <option value={1}>Publier en ligne</option>
                                  <option value={0} selected>Ne pas publier</option>
                                </>
                              )
                            }
                          </select>
                        
                          {this.state.is_empty_is_online &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_online } </div>
                          }
                        </div>

                        <div className="col-md-6 offset-md-6 add-event-submit-col">
                          {!this.state.editStatusInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitEditArticleStatus(e) }>
                              <FeatherIcon icon="edit" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            
          </div>
        )
      }
    }
  }