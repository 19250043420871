import React, { useState } from 'react';
import './ProfilEtudiantViewByCF.css';
import { useParams } from 'react-router-dom';
import FooterV2 from '../../footerV2/FooterV2';
import LandingPageHeaderV2 from '../../landingPageV2/LandingPageHeaderV2';
import axios from 'axios';
import ApiBaseUrl from '../../../http/ApiBaseUrl';
import { useEffect } from 'react';
import WarningIcon from '../../../img/v2/icons/WarningIcon.svg';
import moment from 'moment';

const ProfilEtudiantViewByCF = () => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [profile, setProfile] = useState();
    const [detailProfile, setDetailProfile] = useState();
    const [hasNoProfil, setHasNoProfile] = useState(false);
    const [requestError, setRequestError] = useState(false);

    const params = useParams();

    const onGetProfilEtudiant = (numeroDossier) => {
        axios.get(`${ApiBaseUrl}on-view-profile/${numeroDossier}`)
            .then(response => {
                setIsPageLoading(false);
                console.log('reponse', response.data.profil)
                if(response.data.success && response.data.has_profile){
                    setProfile(response.data.profil);
                    setDetailProfile(response.data.detail_profil);
                }
                if(!response.data.success && !response.data.has_profile){
                    setHasNoProfile(true);
                }
            })
            .catch(error => {
                console.log('error', error);
                setRequestError(true);
            })
    }

    useEffect(() => {
        onGetProfilEtudiant(params?.numero_dossier);
        // eslint-disable-next-line
    }, [params?.numero_dossier]);

    const onGeneratePDF = () => {
        document.title = "Rapport d'Auto-Évaluation de " + profile?.prenom + " " + profile?.nom;
        window.print();
    }


    return (
        <div className="mon-profil-etudiant-component">
            {isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <LandingPageHeaderV2 />

            {
                requestError && (
                    <section className="request-error-section">
                        <div className="container request-error-container">
                            <div className="request-error-page-content">
                                <div className="row request-error-row">
                                    <div className="v2-modal-body-col col-md-12 v2-modal-empty-badge-col">
                                        <div className="v2-modal-empty-badge-body">
                                            <div className="v2-modal-empty-badge-icon-container">
                                                <img src={WarningIcon} alt="Empty" className="v2-modal-empty-badge-icon" />
                                            </div>
                                            <div className="v2-modal-empty-badge-message-container">
                                                <span>Oups! Une erreur est survenue lors de la recupération des données. Veuillez réessayer plus tard ou contacter un conseiller.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

            {
                hasNoProfil && (
                    <section className="request-error-section">
                        <div className="container request-error-container">
                            <div className="request-error-page-content">
                                <div className="row request-error-row">
                                    <div className="v2-modal-body-col col-md-12 v2-modal-empty-badge-col">
                                        <div className="v2-modal-empty-badge-body">
                                            <div className="v2-modal-empty-badge-icon-container">
                                                <img src={WarningIcon} alt="Empty" className="v2-modal-empty-badge-icon" />
                                            </div>
                                            <div className="v2-modal-empty-badge-message-container">
                                                <span>Oups! Aucun profil n'est associé au numéro de dossier {params?.numero_dossier}. </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

            {
                (!hasNoProfil && !requestError) && (
                    <section className="profil-etudiant-section profil-view-by-cf-conseiller" id="profil-section">
                        <div className="container profil-etudiant-container">
                            <div className="ae-quizz-page-title">
                                <h1>Rapport d'auto-évaluation</h1>
                            </div>
                            <div className="print-btn-container">
                                <button 
                                    className="btn btn-primary export-profile-pdf-btn"
                                    onClick={() => onGeneratePDF()}
                                >
                                    Imprimer
                                </button>
                                <div className="total-score">
                                    <span className="total-score-content">
                                        Score total : &nbsp;
                                        <span>
                                        {
                                            parseFloat(detailProfile?.score_mention_bac_bt ? detailProfile?.score_mention_bac_bt : 0) + 
                                            parseFloat(detailProfile?.score_nombre_passage_examen_bac_bt ? detailProfile?.score_nombre_passage_examen_bac_bt : 0) + 
                                            parseFloat(detailProfile?.score_nombre_redoublement ? detailProfile?.score_nombre_redoublement : 0) + 
                                            parseFloat(detailProfile?.score_etude_type_etablissement ? detailProfile?.score_etude_type_etablissement : 0) + 
                                            parseFloat(detailProfile?.score_nombre_procedure_campus_france ? detailProfile?.score_nombre_procedure_campus_france : 0) + 
                                            parseFloat(detailProfile?.score_nombre_acceptation_procedure_campus_france ? detailProfile?.score_nombre_acceptation_procedure_campus_france : 0) + 
                                            parseFloat(detailProfile?.score_pourquoi_poursuivre_etude_en_france ? detailProfile?.score_pourquoi_poursuivre_etude_en_france : 0) + 
                                            parseFloat(detailProfile?.score_pourquoi_poursuivre_etude_en_france_2 ? detailProfile?.score_pourquoi_poursuivre_etude_en_france_2 : 0) + 
                                            parseFloat(detailProfile?.score_idee_etude_fr_comment_quel_moyen ? detailProfile?.score_idee_etude_fr_comment_quel_moyen : 0) + 
                                            parseFloat(detailProfile?.score_idee_etude_fr_comment_quel_moyen_2 ? detailProfile?.score_idee_etude_fr_comment_quel_moyen_2 : 0) + 
                                            parseFloat(detailProfile?.score_pourquoi_reorientation ? detailProfile?.score_pourquoi_reorientation : 0) + 
                                            parseFloat(detailProfile?.score_definition_projet_recherche_ouprofessionnel ? detailProfile?.score_definition_projet_recherche_ouprofessionnel : 0) + 
                                            parseFloat(detailProfile?.score_licence_universite_publique_cout_formation ? detailProfile?.score_licence_universite_publique_cout_formation : 0) + 
                                            parseFloat(detailProfile?.score_licence_universite_privee_cout_formation ? detailProfile?.score_licence_universite_privee_cout_formation : 0) + 
                                            parseFloat(detailProfile?.score_master_universite_publique_cout_formation ? detailProfile?.score_master_universite_publique_cout_formation : 0) + 
                                            parseFloat(detailProfile?.score_master_universite_privee_cout_formation ? detailProfile?.score_master_universite_privee_cout_formation : 0) + 
                                            parseFloat(detailProfile?.score_frais_de_vie_annee_etude ? detailProfile?.score_frais_de_vie_annee_etude : 0)
                                            
                                        } point(s)
                                        </span>
                                    </span>
                                </div>
                            </div>
                            
                            <div className="profil-etudiant-page-content">
                                <div className="row profil-etudiant-section-row">
                                    <div className="col-md-8 offset-md-2 section-title-container">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 1 : INFORMATIONS PERSONNELLES</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Prénom </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.prenom} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Nom </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.nom} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Sexe </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.sexe} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Date de naissance </h3>
                                            <div className="infos-etudiant-item-value"> {moment(profile?.date_naissance).format("DD/MM/YYYY")} </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Pays de résidence </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.pays_residence ? profile?.pays_residence : 'NON RENSEIGNÉ'} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Ville de résidence </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.ville_residence ? profile?.ville_residence : 'NON RENSEIGNÉE'} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Antenne Campus France </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.ville_id ? 'Antenne ' + profile?.ville?.name : 'NON RENSEIGNÉE'} </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Nationalité </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.nationalite} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Numéro de piéce d'identité </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.numero_cni} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Email </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.email} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Téléphone </h3>
                                            <div className="infos-etudiant-item-value"> {profile?.telephone} </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container">
                                            <h3 className="infos-etudiant-label"> Année d'étude / Activité professionnelle </h3>
                                            <div className="infos-etudiant-item-value"> {(profile?.etude_ou_travail === 1 || profile?.etude_ou_travail === true) ? "Oui" : "Non"} </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row profil-etudiant-section-row">
                                    <div className="col-md-8 offset-md-2 section-title-container">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 2 : PARCOURS ACADEMIQUE</h2>
                                                <span className="section-total-summing">
                                                    {
                                                        parseFloat(detailProfile?.score_mention_bac_bt ? detailProfile?.score_mention_bac_bt : 0) + 
                                                        parseFloat(detailProfile?.score_nombre_passage_examen_bac_bt ? detailProfile?.score_nombre_passage_examen_bac_bt : 0) + 
                                                        parseFloat(detailProfile?.score_nombre_redoublement ? detailProfile?.score_nombre_redoublement : 0) + 
                                                        parseFloat(detailProfile?.score_etude_type_etablissement ? detailProfile?.score_etude_type_etablissement : 0) 
                                                    } point(s)
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {detailProfile?.bac_bt_obtenu && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Avez-vous obtenu le bac ou le BT national ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.bac_bt_obtenu} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.mention_bac_bt && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Votre Mention au BAC/BT ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {`${detailProfile?.mention_bac_bt}`}
                                                    {
                                                        detailProfile?.score_mention_bac_bt &&
                                                        <span className="score-item-value"> { detailProfile?.score_mention_bac_bt + ' point(s)' } </span>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.nombre_passage_examen_bac_bt && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Combien de fois avez-vous passé cet examen (Bac ou BT) ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.nombre_passage_examen_bac_bt} 
                                                    {
                                                        detailProfile?.score_nombre_passage_examen_bac_bt &&
                                                        <span className="score-item-value"> { detailProfile?.score_nombre_passage_examen_bac_bt + ' point(s)' } </span>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.serie_bac_bt && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Série du BAC ou du BT <i>(en cours de préparation ou obtenu)</i> </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.serie_bac_bt} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.situation_actuelle && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Quelle est votre situation actuelle ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.situation_actuelle} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.duree_travail_ou_stage && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Depuis combien de temps travaillez-vous ou êtes-vous en stage ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.duree_travail_ou_stage} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.type_dernier_diplome_obtenu && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Type du dernier diplôme obtenu ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.type_dernier_diplome_obtenu} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.obtention_diplome_depuis && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Depuis combien de temps avez-vous obtenu votre dernier diplôme ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.obtention_diplome_depuis} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.niveau_etude && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Quel est votre niveau d’études actuel ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.niveau_etude ? detailProfile?.niveau_etude : "Non Répondu"} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.nombre_redoublement && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Combien de fois avez-vous redoublé ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.nombre_redoublement} 
                                                    {
                                                        detailProfile?.score_nombre_redoublement &&
                                                        <span className="score-item-value"> { detailProfile?.score_nombre_redoublement + ' point(s)' } </span>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-md-12 profil-etudiant-infos-col">
                                        <div className="profil-etudiant-infos-item-container moyenne-annuelle-container">
                                            <h3 className="infos-etudiant-label"> Moyennes annuelles obtenues durant les trois dernières années d’études validées </h3>
                                            <div className="infos-etudiant-item-value notes-container">
                                                {detailProfile?.check_premiere_annee && (
                                                    <div className="row moyenne-etudiant-row">
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="classe-name"> { detailProfile?.check_premiere_annee } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> Sem.1 : { detailProfile?.premier_moyenne_semestre_1 } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> Sem. 2 : { detailProfile?.premier_moyenne_semestre_2 } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> M. Annuelle : { detailProfile?.premier_moyenne_annuelle } </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {detailProfile?.check_deuxieme_annee && (
                                                    <div className="row moyenne-etudiant-row">
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="classe-name"> { detailProfile?.check_deuxieme_annee } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> Sem.1 : { detailProfile?.deuxieme_moyenne_semestre_1 } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> Sem. 2 : { detailProfile?.deuxieme_moyenne_semestre_2 } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> M. Annuelle : { detailProfile?.deuxieme_moyenne_annuelle } </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {detailProfile?.check_troisieme_annee && (
                                                    <div className="row moyenne-etudiant-row">
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="classe-name"> { detailProfile?.check_troisieme_annee } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> Sem.1 : { detailProfile?.troisieme_moyenne_semestre_1 } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> Sem. 2 : { detailProfile?.troisieme_moyenne_semestre_2 } </div>
                                                        </div>
                                                        <div className="col-md-3 moyenne-etudiant-col">
                                                            <div className="moyenne-etudiant-item"> M. Annuelle : { detailProfile?.troisieme_moyenne_annuelle } </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {detailProfile?.domaine_etudes_superieures && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Précisez le domaine d’études dans le supérieur </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.domaine_etudes_superieures} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.etude_type_etablissement && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Dans quel type d’établissement étudiez-vous/avez-vous étudié ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.etude_type_etablissement} 
                                                    {
                                                        detailProfile?.score_etude_type_etablissement &&
                                                        <span className="score-item-value"> { detailProfile?.score_etude_type_etablissement + ' point(s)' } </span>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.nom_dernier_etablissement && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Précisez le nom du dernier établissement fréquenté </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.nom_dernier_etablissement} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.formation_superieure_reconnue_par && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Votre formation dans le supérieur est reconnue par </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.formation_superieure_reconnue_par} </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="row profil-etudiant-section-row">
                                    <div className="col-md-8 offset-md-2 section-title-container">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 3 : PROJET D’ETUDES</h2>
                                                <span className="section-total-summing">
                                                    {
                                                        parseFloat(detailProfile?.score_nombre_procedure_campus_france ? detailProfile?.score_nombre_procedure_campus_france : 0) + 
                                                        parseFloat(detailProfile?.score_nombre_acceptation_procedure_campus_france ? detailProfile?.score_nombre_acceptation_procedure_campus_france : 0) + 
                                                        parseFloat(detailProfile?.score_pourquoi_poursuivre_etude_en_france ? detailProfile?.score_pourquoi_poursuivre_etude_en_france : 0) + 
                                                        parseFloat(detailProfile?.score_pourquoi_poursuivre_etude_en_france_2 ? detailProfile?.score_pourquoi_poursuivre_etude_en_france_2 : 0) + 
                                                        parseFloat(detailProfile?.score_idee_etude_fr_comment_quel_moyen ? detailProfile?.score_idee_etude_fr_comment_quel_moyen : 0) + 
                                                        parseFloat(detailProfile?.score_idee_etude_fr_comment_quel_moyen_2 ? detailProfile?.score_idee_etude_fr_comment_quel_moyen_2 : 0) + 
                                                        parseFloat(detailProfile?.score_pourquoi_reorientation ? detailProfile?.score_pourquoi_reorientation : 0) + 
                                                        parseFloat(detailProfile?.score_definition_projet_recherche_ouprofessionnel ? detailProfile?.score_definition_projet_recherche_ouprofessionnel : 0)
                                                    } point(s)
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {detailProfile?.nombre_procedure_campus_france && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Combien de fois avez-vous fait la procédure Campus France ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.nombre_procedure_campus_france} 
                                                    {
                                                        detailProfile?.score_nombre_procedure_campus_france &&
                                                        <span className="score-item-value"> { detailProfile?.score_nombre_procedure_campus_france + ' point(s)' } </span>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.nombre_acceptation_procedure_campus_france && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Combien d’acceptation avez-vous eu ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.nombre_acceptation_procedure_campus_france} 
                                                    {
                                                        detailProfile?.score_nombre_acceptation_procedure_campus_france &&
                                                        <span className="score-item-value"> { detailProfile?.score_nombre_acceptation_procedure_campus_france + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.pourquoi_poursuivre_etude_en_france && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Pourquoi souhaitez-vous poursuivre vos études en France ? </h3>
                                                <div className="infos-etudiant-item-value double-note"> 
                                                    {detailProfile?.pourquoi_poursuivre_etude_en_france} 
                                                    {
                                                        detailProfile?.score_pourquoi_poursuivre_etude_en_france &&
                                                        <span className="score-item-value"> { detailProfile?.score_pourquoi_poursuivre_etude_en_france + ' point(s)' } </span>
                                                        
                                                    }
                                                    {
                                                        detailProfile?.score_pourquoi_poursuivre_etude_en_france_2 &&
                                                        <span className="score-item-value"> { detailProfile?.score_pourquoi_poursuivre_etude_en_france_2 + ' point(s)' } </span>
                                                        
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.idee_etude_en_france && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Avez-vous une idée de ce que vous souhaitez étudier en France ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.idee_etude_en_france} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.information_sur_formation_voulue && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Est-ce que vous vous êtes renseignés sur la formation envisagée ? (Contenu de la formation, prérequis et modalités d’inscription, durée de la formation, débouchés possible…) </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.information_sur_formation_voulue} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.idee_etude_fr_comment_quel_moyen && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Si oui, comment ? par quel moyen ? </h3>
                                                <div className="infos-etudiant-item-value double-note"> 
                                                    {detailProfile?.idee_etude_fr_comment_quel_moyen} 
                                                    {
                                                        detailProfile?.score_idee_etude_fr_comment_quel_moyen &&
                                                        <span className="score-item-value"> { detailProfile?.score_idee_etude_fr_comment_quel_moyen + ' point(s)' } </span>
                                                    }
                                                    {
                                                        detailProfile?.score_idee_etude_fr_comment_quel_moyen_2 &&
                                                        <span className="score-item-value"> { detailProfile?.score_idee_etude_fr_comment_quel_moyen_2 + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.souhait_niveau_etude_france && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Quelle année d’étude souhaitez-vous intégrer en France ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.souhait_niveau_etude_france} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.dynamique_projet_etude && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Votre projet s’inscrit dans une dynamique de </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.dynamique_projet_etude} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.achevement_cycle_actuel && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Avez-vous achevé votre cycle actuel ? (BTS ou Disep ou Licence ou Master) </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.achevement_cycle_actuel} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.formation_suivie_depuis_combien_detemps && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Depuis combien de temps suivez-vous cette formation ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.formation_suivie_depuis_combien_detemps} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.pourquoi_reorientation && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Pourquoi vous souhaitez vous réorienter ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.pourquoi_reorientation} 
                                                    {
                                                        detailProfile?.score_pourquoi_reorientation &&
                                                        <span className="score-item-value"> { detailProfile?.score_pourquoi_reorientation + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.definition_projet_recherche_ouprofessionnel && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Avez-vous défini votre projet de recherche / Professionnel ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.definition_projet_recherche_ouprofessionnel} 
                                                    {
                                                        detailProfile?.score_definition_projet_recherche_ouprofessionnel &&
                                                        <span className="score-item-value"> { detailProfile?.score_definition_projet_recherche_ouprofessionnel + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.projet_apres_formation && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Quel est votre projet après la formation visée ? </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.projet_apres_formation} </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.etude_type_etablissement_fr && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Vous souhaitez poursuivre vos études dans un établissement </h3>
                                                <div className="infos-etudiant-item-value"> {detailProfile?.etude_type_etablissement_fr} </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="row profil-etudiant-section-row">
                                    <div className="col-md-8 offset-md-2 section-title-container">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 4 : PROJET DE DEPART / DE VIE</h2>
                                                <span className="section-total-summing">
                                                    {
                                                        parseFloat(detailProfile?.score_licence_universite_publique_cout_formation ? detailProfile?.score_licence_universite_publique_cout_formation : 0) + 
                                                        parseFloat(detailProfile?.score_licence_universite_privee_cout_formation ? detailProfile?.score_licence_universite_privee_cout_formation : 0) + 
                                                        parseFloat(detailProfile?.score_master_universite_publique_cout_formation ? detailProfile?.score_master_universite_publique_cout_formation : 0) + 
                                                        parseFloat(detailProfile?.score_master_universite_privee_cout_formation ? detailProfile?.score_master_universite_privee_cout_formation : 0) + 
                                                        parseFloat(detailProfile?.score_frais_de_vie_annee_etude ? detailProfile?.score_frais_de_vie_annee_etude : 0)
                                                    } point(s)
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {detailProfile?.licence_universite_publique_cout_formation && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Selon vous, quel est le cout de la formation dans une Université Publique pour la Licence ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.licence_universite_publique_cout_formation} 
                                                    {
                                                        detailProfile?.score_licence_universite_publique_cout_formation &&
                                                        <span className="score-item-value"> { detailProfile?.score_licence_universite_publique_cout_formation + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.licence_universite_privee_cout_formation && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Selon vous, quel est le cout de la formation dans une Université Privée pour la Licence ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.licence_universite_privee_cout_formation} 
                                                    {
                                                        detailProfile?.score_licence_universite_privee_cout_formation &&
                                                        <span className="score-item-value"> { detailProfile?.score_licence_universite_privee_cout_formation + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.master_universite_publique_cout_formation && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Selon vous, quel est le cout de la formation dans une Université Publique pour le Master ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.master_universite_publique_cout_formation} 
                                                    {
                                                        detailProfile?.score_master_universite_publique_cout_formation &&
                                                        <span className="score-item-value"> { detailProfile?.score_master_universite_publique_cout_formation + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.master_universite_privee_cout_formation && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> Selon vous, quel est le cout de la formation dans une Université Privée pour le Master ? </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.master_universite_privee_cout_formation} 
                                                    {
                                                        detailProfile?.score_master_universite_privee_cout_formation &&
                                                        <span className="score-item-value"> { detailProfile?.score_master_universite_privee_cout_formation + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.frais_de_vie_annee_etude && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label"> A combien estimez-vous les frais de vie pour une année d’études en France ? (Logement, déplacement, nourriture, santé…)  </h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.frais_de_vie_annee_etude} 
                                                    {
                                                        detailProfile?.score_frais_de_vie_annee_etude &&
                                                        <span className="score-item-value"> { detailProfile?.score_frais_de_vie_annee_etude + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {detailProfile?.financement_etude && (
                                        <div className="col-md-12 profil-etudiant-infos-col">
                                            <div className="profil-etudiant-infos-item-container">
                                                <h3 className="infos-etudiant-label">Comment comptez-vous financer vos études ?</h3>
                                                <div className="infos-etudiant-item-value"> 
                                                    {detailProfile?.financement_etude} 
                                                    {
                                                        detailProfile?.score_financement_etude &&
                                                        <span className="score-item-value"> { detailProfile?.score_financement_etude + ' point(s)' } </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

            

            <FooterV2 />
        </div>
    )
}

export default ProfilEtudiantViewByCF