import React, {useEffect, useState} from 'react';
import './MonEspaceListeConseiller.css'
import ApiBaseUrl from '../../http/ApiBaseUrl';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavVertical from '../navVertical/NavVertical';

const MonEspaceListeConseiller = () => {
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    };
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [conseillers, setConseillers] = useState([]);
    const [staffMemberInfos, setStaffMemberInfos] = useState([]);
    const [editStatusField, setEditStatusFields] = useState({});
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({});
    const [userType_empty, set_userType_empty] = useState(false);
    const [errorEditRole, setErrorEditRole] = useState(false);
    const [successEditRole, setSuccessEditRole] = useState(false);
    const [editRoleInProgress, setEditRoleInProgress] = useState(false);
    const [editStaffMemberInProgress, setEditStaffMemberInProgress] = useState(false);
    // const [pseudo_empty, set_pseudo_empty] = useState(false);
    const [email_empty, set_email_empty] = useState(false);
    const [role_empty, set_role_empty] = useState(false);
    const [successEditStaffMember, setSuccessEditStaffMember] = useState(false);
    const [errorEditStaffMember, setErrorEditStaffMember] = useState(false);
    // const [userFullname, setUserFullname] = useState('')
    // const [vr_empty, set_vr_empty] = useState(false)
    // const [fonction_empty, set_fonction_empty] = useState(false);
    const [isUserExist, setIsUserExist] = useState(false);
    const [successAddStaff, setSuccessAddStaff] = useState(false);
    const [errorAddStaff, setErrorAddStaff] = useState(false);
    const [fullname_empty, set_fullname_empty] = useState(false);
    const [addStaffInProgress, setAddStaffInProgress] = useState(false);
    const [successDeleteStaffMember, setSuccessDeleteStaffMember] = useState(false)
    const [errorDeleteStaffMember, setErrorDeleteStaffMember] = useState(false)


    const onGetStaffMembers = () => {
    
        axios.get(ApiBaseUrl + 'conseiller_campus_frances', requestConfig)
          .then(response => {
            setIsPageLoading(false)
    
            if (response.data.success) {
              setConseillers(response.data.data)
            } else {
              console.log(response)
            }
          })
          .catch(error => {
            setIsPageLoading(false)
            console.log(error)
          })
    }

    useEffect(() => {
        onGetStaffMembers();
        // eslint-disable-next-line
    }, [])

    const nameFormatter = (cell, row) => {
        if(row.role === 'admin'){
            return row.pseudo
        }

        return row?.conseiller?.conseiller_fullname
    }

    const statusFormatter = (cell, row) => {
        if(cell === 'admin'){
            return <span data-toggle="modal" data-target="#updateStaffMemberStatus" onClick={() => onGetStaffMemberInfos(row)} className='badge badge-primary mesp-badge-primary linkable'>admin</span>
        }

        return <span data-toggle="modal" data-target="#updateStaffMemberStatus" onClick={() => onGetStaffMemberInfos(row)} className='badge badge-warning mesp-badge-warning linkable'>conseiller</span>
    }

    const onGetStaffMemberInfos = (item) => {
        console.log(item)
        setStaffMemberInfos(item);
        // setUserFullname(item.role === 'admin' ? item.pseudo : item.conseiller.conseiller_fullname)
    }

    const onHandleChangeRole = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setEditStatusFields((prevalue) => {
            return {
                ...prevalue,                 
                [name]: value
            }
        })
        console.log(fields)
    }
  
    const onSwitchBooleanVarStatus = (label) => {
        setTimeout(() => {
            if(label === 'successEditRole'){
                setSuccessEditRole(false)
            }
            if(label === 'successEditStaffMember'){
              setSuccessEditStaffMember(false)
            } 
            if(label === 'errorEditStaffMember'){
              setErrorEditStaffMember(false)
            }
            if(label === 'errorEditRole'){
                setErrorEditRole(false)
            } 
            if(label === 'email_empty'){
                set_email_empty(false)
            }
            if(label === 'role_empty'){
                set_role_empty(false)
            }
            if(label === 'isUserExist'){
                setIsUserExist(false)
            }
            if(label === 'successAddStaff'){
                setSuccessAddStaff(false)
                
            }
            if(label === 'errorAddStaff'){
                setErrorAddStaff(false);
            }
            if(label === 'fullname_empty'){
                set_fullname_empty(false);
            }
            if(label === 'userType_empty'){
                set_userType_empty(false)
            }

            if(label === 'successDeleteStaffMember'){
                setSuccessDeleteStaffMember(false)
            }
            if(label === 'errorDeleteStaffMember'){
                setErrorDeleteStaffMember(false)
            }
            
        }, 5000)
    }
  
    const onValidateEditStaffMemberRoleForm = () => {
        var isValidForm = true;
  
        if(!editStatusField["userType"]){
            isValidForm = false;
            set_userType_empty(true)
            onSwitchBooleanVarStatus('userType_empty');
            setErrors((prevalue) => {
                return {
                    ...prevalue,                 
                    'userType': 'Ce champ est obligatoire'
                }
            })
        }
  
        return isValidForm;
    }
  
    const onSubmitStaffMemberRole = (e) => {
        e.preventDefault();
  
        if(onValidateEditStaffMemberRoleForm()){
            setEditRoleInProgress(true)
            var userID = staffMemberInfos.id
    
            axios.post(ApiBaseUrl + 'update-staff-member-status/' + userID, editStatusField, requestConfig)
                .then(response => {
                if(response.data.success){
                    onGetStaffMembers()
                    document.getElementById('editRole').reset()
                    $('.close').click()
                    toast.success('Le statut a été modifié avec succès.')

                    setEditRoleInProgress(false)
                    setSuccessEditRole(true)
                    onSwitchBooleanVarStatus('successEditRole')

                }else{
                    setEditRoleInProgress(false)
                    setErrorEditRole(true)
                    onSwitchBooleanVarStatus('errorEditRole')
                    console.log(response)
                }
                })
                .catch(error => {
                    setErrorEditRole(true)
                    onSwitchBooleanVarStatus('errorEditRole')
                    console.log(error)
                })
        }
    }
  
    const onHandleChangeUpdate = (e) => {
        e.persist()
        var name = e.target.name
        var value = e.target.value
        setStaffMemberInfos((prevalue) => {
            return {
                ...prevalue,                 
                [name]: value
            }
        })
    }
  
    const onValidateEditStaffMemberForm = () => {
        var isValidForm = true;

        if(!staffMemberInfos["email"]){
            isValidForm = false;

            set_email_empty(true)
            onSwitchBooleanVarStatus('email_empty');
            setErrors((prevalue) => {
                return {
                    ...prevalue,                 
                    'email': 'Ce champ est obligatoire'
                }
            })
        }

        if(!staffMemberInfos["role"]){
            isValidForm = false;
            set_role_empty(true)
            onSwitchBooleanVarStatus('role_empty');
            setErrors((prevalue) => {
                return {
                    ...prevalue,                 
                    'role': 'Ce champ est obligatoire'
                }
            })
        }
  
        return isValidForm;
    }
  
    const onSubmitStaffMemberUpdate = (e) => {
        e.preventDefault()
  
        if(onValidateEditStaffMemberForm()){
          setEditStaffMemberInProgress(true)

          var userID = staffMemberInfos.id
  
          axios.post(ApiBaseUrl + 'update-staff-member-infos/' + userID, staffMemberInfos, requestConfig)
            .then(response => {
                setEditStaffMemberInProgress(false)

                if(response.data.success){
                    onGetStaffMembers()
                    document.getElementById('editStaffMemberForm').reset()
                    $('.close').click()
                    toast.success('Modification avec succès.')

                    // setEditRoleInProgress(false)
                    setSuccessEditStaffMember(true)

                    onSwitchBooleanVarStatus('successEditStaffMember')
                }else{
                    setErrorEditStaffMember(true)
                    onSwitchBooleanVarStatus('errorEditStaffMember')
                    console.log(response)
                }
            })
            .catch(error => {
                setErrorEditStaffMember(true)
                onSwitchBooleanVarStatus('errorEditStaffMember')
                console.log(error)
            })
        }
    }

    const options = {
        noDataText: 'Aucun conseiller trouvé.'
    };

    const addStaffHandleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setFields((prevalue) => {
            return {
                ...prevalue,                 
                [name]: name === 'email' ? (value).toLowerCase() : value
            }
        })
        console.log(fields)
    }

    const onValidateAddStaffForm = () => {
        var isValidForm = true;

        if(!fields["fullname"]){
            isValidForm = false;
            set_fullname_empty(true)
            onSwitchBooleanVarStatus('fullname_empty');
            setErrors((prevalue) => {
                return {
                    ...prevalue,                 
                    'fullname': 'Ce champ est obligatoire'
                }
            })
        }
        if(!fields["email"]){
            isValidForm = false;
            set_email_empty(true)
            onSwitchBooleanVarStatus('email_empty');
            setErrors((prevalue) => {
                return {
                    ...prevalue,
                    'email': 'Ce champ est obligatoire'
                }
            })
        }
        if(!fields["userType"]){
            isValidForm = false;
            set_userType_empty(true)
            onSwitchBooleanVarStatus('userType_empty');
            setErrors((prevalue) => {
                return {
                    ...prevalue,
                    'userType': 'Ce champ est obligatoire'
                }
            })
        }

        return isValidForm;
    }
    // 
    const onSubmitAddStaff = (e) => {
        e.preventDefault();

        if(onValidateAddStaffForm()){
            setAddStaffInProgress(true);
            axios.post(ApiBaseUrl + 'conseiller_campus_frances', fields, requestConfig)
                .then(response => {
                    onGetStaffMembers()
                    setAddStaffInProgress(false);

                    if(response.data.success && response.data.exist === false){
                        toast.success('Le compte conseiller a été bien créé.')
                        $('.close').click();
                        $('#fullname').val('');
                        $('#email').val('');
                        $('#userType').val('');
                        
                        setSuccessAddStaff(true)
                        onSwitchBooleanVarStatus('successAddStaff')
                    }else if(response.data.success && response.data.exist === true){
                        setIsUserExist(true)
                        onSwitchBooleanVarStatus('isUserExist')
                    }else{
                        setErrorAddStaff(true)
                        onSwitchBooleanVarStatus('errorAddStaff')
                    }
                })
                .catch(error => {
                    setAddStaffInProgress(false);
                    setErrorAddStaff(true)
                    onSwitchBooleanVarStatus('errorAddStaff')
                    console.log(error.message)
                })
        }

    }

    const onDeleteStaffMember = (e, user_id) => {
        e.preventDefault();
        if(window.confirm("Confirmez-vous la suppression de ce compte ?")){
            axios.get(ApiBaseUrl + 'delete-staff-member/' + user_id, requestConfig)
            .then(response => {
                const resp = response.data;
                if(resp.success){
                    onGetStaffMembers();
                    toast.success(resp.message)
                    setSuccessDeleteStaffMember(true)
                    onSwitchBooleanVarStatus('successDeleteStaffMember')
                }else{
                    toast.error(resp.message)
                    setErrorDeleteStaffMember(true)
                    onSwitchBooleanVarStatus('errorDeleteStaffMember')
                }
            }).catch(error => {
                console.log('error', error)
                toast.error("Oups! Une erreur est survenue lors de la suppression.")
                setErrorDeleteStaffMember(true)
                onSwitchBooleanVarStatus('errorDeleteStaffMember')
            })
        }
    }

    const actionsFormatter = (cell, row) => {
        return(
            <div className="actions-btn-container">
                <button data-toggle="modal" data-target="#showConseiller" className="action-btn oe-view-btn color-white" onClick={(e) => onGetStaffMemberInfos(row)}>
                    <FeatherIcon icon="eye" className="eye-icon" />
                </button> &nbsp;
                <button data-toggle="modal" data-target="#updateStaffMember" className="action-btn oe-edit-btn color-white" onClick={(e) => onGetStaffMemberInfos(row)}>
                    <FeatherIcon icon="edit" className="edit-icon" />
                </button> 
                <button className="action-btn oe-delete-btn color-white" onClick={(e) => onDeleteStaffMember(e, row.id)}>
                    <FeatherIcon icon="trash" className="trash-icon" />
                </button>
            </div>
        )
    }

    return ( 
        <div className="component-liste-conseillers-oe">
            {isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />
            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
                <div className="bordure-bas">
                    <div className="row p-block-20 headering-container">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="logo-soushaulle">
                            <p className="mb-0 bold color-white mb-3">Liste des conseillers</p>
                            </div>
                        </div>
                    </div>

                    <div className="ligne mt-3 mb-5"></div>

                    <div className="row lc-container">
                        <div className="nav-container nav-dashboard-color">
                            <NavVertical />
                        </div>
                        
                        <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 cm-col oe-liste-conseiller-table-container">
                            <div className="row page-content-row">
                                <div className="col-md-4 offset-md-4 orm-add-btn-col">
                                    <button className="orm-add-btn btn btn-primary" data-toggle="modal" data-target="#addStaffMember">
                                        <FeatherIcon icon="plus" className="add-plus-icon" />
                                    </button>
                                </div>
                                <div className="col-md-12 table-content-container">
                                    <BootstrapTable data={conseillers} 
                                        options={options}
                                        striped 
                                        hover 
                                        version='4'
                                        pagination>
                                            <TableHeaderColumn isKey dataField='id' hidden={true}>Conseiller ID</TableHeaderColumn>
                                            <TableHeaderColumn 
                                                dataField='id'
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataFormat={(cell, row) => nameFormatter(cell, row)}
                                            >
                                            Nom complet</TableHeaderColumn>
                                            <TableHeaderColumn 
                                                dataField='role'
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataFormat={(cell, row) => statusFormatter(cell, row)}
                                            >
                                            Statut</TableHeaderColumn>
                                            <TableHeaderColumn 
                                                dataField='email'>
                                            Email</TableHeaderColumn>
                                            <TableHeaderColumn dataFormat={(cell, row) => actionsFormatter(cell, row)} dataField='id'>Actions</TableHeaderColumn>
                                        </BootstrapTable>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            
            {/* <AdminRightSidebar isAdmin={true} /> */}

            <div className="modal fade" id="addStaffMember" tabIndex="-1" role="dialog" aria-labelledby="addStaffMemberTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addStaffMemberTitle"> Ajouter un Admin/Conseiller </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={(e) => onSubmitAddStaff(e)} id="addStaffMemberForm">
                            <div className="modal-body">
                                <div className="row lcm-row">
                                    <div className="col-md-12 cc-col">
                                        <div className="wrap-input100-c validate-input m-b-23 mt-0">
                                            <input className="input100-c border-none color-dark" id="fullname" onChange={addStaffHandleChange} type="text" name="fullname" placeholder="Nom & prénom" />
                                            <span className="focus-input100-c" data-symbol="&#xf206;"></span>
                                        </div>
                                        {fullname_empty &&
                                            <div className="alert alert-danger"> {errors.fullname} </div>
                                        }
                                    </div>

                                    <div className="col-md-12 cc-col">
                                        <div className="wrap-input100-c validate-input m-b-23">
                                            <input className="input100-c color-dark" type="email" id="email" name="email"  onChange={addStaffHandleChange} placeholder="Adresse e-mail" />
                                            <span className="focus-input100-c" data-symbol="&#xf15a;"></span>   
                                        </div>
                                        {email_empty &&
                                            <div className="alert alert-danger"> {errors.email} </div>
                                        }
                                    </div>

                                    <div className="col-md-12 cc-col">
                                        <div className="wrap-input100-c validate-input m-b-23">
                                            <select onChange={addStaffHandleChange} name="userType" className="input100-c border-none color-dark" id="userType">
                                                <option value="">--Choisir un type--</option>
                                                <option value="admin">Admin</option>
                                                <option value="conseiller">Conseiller</option>
                                            </select>
                                            <span className="focus-input100-c" data-symbol="&#xf206;"></span>
                                        </div>
                                        {userType_empty &&
                                            <div className="alert alert-danger"> {errors.userType} </div>
                                        }
                                    </div>
                                    

                                    {errorAddStaff &&
                                        <div className="wrap-input100-c validate-input m-b-23">                   
                                            <div className="alert alert-danger config-form-error-container">
                                                Oupsss ! Une erreur est survenue lors de l'ajout. Veuillez réessayer plus tard.
                                            </div>
                                        </div>
                                    }

                                    {isUserExist &&
                                        <div className="wrap-input100-c validate-input m-b-23">
                                            <div className="alert alert-danger config-form-error-container">
                                            Un utilisateur avec cet adresse email existe déjà. Veuillez en choisir une autre.
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="modal-footer">
                                <div className="form-actions-btn-container">
                                    <button type="button" className="btn btn-danger close-btn modal-close-btn" data-dismiss="modal">
                                        Fermer
                                    </button>
                                    {
                                        addStaffInProgress ?
                                            <button type="button" className="btn btn-primary form-submit-btn-in-modal">
                                                Ajout en cours &nbsp;
                                                <i className="fa fa-spin fa-spinner"></i>
                                            </button> :
                                            <button type="submit" className="btn btn-primary form-submit-btn-in-modal">
                                                <FeatherIcon icon="user-plus" className="icon-user" />&nbsp;
                                                Ajouter
                                            </button>
                                    }
                                </div>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>

            <div className="modal fade" id="showConseiller" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle"> {staffMemberInfos?.conseiller?.conseiller_fullname} </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row lcm-row">
                            <div className="col-md-12 cItem">
                                <span className="lci-label">Nom complet : </span>
                                <span className="lci-content"> {staffMemberInfos?.conseiller?.conseiller_fullname} </span>
                            </div>
                            <hr className="separator-ci" />
                            <div className="col-md-12 cItem">
                                <span className="lci-label">Email : </span>
                                <span className="lci-content"> {staffMemberInfos?.conseiller?.conseiller_email} </span>
                            </div>
                            <hr className="separator-ci" />
                            </div>
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary close-btn" data-dismiss="modal">
                                Fermer
                            </button>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div className="modal fade" id="updateStaffMemberStatus" tabIndex="-1" role="dialog" aria-labelledby="updateStaffMemberStatusTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateStaffMemberStatusTitle"> Modifier le statut de { staffMemberInfos?.role === 'admin' ? staffMemberInfos?.pseudo : staffMemberInfos?.conseiller?.conseiller_fullname } </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={(e) => onSubmitStaffMemberRole(e)} id="editRole">
                            <div className="modal-body">
                                <div className="row lcm-row">
                                    <div className="col-md-12 cc-col">
                                        <div className="wrap-input100-c validate-input m-b-23">
                                            <select onChange={onHandleChangeRole} name="userType" className="input100-c border-none color-dark" id="userType">
                                                <option value="">--Choisir un type--</option>
                                                <option value="admin" selected={staffMemberInfos?.role === 'admin' && true} >Admin</option>
                                                <option value="conseiller-campus-france" selected={staffMemberInfos?.role === 'conseiller-campus-france' && true}>Conseiller</option>
                                            </select>
                                            <span className="focus-input100-c" data-symbol="&#xf206;"></span>
                                        </div>
                                        {userType_empty &&
                                            <div className="alert alert-danger"> {errors.userType} </div>
                                        }
                                    </div>
                                    {
                                    errorEditRole &&
                                        <div className="col-md-12 alert-error-container">
                                            <div className="alert alert-danger messn-alert-danger">
                                                Oupsss! Une erreur est survenue lors de la modification. Veuillez réessayer plus tard.
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="modal-footer">
                                <div className="form-actions-btn-container">
                                    <button type="button" className="btn btn-danger close-btn modal-close-btn" data-dismiss="modal">
                                        Fermer
                                    </button>
                                    {
                                        editRoleInProgress ?
                                            <button type="button" className="btn btn-primary form-submit-btn-in-modal">
                                                En cours...
                                            </button> :
                                            <button type="submit" className="btn btn-primary form-submit-btn-in-modal">
                                                Modifier
                                            </button>
                                    }
                                </div>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>

            <div className="modal fade" id="updateStaffMember" tabIndex="-1" role="dialog" aria-labelledby="updateStaffMemberTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateStaffMemberTitle"> Modifier les infos de { staffMemberInfos?.role === 'admin' ? staffMemberInfos.pseudo : staffMemberInfos?.conseiller?.conseiller_fullname } </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={(e) => onSubmitStaffMemberUpdate(e)} id="editStaffMemberForm">
                            <div className="modal-body">
                                <div className="row lcm-row">
                                    <div className="col-md-12 cc-col">
                                        <div className="wrap-input100-c validate-input m-b-23">
                                            <input value={staffMemberInfos?.email} className="input100-c color-dark" type="email" id="email" name="email"  onChange={onHandleChangeUpdate} placeholder="Adresse e-mail" />
                                            <span className="focus-input100-c" data-symbol="&#xf15a;"></span>   
                                        </div>
                                        {email_empty &&
                                            <div className="alert alert-danger"> {errors.email} </div>
                                        }
                                    </div>
                                    <div className="col-md-12 cc-col">
                                        <div className="wrap-input100-c validate-input m-b-23">
                                            <select onChange={onHandleChangeUpdate} name="role" className="input100-c border-none color-dark" id="userType">
                                                <option value="">--Choisir un type--</option>
                                                <option value="admin" selected={staffMemberInfos?.role === 'admin' && true} >Admin</option>
                                                <option value="conseiller-campus-france" selected={staffMemberInfos?.role === 'conseiller-campus-france' && true}>Conseiller</option>
                                            </select>
                                            <span className="focus-input100-c" data-symbol="&#xf206;"></span>
                                        </div>
                                        {role_empty &&
                                            <div className="alert alert-danger"> {errors.role} </div>
                                        }
                                    </div>
                                    {errorEditStaffMember &&
                                        <div className="col-md-12 alert-error-container">
                                            <div className="alert alert-danger messn-alert-danger">
                                                Oupsss! Une erreur est survenue lors de la modification. Veuillez réessayer plus tard.
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="modal-footer">
                                <div className="form-actions-btn-container">
                                    <button type="button" className="btn btn-danger close-btn modal-close-btn" data-dismiss="modal">
                                        Fermer
                                    </button>
                                    {
                                        editStaffMemberInProgress ?
                                            <button type="button" className="btn btn-primary form-submit-btn-in-modal">
                                                En cours...
                                            </button> :
                                            <button type="submit" className="btn btn-primary form-submit-btn-in-modal">
                                                Modifier
                                            </button>
                                    }
                                </div>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>


            {(successEditRole || successEditStaffMember || successAddStaff || successDeleteStaffMember || errorDeleteStaffMember) &&
                <ToastContainer />
            }

        </div>
    );
}
 
export default MonEspaceListeConseiller;