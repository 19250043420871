import React, { useEffect, useState } from 'react'
import axios from "axios";
import HeaderOutilsEtudiant from "../headerOutilsEtudiant/headerOutilsEtudiant";
import FeatherIcon from "feather-icons-react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  SearchField,
  ButtonGroup,
} from "react-bootstrap-table";
import $ from "jquery";
import formatDate from "../../utils";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-multi-date-picker";
import { ExportToExcel } from "./../modules/ExportToExcel/ExportToExcel";
import NavVertical from "../navVertical/NavVertical";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MultiSelect } from "react-multi-select-component";
import ApiBaseUrl from "../../http/ApiBaseUrl";
import { toast } from "react-hot-toast";
import UseAddOrUpdatePlanningForm from './UseAddOrUpdatePlanningForm';

const PlanningsManager = () => {
    moment.locale('fr', {
        months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
        monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
        monthsParseExact : true,
        weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
        weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
        weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
        weekdaysParseExact : true,
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY',
            LLLL : 'dddd D MMMM YYYY HH:mm'
        },
        calendar : {
            sameDay : '[Aujourd’hui à] LT',
            nextDay : '[Demain à] LT',
            nextWeek : 'dddd [à] LT',
            lastDay : '[Hier à] LT',
            lastWeek : 'dddd [dernier à] LT',
            sameElse : 'L'
        },
        relativeTime : {
            future : 'dans %s',
            past : 'il y a %s',
            s : 'quelques secondes',
            m : 'une minute',
            mm : '%d minutes',
            h : 'une heure',
            hh : '%d heures',
            d : 'un jour',
            dd : '%d jours',
            M : 'un mois',
            MM : '%d mois',
            y : 'un an',
            yy : '%d ans'
        },
        dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
        ordinal : function (number) {
            return number + (number === 1 ? 'er' : 'e');
        },
        meridiemParse : /PD|MD/,
        isPM : function (input) {
            return input.charAt(0) === 'M';
        },
        // In case the meridiem units are not separated around 12, then implement
        // this function (look at locale/id.js for an example).
        // meridiemHour : function (hour, meridiem) {
        //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
        // },
        meridiem : function (hours, minutes, isLower) {
            return hours < 12 ? 'PD' : 'MD';
        },
        week : {
            dow : 1, // Monday is the first day of the week.
            doy : 4  // Used to determine first week of the year.
        }
    });
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    }
    const userID = window.sessionStorage.getItem('user_id')
    const [eventLists, setEventLists] = useState([]);
    const [villes, setVilles] = useState([]);
    const [isPageLoading, setIsPageLoading] = useState(true);

    const [eventInfos, setEventInfos] = useState([]); 
    const [toEdit, setToEdit] = useState(false);

    const [date, setDate] = useState([]);
    const [dates, setDates] = useState([]);
    const [eventStatus, setEvenStatus] = useState('');

    

    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);


    const handleClose = () => {
        setOpenModal(false);
        setOpenEditModal(false);
    }
    const handleShow = () => setOpenModal(true);

    const handleEditClose = () => setOpenEditModal(false);
    const handleEditShow = () => setOpenEditModal(true);


    const onGetEvents = () => {
        axios.get(`${ApiBaseUrl}orientation_remplissage_seances`, requestConfig)
            .then(response => {
                const resp = response.data;
                setIsPageLoading(false);

                if(resp.success){
                    setEventLists(resp.data);
                }else{
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setIsPageLoading(false);
            })
    }
  
    const onGetVilles = () => {
        axios.get(ApiBaseUrl + 'villes', requestConfig)
            .then(response => {
                const resp = response.data;
                if (resp.success) {
                    setVilles(resp.data);
                } else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        onGetEvents();
        onGetVilles();
    }, []);

    const options = {
        noDataText: "Aucune donnée disponible",
    };

    const eventTypeFormatter = (cell, row) => {
        return (
            (row?.is_private === 1 || row?.is_private === false) ? (
                <div className="event_type_item linkable">
                    <span className="event-name"> {cell} </span>
                    <span className="badge badge-danger"> privée </span>
                </div>
            ) : (
                <div className="event_type_item linkable">
                    <span className="event-name"> {cell} </span>
                    <span className="badge badge-primary"> public </span>
                </div>
            )
        )
    }

    const eventTypeFilterValueFormatter = (cell, row) => {
        return (cell)
    }

    const onExportToPDF = (infos, type, date) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        var infs = null;
        var data = null;
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        var title = ''
        if(typeof(date) === 'string'){
            title = "Liste des inscrits - " + type + ' du ' + formatDate(date);
            infs = infos.filter(function(checked) {
                return moment(checked.choosed_date).format("YYYY-MM-DD") === date;
            })
        }else{
            title = "Liste des inscrits - " + type
        }
        
        const headers = [["Dates","Prénom & Nom", "Email", "Téléphone", "Adresse", "Antenne", "Numéro compte FR", "Profil Etudiant"]];
  
        if(typeof(date) === 'string'){
            data = infs.map(elt=> [formatDate(elt.choosed_date), elt.event_checkout_fullname, elt.event_checkout_email, elt.event_checkout_phone, elt.event_checkout_address, elt?.ville?.name, (elt?.event_checkout_compte_etudes_fr_number) ? elt?.event_checkout_compte_etudes_fr_number : "N/A", elt?.numero_dossier]);
        }else{
            data = infos.map(elt=> [formatDate(elt.choosed_date), elt.event_checkout_fullname, elt.event_checkout_email, elt.event_checkout_phone, elt.event_checkout_address, elt?.ville?.name, (elt?.event_checkout_compte_etudes_fr_number) ? elt?.event_checkout_compte_etudes_fr_number : "N/A", elt?.numero_dossier]);
        }
    console.log('data', data)
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        const pdfName = "Export_Liste_Inscrits_" + type
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(`${pdfName}.pdf`)
    }

    const onActionFormatter = (cell, row) => {
        return(
            <div className="actions-btn-container">
                <button 
                    className="btn btn-primary action-btn edit-btn" 
                    onClick={() => {
                        handleEditShow();
                        onGetEventInfos(row);
                    }}
                >
                    <FeatherIcon icon="edit" className="action-icon" />
                </button>
                <button className="btn btn-danger action-btn delete-btn" onClick={() => onDeleteEvent(row)}>
                    <FeatherIcon icon="trash" className="action-icon" />
                </button>
            </div>
        )
    }

    const exportButtonFormatter = (cell, row) => {
        
        let datas = [];
        const exportInfos = [
            {label: "Dates", value: "date"},
            {label: "Prénom & Nom", value: "fullname"},
            {label: "Email", value: "email"},
            {label: "Téléphone", value: "phone"},
            {label: "Adresse", value: "address"},
            {label: "Antenne", value: "antenne"},
            {label: "Numéro compte FR", value: "compte_etudes"},
            {label: "Profil Etudiant", value: "numero_dossier"}
        ];

        if(row?.checkouts?.length > 0){
            /* eslint-disable */
            row?.checkouts?.forEach((checkout) => {
                let dt = {
                    date: moment(checkout?.choosed_date).format("LLL"),
                    fullname: checkout?.event_checkout_fullname,
                    email: checkout?.event_checkout_email,
                    phone: checkout?.event_checkout_phone,
                    address: checkout?.event_checkout_address,
                    antenne: checkout?.ville?.name,
                    compte_etudes: checkout?.event_checkout_compte_etudes_fr_number ? checkout?.event_checkout_compte_etudes_fr_number : "N/A",
                    numero_dossier: checkout?.numero_dossier
                }
                datas.push(dt);
            })
        }

        return(
            <div className="export-actions-btn-container">
                
                {/* {row?.all_dates.map((item) => (
                    <> */}
                        <div 
                            className="export-btn new-export-pdf-btn linkable"
                            onClick={() => onExportToPDF(
                                row.checkouts,
                                row?.event_type, 
                                row?.all_dates[0]?.date
                            )}
                        >
                            PDF
                        </div> 
                        <div className="new-export-to-xlsx-container linkable">
                            <ExportToExcel 
                                isRDV={true} 
                                exportData={datas} 
                                exportLabels={exportInfos} 
                                date={formatDate(row?.all_dates[0]?.date)} 
                            />
                        </div> 
                    {/* </>
                ))} */}
            </div>
        )
    }

    const onGetEventInfos = (event) => {
        console.log(event);
        setToEdit(true);
        setEventInfos(event);
        setEvenStatus(event?.is_online)
    }
  
    const onDeleteEvent = (event) => {
  
        if(window.confirm("Voulez-vous vraiment supprimer ce RDV ?")){
            axios.delete(`${ApiBaseUrl}orientation_remplissage_seances/${event?.id}`, requestConfig)
                .then(response => {
                    const resp = response.data;
                    if(resp.success){
                        onGetEvents()
                        toast.success('La rencontre a été supprimée avec succès.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                    }else{
                        console.log(response)
                        toast.error('Oupsss! Une erreur est survenue lors de la modification.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error('Oupsss! Une erreur est survenue lors de la modification.', {
                        duration: 5000,
                        position: 'top-center'
                    });
                })
        }
    }
  
    const eventVilleFormatter = (cell, row) => {
        if(cell){
          return <span className="ville_name"> { row?.ville?.name } </span>
        }
        return <span className="badge badge-danger">ville non associée</span>
    }

    const eventVilleFilterValueFormatter = (cell, row) => {
        return row?.ville?.name
    }

    const participantsFormatter = (cell, row) => {
        return (
            <>
                <span className={`${row?.all_dates[0]?.participants === 0 ? 'red-participant' : 'blue-participant'}`}>{`${row?.all_dates[0]?.participants}`}</span>
                <span className="nb-counter">{`/${cell}`}</span>
            </>
        )
    }

    const dateFormatter = (cell, row) => {
        return (
            `${moment(row?.all_dates[0]?.date).format('DD-MM-YYYY')}`
        )
    }

    const userFormatter = (cell, row) => {
        return (
            `${cell?.role === 'admin' ? cell?.pseudo : cell?.conseiller?.conseiller_fullname}`
        )
    }

    const handleChangeDate = (date) => {
        let newDate = []
        for (let i = 0; i < date.length; i++) {
          const element = date[i]
          console.log(new Date(element).toISOString())
          newDate.push(new Date(element).toISOString())
        }
        console.log(newDate)
        setDate(date);
        setDates(newDate);
        setValue('event_dates', newDate)
    };

    const {
        register,
        errors,
        clearErrors,
        setValue,
        isRequestLoading,
        reset,
        onSubmit
    } = UseAddOrUpdatePlanningForm(onGetEvents, handleClose, setToEdit, setEventInfos);

    useEffect(() => {
        if (errors) {
            setTimeout(() => {
                Object.entries(errors).map(([key]) => {
                    return clearErrors(key);
                });
            }, 5000);
        }
    }, [errors]);

    useEffect(() => {
        if (toEdit) {
            setValue('id', parseInt(eventInfos?.id));
            setValue('event_type', eventInfos?.event_type);
            setValue('date_id', eventInfos?.all_dates[0]?.id);
            setValue('ville_id', eventInfos?.ville_id);
            setValue('event_date', moment(eventInfos?.all_dates[0]?.date).format("YYYY-MM-DD"));
            setValue('event_start_time', eventInfos?.event_start_time);
            setValue('event_end_time', eventInfos?.event_end_time);
            setValue('event_participant_count', eventInfos?.event_participant_count);
            setValue('is_online', eventInfos?.is_online);
            setValue('is_private', eventInfos?.is_private);
        }
    }, [toEdit]);


    return (
        <div className="component-orientation-remplissage-manager">
            <button
                className="orm-add-btn btn btn-primary floatable-add-btn"
                onClick={handleShow}
            >
                <FeatherIcon icon="plus" className="add-plus-icon" />
            </button>

            {isPageLoading && (
                <div className="ui active inverted dimmer">
                    <div className="ui text loader">Chargement en cours</div>
                </div>
            )}
    
            <HeaderOutilsEtudiant isAdminDashboard={true} />
    
            <section id="cd_container" className="s-h-primo-1">
                <div className="bordure-bas">
                    <div className="row p-block-20 headering-container">
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="logo-soushaulle">
                                <p className="mb-0 bold color-white mb-3">Gestion des plannings</p>
                            </div>
                        </div>
                    </div>
                    <div className="ligne mt-3 mb-5"></div>
                </div>
    
                <div className="row orm-row">
                    <div className="nav-container nav-dashboard-color">
                        <NavVertical />
                    </div>
                    <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                        <div className="row page-content-row">
                            <div className="col-md-12 orm-table-col">
                                <div className="orm-table-container">
                                    <BootstrapTable
                                        data={eventLists}
                                        striped={true}
                                        hover={true}
                                        condensed={true}
                                        options={options}
                                        pagination
                                        version="4"
                                    >
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        hidden={true}
                                    >
                                        Numero
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="100"
                                        dataField="updated_at"
                                        dataFormat={exportButtonFormatter}
                                    >
                                        Exports
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="150"
                                        dataField="all_dates"
                                        dataFormat={dateFormatter}
                                        filter={ { type: 'TextFilter', delay: 1000 } }
                                        filterFormatted
                                    >
                                        Dates
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        width="270"
                                        dataField="event_type"
                                        dataFormat={eventTypeFormatter}
                                        filter={ { type: 'TextFilter', delay: 1000 } }
                                        filterValue={eventTypeFilterValueFormatter}
                                    >
                                        Type de Séance
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="150"
                                        dataField="ville_id"
                                        dataFormat={eventVilleFormatter}
                                        filter={ { type: 'TextFilter', delay: 1000 } }
                                        filterValue={eventVilleFilterValueFormatter}
                                    >
                                        Antenne CF
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="120"
                                        dataField="event_start_time"
                                    >
                                        Heure de début
                                    </TableHeaderColumn>
                                    
                                    <TableHeaderColumn
                                        width="80"
                                        dataField="event_participant_count"
                                        dataFormat={participantsFormatter}
                                    >
                                        NP/NA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="120"
                                        dataField="user"
                                        dataFormat={userFormatter}
                                        filter={ { type: 'TextFilter', delay: 1000 } }
                                        filterFormatted
                                    >
                                        Ajouté par
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        width="150"
                                        dataField="id"
                                        dataFormat={onActionFormatter}
                                        
                                    >
                                        Action
                                    </TableHeaderColumn>
                                    </BootstrapTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={openModal} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter un RDV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="app-modal-form-container">
                        <form id="add-rdv-form" onSubmit={onSubmit}>
                            <div className="row add-event-row">
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="event_type">Type de rencontre{" "}<span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="event_type"
                                        id="event_type"
                                        {...register('event_type')}
                                    >
                                    <option disabled selected>Choisir un type de rencontre</option>
                                    <option value="Orientation Collective">
                                        {" "}
                                        Session d'orientation collective{" "}
                                    </option>
                                    <option value="Aide au remplissage">
                                        {" "}
                                        Atelier d'aide au remplissage{" "}
                                    </option>
                                    <option value="Rendez-vous individuel d'informations">
                                        {" "}
                                        Rendez-vous individuel d'informations{" "}
                                    </option>
                                    <option value="Rendez-vous de complément de dossier">
                                        {" "}
                                        Rendez-vous de complément de dossier{" "}
                                    </option>
                                    <option value="Séance de préparation au séjour en France">
                                        {" "}
                                        Séance de préparation au séjour en France{" "}
                                    </option>
                                    <option value="Aide à la construction du projet">
                                        Aide à la construction du projet
                                    </option>
                                    <option value="Entretien Individuel d'orientation">
                                        Entretien Individuel d'orientation
                                    </option>
                                    <option value="Rendez-vous dépôt et retrait dossier recours">
                                        Rendez-vous dépôt et retrait dossier recours
                                    </option>
                                    </select>
            
                                    {errors?.event_type && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.event_type.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="villes">Antenne Campus France{" "}<span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="ville_id"
                                        id="villes"
                                        {...register('ville_id')}
                                    >
                                        <option selected disabled>Choisir une antenne</option>
                                        {villes?.map((ville, index) => (
                                            <option
                                            key={`select-ville-${index}`}
                                            value={ville?.id}
                                            >
                                            {" "}
                                            {ville?.name}{" "}
                                            </option>
                                        ))}
                                    </select>
            
                                    {errors?.ville_id && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.ville_id.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col datepicker-col">
                                    <label htmlFor="event_date">Date de la rencontre{" "}<span className="required-input">*</span></label>
                                    <DatePicker
                                        inputClass="form-control add-event-form-control cpus-datepicker-input"
                                        multiple
                                        placeholder="Sélectionner une ou plusieurs dates"
                                        value={date}
                                        onChange={handleChangeDate}
                                    />
                                    {errors?.event_dates && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.event_dates.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col"></div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="morning_start_time">Heure de début de la matinée</label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="time"
                                        placeholder="Heure de début de la matinée"
                                        className="form-control add-event-form-control"
                                        name="morning_start_time"
                                        {...register('morning_start_time')}
                                    />
                                </div>
            
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="morning_end_time">Heure de fin de la matinée</label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="time"
                                        placeholder="Heure de fin de la matinée"
                                        className="form-control add-event-form-control"
                                        // name="morning_end_time"
                                        {...register('morning_end_time')}
                                    />
                                </div>
            
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="afternoon_start_time">Heure de début de l'aprés-midi</label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="time"
                                        placeholder="Heure de début de la matinée"
                                        className="form-control add-event-form-control"
                                        // name="afternoon_start_time"
                                        {...register('afternoon_start_time')}
                                    />
                                </div>
            
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="afternoon_end_time">Heure de fin de l'aprés-midi</label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="time"
                                        placeholder="Heure de fin de la matinée"
                                        className="form-control add-event-form-control"
                                        // name="afternoon_end_time"
                                        {...register('afternoon_end_time')}
                                    />
                                </div>
            
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="interval_between_seance">Durée en minutes de chaque séance{" "}<span className="required-input">*</span></label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="number"
                                        placeholder="Durée en minutes de chaque séance"
                                        className="form-control add-event-form-control"
                                        // name="interval_between_seance"
                                        id="interval_between_seance"
                                        {...register('interval_between_seance')}
                                    />
                                    {errors?.interval_between_seance && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.interval_between_seance.message}
                                        </div>
                                    )}
                                </div>
            
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="event_participant_count"> Nombre de participants attendus{" "}<span className="required-input">*</span></label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="number"
                                        placeholder="Nombre de participants attendus"
                                        className="form-control add-event-form-control"
                                        // name="event_participant_count"
                                        id="event_participant_count"
                                        {...register('event_participant_count')}
                                    />
            
                                    {errors?.event_participant_count && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.event_participant_count.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="is_online">Statut <span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="is_online"
                                        id="is_online"
                                        {...register('is_online')}
                                    >
                                    <option disabled selected>Choisir un statut</option>
                                    <option value={1}> Publier en ligne </option>
                                    <option value={0}> Ne pas publier en ligne </option>
                                    </select>
            
                                    {errors?.is_online && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.is_online.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="is_private">Statut d'accès<span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="is_private"
                                        id="is_private"
                                        {...register('is_private')}
                                    >
                                        <option disabled selected>Choisir un accès</option>
                                        <option value={1}> Privé</option>
                                        <option value={0}> Public </option>
                                    </select>
            
                                    {errors?.is_private && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.is_private.message}
                                        </div>
                                    )}
                                </div>
            
                                <div className="col-md-4 offset-md-4 add-event-submit-col">
                                    {!isRequestLoading ? (
                                        <button
                                            className="btn btn-primary add-event-submit-btn"
                                            type="submit"
                                            onClick={() => setValue('requestType', 'add')}
                                            disabled={isRequestLoading}
                                        >
                                            <span className="submit-add-label">Ajouter</span>
                                        </button>
                                        ) : (
                                        <button className="btn btn-primary add-event-submit-btn">
                                            <span className="submit-add-label">En cours ...</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={openEditModal} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier un RDV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="app-modal-form-container">
                        <form id="add-rdv-form" onSubmit={onSubmit}>
                            <div className="row add-event-row">
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="event_type">Type de rencontre{" "}<span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="event_type"
                                        id="event_type"
                                        {...register('event_type')}
                                    >
                                    <option>Choisir un type de rencontre</option>
                                    <option value="Orientation Collective">
                                        {" "}
                                        Session d'orientation collective{" "}
                                    </option>
                                    <option value="Aide au remplissage">
                                        {" "}
                                        Atelier d'aide au remplissage{" "}
                                    </option>
                                    <option value="Rendez-vous individuel d'informations">
                                        {" "}
                                        Rendez-vous individuel d'informations{" "}
                                    </option>
                                    <option value="Rendez-vous de complément de dossier">
                                        {" "}
                                        Rendez-vous de complément de dossier{" "}
                                    </option>
                                    <option value="Séance de préparation au séjour en France">
                                        {" "}
                                        Séance de préparation au séjour en France{" "}
                                    </option>
                                    <option value="Aide à la construction du projet">
                                        Aide à la construction du projet
                                    </option>
                                    <option value="Entretien Individuel d'orientation">
                                        Entretien Individuel d'orientation
                                    </option>
                                    <option value="Rendez-vous dépôt et retrait dossier recours">
                                        Rendez-vous dépôt et retrait dossier recours
                                    </option>
                                    </select>
            
                                    {errors?.event_type && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.event_type.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="villes">Antenne Campus France{" "}<span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="ville_id"
                                        id="villes"
                                        {...register('ville_id')}
                                    >
                                        <option>Choisir une antenne</option>
                                        {villes?.map((ville, index) => (
                                            <option
                                            key={`select-ville-${index}`}
                                            value={ville?.id}
                                            >
                                            {" "}
                                            {ville?.name}{" "}
                                            </option>
                                        ))}
                                    </select>
            
                                    {errors?.ville_id && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.ville_id.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col datepicker-col">
                                    <label htmlFor="event_date">Date de la rencontre{" "}<span className="required-input">*</span></label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="date"
                                        placeholder="Date"
                                        className="form-control add-event-form-control"
                                        {...register('event_date')}
                                    />
                                    {errors?.event_date && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.event_date.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col"></div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="event_start_time">Heure de début de la matinée</label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="time"
                                        placeholder="Heure de début de la matinée"
                                        className="form-control add-event-form-control"
                                        name="event_start_time"
                                        {...register('event_start_time')}
                                    />
                                </div>
            
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="event_end_time">Heure de fin de la matinée</label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="time"
                                        placeholder="Heure de fin de la matinée"
                                        className="form-control add-event-form-control"
                                        // name="event_end_time"
                                        {...register('event_end_time')}
                                    />
                                </div>
            
                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="event_participant_count"> Nombre de participants attendus{" "}<span className="required-input">*</span></label>
                                    <input
                                        // onChange={addEventHandleChange}
                                        type="number"
                                        placeholder="Nombre de participants attendus"
                                        className="form-control add-event-form-control"
                                        // name="event_participant_count"
                                        id="event_participant_count"
                                        {...register('event_participant_count')}
                                    />
            
                                    {errors?.event_participant_count && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.event_participant_count.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="is_online">Statut <span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="is_online"
                                        id="is_online"
                                        {...register('is_online')}
                                    >
                                    <option>Choisir un statut</option>
                                    <option value={1}> Publier en ligne </option>
                                    <option value={0}> Ne pas publier en ligne </option>
                                    </select>
            
                                    {errors?.is_online && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.is_online.message}
                                        </div>
                                    )}
                                </div>

                                <div className="col-md-6 add-event-col">
                                    <label htmlFor="is_private">Statut d'accès<span className="required-input">*</span></label>
                                    <select
                                        // onChange={addEventHandleChange}
                                        className="form-control add-event-form-control"
                                        // name="is_private"
                                        id="is_private"
                                        {...register('is_private')}
                                    >
                                        <option>Choisir un accès</option>
                                        <option value={1}> Privé</option>
                                        <option value={0}> Public </option>
                                    </select>
            
                                    {errors?.is_private && (
                                        <div className="alert alert-danger oe-alert-danger">
                                            {errors.is_private.message}
                                        </div>
                                    )}
                                </div>
            
                                <div className="col-md-4 offset-md-4 add-event-submit-col">
                                    {!isRequestLoading ? (
                                        <button
                                            className="btn btn-primary add-event-submit-btn"
                                            type="submit"
                                            disabled={isRequestLoading}
                                            onClick={() => setValue('requestType', 'edit')}
                                        >
                                            <span className="submit-add-label">Modifier</span>
                                        </button>
                                        ) : (
                                        <button className="btn btn-primary add-event-submit-btn">
                                            <span className="submit-add-label">En cours ...</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PlanningsManager
