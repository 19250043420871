import React, {Component} from 'react';
import './LocalClock.scss'

export default class LocalClock extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
          date: ``
        };
    }

    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    startTime() {
      const today = new Date();
      var h = 0
      if(this.props.timezone === 'dakar'){
        h = today.getHours();
      }
      if(this.props.timezone === 'paris'){
        h = today.getHours() + 1;
      }
      const m = this.checkTime(today.getMinutes());
      const s = this.checkTime(today.getSeconds());
  
      this.setState({ date: h + ":" + m + ":" + s });
      this.timeout = setTimeout(() => this.startTime(), 500);
    }
  
    componentDidMount() {
      this.startTime();
    }
    componentWillUnmount() {
      if (!this.timeout) return;
      clearTimeout(this.timeout);
    }

    render() {
      return(
        <div className="component-local-clock">
          {this.state.date}
        </div>
      );
    }
}
