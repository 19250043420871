import React, {Component} from 'react';
import './GeneratedEventLinkForm.scss'
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/index';
import FooterOutilsEtudiant from '../footerOutilsEtudiant/index';
import api from '../globals/global-vars'
import axios from 'axios'
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FeatherIcon from 'feather-icons-react';
import formatDate from '../../utils'
import ApiBaseUrl from '../../http/ApiBaseUrl';
import { ValidateEmail } from '../../modules/ValidateEmail/ValidateEmail';

export default class GeneratedEventLinkForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: api.api,
          eventCheckoutInProgress: false,
          isSuccessEventCheckout: false,
          isErrorEventCheckout: false,
          is_empty_event_checkout_address: false,
          is_empty_event_checkout_email: false,
          is_empty_event_checkout_fullname: false,
          is_empty_event_checkout_phone: false,
          is_empty_event_checkout_region: false,
          is_empty_event_checkout_compte_etudes_fr: false,
          checkoutHasCompteEtudesFR: false,
          is_empty_event_checkout_compte_etudes_fr_number: false,
          is_invalid_event_checkout_email: false,
          eventInfos: [],
          checkoutFields: {},
          checkoutErrors: {},
          showForm: false,
          generatedLinkNotExist: false,
          alreadySubscribe: false,
          expiredLink: false,
          participantLimitReached: false,
          isLinkGeneratedRequestError: false,
          titleLabel: '',
          eventDateInfos: [],
          generatedLinkInfos: [],
          showNumeroDossierInput: false,
          is_empty_has_doing_auto_evaluation: false,
          is_empty_numero_dossier_auto_evaluation: false,
          villes: [],
          is_different_event_checkout_region: false,

        };

        this.checkoutEventHandleChange = this.checkoutEventHandleChange.bind(this)
        this.onSubmitEventCheckout = this.onSubmitEventCheckout.bind(this)
    }

    componentDidMount(){
      var email = this.props.match.params?.email
      var token = this.props.match.params?.token
      var timestamp = this.props.match.params?.timestamp
    //   console.log(email, token, timestamp)
      this.onGetValidateLinkAndEventInfos(email, token, timestamp);
      this.onGetVilles();
    }

    onGetVilles = () => {
        axios.get(ApiBaseUrl + 'villes')
          .then(response => {
            if (response.data.success) {
              this.setState({
                villes: response.data.data
              })
            } else {
              console.log(response)
            }
          })
          .catch(error => {
            console.log(error)
          })
    }

    onGetValidateLinkAndEventInfos = (email, token, timestamp) => {
      this.setState({
        isPageLoading: true
      })
    //   var api = this.state.api

      axios.get(`${ApiBaseUrl}event-subscribe-link-generated-infos/${email}/${token}/${timestamp}`)
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          if(response.data.success && response.data.exist && !response.data.expired_link && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event){
            this.setState({
              showForm: true,
              eventInfos: response.data.data,
              eventDateInfos: response.data.data_date,
              generatedLinkInfos: response.generatedLinkInfos
            })
            console.log(response.data.data_date)

            if(this.state.eventInfos?.event_type === 'Orientation Collective'){
              this.setState({
                titleLabel: ` à la séance d'${this.state.eventInfos.event_type} du ${formatDate(this.state.eventDateInfos.date)}`
              })
            }
            if(this.state.eventInfos?.event_type === 'Aide au remplissage'){
              this.setState({
                titleLabel: ` à l'atelier d'${this.state.eventInfos.event_type} du ${formatDate(this.state.eventDateInfos.date)}`
              })
            }
            if(this.state.eventInfos?.event_type === 'Rendez-vous individuel d\'informations'){
                this.setState({
                  titleLabel: ` au ${this.state.eventInfos.event_type} du ${formatDate(this.state.eventDateInfos.date)}`
                })
            }
            if(this.state.eventInfos?.event_type === 'Rendez-vous de complément de dossier'){
                this.setState({
                  titleLabel: ` au ${this.state.eventInfos.event_type} du ${formatDate(this.state.eventDateInfos?.date)}`
                })
            }
            if(this.state.eventInfos?.event_type === 'Séance de préparation au séjour en France'){
                this.setState({
                  titleLabel: ` à la ${this.state.eventInfos.event_type} du ${formatDate(this.state.eventDateInfos.date)}`
                })
            }
          }
          if(response.data.success && !response.data.exist && !response.data.expired_link && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event){
            this.setState({
              generatedLinkNotExist: true
            })
          }
          if(response.data.success && response.data.exist && !response.data.expired_link && response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event){
            this.setState({
              alreadySubscribe: true
            })
          }
          if(response.data.success && response.data.exist && response.data.expired_link && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event){
            this.setState({
              expiredLink: true
            })
          }
          if(response.data.success && response.data.exist && !response.data.expired_link && !response.data.already_subscribe && response.data.limit_reached && !response.data.cancelled_event){
            this.setState({
              participantLimitReached: true
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false,
            isLinkGeneratedRequestError: true
          })
        })
    }


    checkoutEventHandleChange = (e) => {
        let fields = this.state.checkoutFields
        fields['event_id'] = this.state.eventInfos.id
        fields[e.target.name] = e.target.value

        if(e.target.name === 'event_checkout_email'){
            fields[e.target.name] = e.target.value.toLowerCase()
        }
        if(e.target.name === 'event_checkout_compte_etudes_fr' && e.target.value === '1'){
            this.setState({
                checkoutHasCompteEtudesFR: true
            })
        }
        if(e.target.name === 'event_checkout_compte_etudes_fr' && e.target.value === '0'){
            this.setState({
                checkoutHasCompteEtudesFR: false
            })
        }
        if(fields['has_doing_auto_evaluation'] === '1' || fields['has_doing_auto_evaluation'] === 1){
            this.setState({
                showNumeroDossierInput: true
            })
        }
        if(fields['has_doing_auto_evaluation'] === '0' || fields['has_doing_auto_evaluation'] === 0){
            this.setState({
                showNumeroDossierInput: false
            })
        }

      this.setState({
          checkoutFields: fields
      })
      console.log(fields)
    }

    onValidateCheckoutEventForm = () => {
        let fields = this.state.checkoutFields
        let errors = this.state.checkoutErrors
        var isValidForm = true

        if(!fields['event_checkout_fullname']){
            isValidForm = false
            this.setState({
                is_empty_event_checkout_fullname: true
            })

            setTimeout(() => {
                this.setState({
                    is_empty_event_checkout_fullname: false
                })
            }, 5000);

            errors['event_checkout_fullname'] = 'Ce champ est obligatoire'
        }

        if(!fields['event_checkout_email']){
            isValidForm = false
            this.setState({
                is_empty_event_checkout_email: true
            })

            setTimeout(() => {
                this.setState({
                    is_empty_event_checkout_email: false
                })
            }, 5000);

            errors['event_checkout_email'] = 'Ce champ est obligatoire'
        }

        if (typeof fields["event_checkout_email"] !== "undefined" && !ValidateEmail(fields["event_checkout_email"])) {
            isValidForm = false;
            this.setState({
            is_invalid_event_checkout_email: true,
            });
            setTimeout(() => {
            this.setState({
                is_invalid_event_checkout_email: false, 
            });
            }, 5000);
    
            errors["invalid_event_checkout_email"] = "Votre adresse email n'est pas valide";
        }

        if(!fields['event_checkout_phone']){
            isValidForm = false
            this.setState({
                is_empty_event_checkout_phone: true
            })

            setTimeout(() => {
                this.setState({
                    is_empty_event_checkout_phone: false
                })
            }, 5000);

            errors['event_checkout_phone'] = 'Ce champ est obligatoire'
        }

        if(!fields['event_checkout_address']){
            isValidForm = false
            this.setState({
                is_empty_event_checkout_address: true
            })

            setTimeout(() => {
                this.setState({
                    is_empty_event_checkout_address: false
                })
            }, 5000);

            errors['event_checkout_address'] = 'Ce champ est obligatoire'
        }

        if(!fields['event_checkout_region'] || fields['event_checkout_region'] === '--Choisir une ville--'){
            isValidForm = false
            this.setState({
                is_empty_event_checkout_region: true
            })

            setTimeout(() => {
                this.setState({
                    is_empty_event_checkout_region: false
                })
            }, 5000);

            errors['event_checkout_region'] = 'Ce champ est obligatoire'
        }
        if (typeof fields["event_checkout_region"] !== undefined) {
            console.log('fields["event_checkout_region"]', fields["event_checkout_region"])
            console.log('this.state.eventInfos?.eventInfos?.ville?.id', this.state.eventInfos)
            if(parseInt(fields["event_checkout_region"]) !== this.state.eventInfos?.ville_id){
                isValidForm = false
                this.setState({
                    is_different_event_checkout_region: true
                })

                setTimeout(() => {
                    this.setState({
                        is_different_event_checkout_region: false
                    })
                }, 5000);

                errors['different_event_checkout_region'] = 'Votre ville de résidence ne correspond à la ville associée à l\'événement.'
            }
        }

        if(!fields['event_checkout_compte_etudes_fr'] || fields['event_checkout_compte_etudes_fr'] === '--Choisir une option--'){
            isValidForm = false
            this.setState({
                is_empty_event_checkout_compte_etudes_fr: true
            })

            setTimeout(() => {
                this.setState({
                    is_empty_event_checkout_compte_etudes_fr: false
                })
            }, 5000);

            errors['event_checkout_compte_etudes_fr'] = 'Ce champ est obligatoire'
        }

        if(typeof fields['event_checkout_compte_etudes_fr'] !== undefined && fields['event_checkout_compte_etudes_fr'] === 1){
            if(!fields['event_checkout_compte_etudes_fr_number']){
                isValidForm = false
                this.setState({
                    is_empty_event_checkout_compte_etudes_fr_number: true
                })

                setTimeout(() => {
                    this.setState({
                        is_empty_event_checkout_compte_etudes_fr_number: false
                    })
                }, 5000);

                errors['event_checkout_compte_etudes_fr_number'] = 'Ce champ est obligatoire'
            }
        }

        if(!fields['has_doing_auto_evaluation'] || fields['has_doing_auto_evaluation'] === '--Choisir--'){
            isValidForm = false
            this.setState({
                is_empty_has_doing_auto_evaluation: true
            })
            setTimeout(() => {
                this.setState({
                    is_empty_has_doing_auto_evaluation: false
                })
            }, 5000);
            errors['has_doing_auto_evaluation'] = 'Ce champ est obligatoire'
        }
        
        if(this.state.showNumeroDossierInput){
            if(!fields['numero_dossier_auto_evaluation']){
                isValidForm = false
                this.setState({
                    is_empty_numero_dossier_auto_evaluation: true
                })
                setTimeout(() => {
                    this.setState({
                        is_empty_numero_dossier_auto_evaluation: false
                    })
                }, 5000);
                errors['numero_dossier_auto_evaluation'] = 'Ce champ est obligatoire'
            }
        }

        this.setState({
            checkoutErrors: errors
        })

        return isValidForm
    }

    onSubmitEventCheckout = (e) => {
        e.preventDefault()
        if(this.onValidateCheckoutEventForm()){
            this.setState({
                eventCheckoutInProgress: true
            })
            // var api = this.state.api
            var data = this.state.checkoutFields
            data['checkout_date_id'] = this.state.eventDateInfos.id
            data['true_comparable_email'] = this.props.match.params?.email;
            data['true_comparable_token'] = this.props.match.params?.token;
            data['true_comparable_timestamp'] = this.props.match.params?.timestamp;
            // data['event_subscribe_link_generates_id'] =  this.state.generatedLinkInfos.id;

            axios.post(`${ApiBaseUrl}event_checkouts`, data)
                .then(response => {
                    this.setState({
                        eventCheckoutInProgress: false
                    })

                    if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event && !response.data.bad_link){
                        if(this.state.isOpenSeanceOrientationView){
                            this.onGetOrientations()
                        }
                        if(this.state.isOpenSeanceRemplissageView){
                            this.onGetSeanceDeRemplissage()
                        }
                        toast.success('Votre inscription a été bien prise en compte.')
                        
                        this.setState({
                            checkoutFields: {},
                            checkoutErrors: {},
                            isSuccessEventCheckout: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isSuccessEventCheckout: false
                            })
                        }, 5000);
                    }else if(response.data.success && response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event && !response.data.bad_link){
                        toast.error('Désolé! vous ne pouvez pas vous réinscrire puis que vous êtes déjà inscrit à cette séance.')
                        
                        this.setState({
                            isErrorEventCheckout: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorEventCheckout: false
                            })
                        }, 5000);
                    }else if(response.data.success && !response.data.already_subscribe && response.data.limit_reached && !response.data.cancelled_event && !response.data.bad_link){
                        toast.error('Oupsss! Désolé, votre inscription ne peut pas être prise en compte. Nous avons atteint le nombre de participants pour cette séance.')
                        
                        this.setState({
                            isErrorEventCheckout: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorEventCheckout: false
                            })
                        }, 5000);
                    }else if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && response.data.cancelled_event && !response.data.bad_link){
                        toast.error('Oupsss! Désolé, vous ne pouvez pas vous inscrire car cette séance a été annulé.')
                        
                        this.setState({
                            isErrorEventCheckout: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorEventCheckout: false
                            })
                        }, 5000);
                    }else if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event && response.data.bad_link){
                        // toast.error("")
                        toast.error("Oups! Les informations que vous avez fourni ne correspondent pas à celles avec lesquelles le lien d'inscription a été généré.", {
                            position: "top-right",
                            autoClose: 20000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        
                        this.setState({
                            isErrorEventCheckout: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorEventCheckout: false
                            })
                        }, 20000);
                    }else{
                        toast.error('Oupsss! Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.')
                        console.log(response)
                        this.setState({
                            isErrorEventCheckout: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isErrorEventCheckout: false
                            })
                        }, 5000);
                    }

                    $('#event_checkout_fullname').val('')
                    $('#event_checkout_email').val('')
                    $('#event_checkout_phone').val('')
                    $('#event_checkout_address').val('')
                    $('#event_checkout_region').val('')
                    $('#numero_dossier_auto_evaluation').val('')
                    $('#has_doing_auto_evaluation').val('--Choisir--')
                    $('#event_checkout_compte_etudes_fr').val('--Choisir une option--')
                    $('#event_checkout_compte_etudes_fr_number').val('')
                    $('.close').click()
                })
                .catch(error => {
                    toast.error('Oupsss! Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.')
                    console.log(error)
                    this.setState({
                        eventCheckoutInProgress: false,
                        isErrorEventCheckout: true
                    })
                    setTimeout(() => {
                        this.setState({
                            isErrorEventCheckout: false
                        })
                    }, 5000);
                })
        }
    }

    render() {
      return(
        <div className="component-generated-event-link-form">
          {this.state.isPageLoading &&
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Chargement en cours</div>
            </div>
          }

          <HeaderOutilsEtudiant isAdminDashboard={false} />

          <div className="half-top-accueil fixing-after-header gl-form-container">
            <div className="container">
              <h1 className="ff-nunito fw-900 text-center m-t-100 m-b-40 wow fadeInLeft generated-link-formulaire-title">Formulaire d'inscription {this.state.titleLabel} </h1>
              
              <form id="generated_event_form">
                {this.state.showForm &&
                  <div className="row add-event-row">
                      <div className="col-md-6 add-event-col">
                          <label forHtml="event_checkout_fullname">Prénom et Nom</label>
                          <input onChange={this.checkoutEventHandleChange} type="text" placeholder="Votre Prénom et Nom" className="form-control oe-form-control" name="event_checkout_fullname" id="event_checkout_fullname" />

                          {this.state.is_empty_event_checkout_fullname &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.event_checkout_fullname } </div>
                          }
                      </div>
                      <div className="col-md-6 add-event-col">
                          <label forHtml="event_checkout_email">Votre Email</label>
                          <input onChange={this.checkoutEventHandleChange} type="text" placeholder="Votre Email" className="form-control oe-form-control" name="event_checkout_email" id="event_checkout_email" />
                          
                          {this.state.is_empty_event_checkout_email &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.event_checkout_email } </div>
                          } 
                          {this.state.is_invalid_event_checkout_email &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.invalid_event_checkout_email } </div>
                          }
                      </div>
                      <div className="col-md-6 add-event-col">
                          <label forHtml="event_checkout_phone">Votre Téléphone</label>
                          <input onChange={this.checkoutEventHandleChange} type="text" placeholder="Votre Téléphone" className="form-control oe-form-control" name="event_checkout_phone" id="event_checkout_phone" />
                          
                          {this.state.is_empty_event_checkout_phone &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.event_checkout_phone } </div>
                          }
                      </div>
                      <div className="col-md-6 add-event-col">
                          <label forHtml="event_checkout_address">Votre Adresse</label>
                          <input onChange={this.checkoutEventHandleChange} type="text" placeholder="Votre adresse de résidence" className="form-control oe-form-control" name="event_checkout_address" id="event_checkout_address" />
                          
                          {this.state.is_empty_event_checkout_address &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.event_checkout_address } </div>
                          }
                      </div>
                        <div className="col-md-6 o-form-col">
                            <div className="form-group o-form-group">
                                <label htmlFor="has_doing_auto_evaluation">Avez-vous été auto-évalué ? <span className="required-icon">*</span></label>
                                <select onChange={this.checkoutEventHandleChange} name="has_doing_auto_evaluation" className="form-control oe-form-control v2-select-form-control" id="has_doing_auto_evaluation">
                                    <option selected disabled>--Choisir--</option>
                                    <option value={1}>Oui</option>
                                    <option value={0}>Non</option>
                                </select>
                            </div>
                            {this.state.is_empty_has_doing_auto_evaluation &&
                                <div className="alert alert-danger oe-alert-danger">
                                    {this.state.checkoutErrors.has_doing_auto_evaluation}
                                </div>
                            }
                        </div>
                        
                        {this.state.showNumeroDossierInput &&
                            <div className="col-md-6 o-form-col">
                                <div className="form-group o-form-group">
                                    <label htmlFor="numero_dossier_auto_evaluation">Votre Numéro de dossier <span className="required-icon">*</span></label>
                                    <input placeholder="Votre Numéro de dossier" onChange={this.checkoutEventHandleChange} name="numero_dossier_auto_evaluation" className="form-control oe-form-control" id="numero_dossier_auto_evaluation" />
                                </div>
                                {this.state.is_empty_numero_dossier_auto_evaluation &&
                                    <div className="alert alert-danger oe-alert-danger">
                                        {this.state.checkoutErrors.numero_dossier_auto_evaluation}
                                    </div>
                                }
                            </div>
                        }
                        <div className="col-md-6 o-form-col">
                            <div className="form-group o-form-group">
                                <label forHtml="event_checkout_region">Votre Région de résidence</label>
                                <select onChange={this.checkoutEventHandleChange} name="event_checkout_region" id="event_checkout_region" className="form-control oe-form-control v2-select-form-control">
                                    <option>--Choisir une ville--</option>
                                    {
                                        this.state.villes?.map((ville, index) => 
                                            <option key={`select-ville-${index}`} value={ville?.id}> { ville?.name } </option>
                                        )
                                    }
                                </select>
                            </div>
                            {this.state.is_empty_event_checkout_region &&
                                <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.event_checkout_region } </div>
                            }
                            {this.state.is_different_event_checkout_region &&
                                <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.different_event_checkout_region } </div>
                            }
                        </div>
                      <div className="col-md-6 add-event-col">
                          <label forHtml="event_checkout_compte_etudes_fr">Avez-vous un compte etudes SN ?</label>
                          <select onChange={this.checkoutEventHandleChange} name="event_checkout_compte_etudes_fr" id="event_checkout_compte_etudes_fr" className="form-control oe-form-control v2-select-form-control">
                              <option>--Choisir une option--</option>
                              <option value={1}> OUI </option>
                              <option value={0}> NON </option>
                          </select>
                          
                          {this.state.is_empty_event_checkout_compte_etudes_fr &&
                              <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.event_checkout_compte_etudes_fr } </div>
                          }
                      </div>

                      {this.state.checkoutHasCompteEtudesFR &&
                          <div className="col-md-6 add-event-col">
                              <label forHtml="event_checkout_compte_etudes_fr_number">Votre Numero de Compte Etudes SN</label>
                              <input onChange={this.checkoutEventHandleChange} type="text" placeholder="Votre Numero de Compte Etudes SN" className="form-control oe-form-control" name="event_checkout_compte_etudes_fr_number" id="event_checkout_compte_etudes_fr_number" />
                              
                              {this.state.is_empty_event_checkout_compte_etudes_fr_number &&
                                  <div className="alert alert-danger oe-alert-danger"> { this.state.checkoutErrors.event_checkout_compte_etudes_fr_number } </div>
                              }
                          </div>
                      }

                      <div className="col-md-6 offset-md-3 add-event-submit-col">
                          {!this.state.eventCheckoutInProgress ?
                              <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitEventCheckout(e) }>
                                  <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                                  <span className="submit-add-label">S'inscrire</span>
                              </button> :
                              <button className="btn btn-primary add-event-submit-btn">
                                  <i className="fas fa-spin fa-spinner"></i>&nbsp;
                                  <span className="submit-add-label">Inscription en cours</span>
                              </button>
                          }
                      </div>
                  </div>
                }
                
                {this.state.expiredLink &&
                  <div className="row empty-alert-row">
                    <div className="col-md-2 empty-icon-col">
                        <FeatherIcon
                            icon="alert-circle"
                            className="m-b-3 empty-alert-icon rotateIn"
                        />
                    </div>
                    <div className="col-md-10 empty-textual-col">
                        <p><strong>Désolé! Le lien est expiré. Veuillez postuler directement sur la plateoforme ou contacter un(e) conseiller(e) pour disposer d'un nouveau lien d'inscription</strong></p>
                    </div>
                  </div>
                }

                {this.state.generatedLinkNotExist &&
                  <div className="row empty-alert-row">
                    <div className="col-md-2 empty-icon-col">
                        <FeatherIcon
                            icon="alert-circle"
                            className="m-b-3 empty-alert-icon rotateIn"
                        />
                    </div>
                    <div className="col-md-10 empty-textual-col">
                        <p><strong>Désolé! Ce lien est invalide.</strong></p>
                    </div>
                  </div>
                }

                {this.state.participantLimitReached &&
                  <div className="row empty-alert-row">
                    <div className="col-md-2 empty-icon-col">
                        <FeatherIcon
                            icon="alert-circle"
                            className="m-b-3 empty-alert-icon rotateIn"
                        />
                    </div>
                    <div className="col-md-10 empty-textual-col">
                        <p><strong>Désolé! Vous ne pouvez postuler pour cette séance car la limite de participants est déjà atteinte.</strong></p>
                    </div>
                  </div>
                }

                {this.state.alreadySubscribe &&
                  <div className="row empty-alert-row">
                    <div className="col-md-2 empty-icon-col">
                        <FeatherIcon
                            icon="alert-circle"
                            className="m-b-3 empty-alert-icon rotateIn"
                        />
                    </div>
                    <div className="col-md-10 empty-textual-col">
                        <p><strong>Désolé! Vous êtes déjà inscrit à cette séance.</strong></p>
                    </div>
                  </div>
                }

                {this.state.isLinkGeneratedRequestError &&
                  <div className="row empty-alert-row">
                    <div className="col-md-2 empty-icon-col">
                        <FeatherIcon
                            icon="alert-circle"
                            className="m-b-3 empty-alert-icon rotateIn"
                        />
                    </div>
                    <div className="col-md-10 empty-textual-col">
                        <p><strong>Oupsss! Une erreur est survenue lors de la vérification du lien. Veuillez réessayer plus tard ou contacter un(e) conseiller(e).</strong></p>
                    </div>
                  </div>
                }
              </form>

            </div>
          </div>

          <FooterOutilsEtudiant />
          
          {this.state.isSuccessEventCheckout || this.state.isErrorEventCheckout ?
            <ToastContainer /> : ''
          }
        </div>
      );
    }
}
