import React, {useEffect, useState} from 'react';
import './AdminAndConseillerDashboard.css'
import ApiBaseUrl from '../../http/ApiBaseUrl';
import axios from 'axios';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import LocalClock from '../localClock/LocalClock'
import { Bar } from 'react-chartjs-2';
import NavVertical from '../navVertical/NavVertical';

const AdminAndConseillerDashboard = () => {
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    };
    const userRole = window.sessionStorage.getItem('user_role');
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [chartDate, setChartDate] = useState([]);
    const [chartCount, setChartCount] = useState([]);
    const [filterRequestInProgress, setFilterRequestInProgress] = useState(false);
    const [isFilterRequestError, setIsFilterRequestError] = useState(false);
    const [is_empty_month, set_is_empty_month] = useState(false);
    const [is_empty_year, set_is_empty_year] = useState(false);
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [isEmptyData, setIsEmptyData] = useState(false);
    // const [currentUser, setCurrentUser] = useState();
    // const currentTalkjsUser = window.sessionStorage.getItem('currentTalkjsUser');
    // console.log('currentUser', currentUser)

    

    const onGetInitialChartData = () => {
  
        axios.get(`${ApiBaseUrl}need-help-chart-initial-data`, requestConfig)
          .then(response => {
            setIsPageLoading(false)
            if(response.data.success){
              setChartDate(response.data.date_array)
              setChartCount(response.data.count_array)
            }
          })
          .catch(error => {
            console.log(error)
            setIsPageLoading(false)
          })
    }

    useEffect(() => {
      // if (currentTalkjsUser) {
      //   setCurrentUser(JSON.parse(currentTalkjsUser))
      // }
      // console.log('currentUser', currentUser)
      onGetInitialChartData()
        // eslint-disable-next-line
    }, [])

    const handleChangeFilter = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setFields((prevalue) => {
            return {
                ...prevalue,                 
                [name]: value
            }
        })
        console.log(fields)
    }
  
    const handleFormValidation = () => {
        var isValidForm = true
  
        if(!fields['month'] || fields['month'] === '-- Choisir un mois --'){
            isValidForm = false
            set_is_empty_month(true)
            setTimeout(() => {
                set_is_empty_month(false)
            }, 5000);

            setErrors((prevalue) => {
                return {
                    ...prevalue,                 
                    'month': 'Vous devez choisir un mois.'
                }
            })
        }
        if(!fields['year'] || fields['month'] === '-- Choisir une année --'){
            isValidForm = false
            set_is_empty_year(true)
            setTimeout(() => {
                set_is_empty_year(false)
            }, 5000);
            setErrors((prevalue) => {
                return {
                    ...prevalue,                 
                    'year': 'Vous devez choisir une année.'
                }
            })
        }
  
        return isValidForm;
    }
  
    const onSubmitFilter = (e) => {
        console.log(fields)
        e.preventDefault()
        if(handleFormValidation()){
          setFilterRequestInProgress(true)
  
          axios.post(`${ApiBaseUrl}need-help-chart-data`, fields, requestConfig)
            .then(response => {
              setFilterRequestInProgress(false);
                if(response.data.success){
                    setChartDate(response.data.date_array)
                    setChartCount(response.data.count_array)

                    if(response.data.count_data === 0){
                        setIsEmptyData(true)
                        setFilterRequestInProgress(false)
                        setTimeout(() => {
                            setIsEmptyData(false)
                        }, 5000);
                    }
                    
                }
            })
            .catch(error => {
                console.log(error)
                setFilterRequestInProgress(false)
                setIsFilterRequestError(true)
                setTimeout(() => {
                    setIsFilterRequestError(false)
                }, 5000);
            })
        }
    }

    const data = {
        labels: chartDate,
        datasets: [{
            type: 'line',
            label: 'Prise en charge',
            data: chartCount,
            fill: false,
            backgroundColor: '#1F497D',
            borderColor: '#1F497D',
            hoverBackgroundColor: '#71B37C',
            hoverBorderColor: '#71B37C',
            yAxisID: 'y-axis-1'
        }]
    };

    const options = {
        responsive: true,
        labels: chartDate,
        tooltips: {
            mode: 'label'
        },
        elements: {
            line: {
                fill: false
            }
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    display: false
                },
                labels: chartDate,
            }],
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            },
            {
                type: 'linear',
                display: false,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                }
            }
            ]
        }
    };

    let plugins = [{
        afterDraw: (chartInstance, easing) => {
        }
    }];

    return ( 
        <div className="component-admin-and-conseiller-dashboard">
            {isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <section id="cd_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="row sh-row">
                      <div className="col-md-3 sh-col clock-col">
                        <h3 className="timezone-title"> Heure de Paris </h3>
                        <LocalClock timezone="paris" />
                      </div>
                      <div className="col-md-6 sh-col page-title-col">
                        <div className="logo-soushaulle">
                          <p className="mb-0 bold color-white mb-3">Dashboard { userRole === 'admin' ? 'Admin' : 'Conseiller' } </p>
                        </div>
                      </div>
                      <div className="col-md-3 sh-col clock-col">
                        <h3 className="timezone-title"> Heure de Dakar </h3>
                        <LocalClock timezone="dakar" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

              </div>
              

              <div className="container need-help-chart-container">
                

                <div className="row need-help-chart-row">
                  <div className="nav-container nav-dashboard-color">
                      <NavVertical />
                  </div>

                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-10 col-md-12 content-position-after-header px-md-3 px-0 need-help-chart-col">
                    <div className="need-help-chart-content">
                      <form id="filter-need-help-count-form" onSubmit={(e) => onSubmitFilter(e)}>
                        <div className="row nhf-row">
                          <div className="col-md-3"></div>
                          <div className="col-md-3 nhf-form-col">
                            <div className="form-group nhf-form-group">
                              <select name="month" onChange={handleChangeFilter} className="form-control nhf-form-control">
                                <option>-- Choisir un mois --</option>
                                <option value="01">Janvier</option>
                                <option value="02">Fevrier</option>
                                <option value="03">Mars</option>
                                <option value="04">Avril</option>
                                <option value="05">Mai</option>
                                <option value="06">Juin</option>
                                <option value="07">Juillet</option>
                                <option value="08">Août</option>
                                <option value="09">Septembre</option>
                                <option value="10">Octobre</option>
                                <option value="11">Novembre</option>
                                <option value="12">Décembre</option>
                              </select>
                            </div>
                            {is_empty_month &&
                              <div className="alert alert-danger oe-alert-danger filter-error-alert"> { errors.month } </div>
                            }
                          </div>
                          <div className="col-md-3 nhf-form-col">
                            <div className="form-group nhf-form-group">
                              <select name="year" onChange={handleChangeFilter} className="form-control nhf-form-control">
                                <option>-- Choisir une année --</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                              </select>
                            </div>
                            {is_empty_year &&
                              <div className="alert alert-danger oe-alert-danger filter-error-alert"> { errors.year } </div>
                            }
                          </div>
                          <div className="col-md-3 nhf-form-col">
                            {filterRequestInProgress ?
                              <button className="btn btn-primary need-help-btn-filter">
                                <i className="fas fa-spin fa-spinner"></i>&nbsp;
                                En cours...
                              </button> :
                              <button className="btn btn-primary need-help-btn-filter">
                                Valider
                              </button>
                            }
                            
                          </div>
                          {isFilterRequestError &&
                            <div className="col-md-12 oe-server-error-message-container">
                              <div className="alert alert-danger oe-alert-danger">
                                Un probléme de serveur est survenu lors de votre requête. Veuillez réessayer plus tard.
                              </div>
                            </div>
                          }
                        </div>
                      </form>
                      <h2 className="chart-title"> Graphe des prises en charge </h2>
                      {isEmptyData &&
                        <div className="row empty-data-row">
                          <div className="col-md-12 empty-data-col">
                            <div className="alert alert-warning oe-alert-warning">
                              <button className="close" data-dismiss="alert" type="button">x</button>
                              Aucune donnée statistique n'est disponible pour cette période.
                            </div>
                          </div>
                        </div>
                      }
                      <Bar
                          data={data}
                          options={options}
                          plugins={plugins}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* {userRole === 'admin' ?
                <AdminRightSidebar isAdmin={true} /> :
                <AdminRightSidebar isAdmin={false} />
              } */}
              
            </section>

          </div>
    );
}
 
export default AdminAndConseillerDashboard;