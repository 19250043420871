import React, {Component} from 'react';
import './footerOutilsEtudiant.css';
import LogoFooter from '../../img/logo-footer.png';
import IconFb from '../../img/icons/fb.png';
import IconTw from '../../img/icons/tw.png';
import IconYb from '../../img/icons/yb.png';
import IconInsta from '../../img/icons/insta.png';
import IconLkd from '../../img/icons/lkd.png';
// import { NavLink } from 'react-router-dom';

export default class FooterOutilsEtudiant extends Component {
    constructor(props) {
        super(props);
        this.state = {
          copyrightYear: new Date().getFullYear()
        };
    }


    render() {
      return  <footer>
                <div className="container">
                  <div className="row m-b-80">
                    <div className="col-xl-5 col-md-12">
                      <img className="logo-footer" src={LogoFooter} alt="Campus France SN" />
                      <p className="text-about-footer">
                        Campus France Sénégal est un service de l'Ambassade de France au Sénégal dédié aux étudiants sénégalais et étrangers (Hors Union Européenne) résidant au Sénégal et désireux de poursuivre leurs études supérieures en France.
                      </p>
                    </div>
                    <div className="col-xl-3 col-md-6">
                      <h5 className="h-footer">événements</h5>
                      <a className="link-footer" href="https://salonvirtuelsenegal.campusfrance.org/" target="_blank" rel="noopener noreferrer">
                        Salon virtuel
                      </a>
                      <a className="link-footer" href="https://jposenegal.campusfrance.org/" target="_blank" rel="noopener noreferrer">
                        Journées portes ouvertes
                      </a>
                      <a className="link-footer" href="https://www.francealumni.fr/fr/poste/senegal/page/10511/adherer" target="_blank" rel="noopener noreferrer">
                        France Alumni
                      </a>
                    </div>
                    <div className="col-xl-4 col-md-6">
                      <h5 className="h-footer">Contact</h5>
                      <div className="row m-b-15">
                        <div className="col-md-3">
                          <p className="h-contact">Adresse</p>
                        </div>
                        <div className="col-md-9">
                          <p className="p-contact">km1 Avenue Cheikh Anta Diop, Dakar</p>
                        </div>
                      </div>
                      {/* <div className="row m-b-15">
                        <div className="col-md-3">
                          <p className="h-contact">Téléphone</p>
                        </div>
                        <div className="col-md-9">
                          <p className="p-contact">+221 77 000 00 00 +221 77 000 00 00</p>
                        </div>
                      </div>
                      <div className="row m-b-25">
                        <div className="col-md-3">
                          <p className="h-contact">Email</p>
                        </div>
                        <div className="col-md-9">
                          <p className="p-contact">leader@gmail.com</p>
                        </div>
                      </div> */}

                      <h6 className="contact-us">Nous suivre</h6>
                      <div>
                        <a href="https://www.facebook.com/Senegal.CampusFrance" target="_blank" rel="noopener noreferrer">
                          <img className="icon-contact-us" src={IconFb} alt="Facebook" />
                        </a>
                        <a href="https://twitter.com/CFsenegal" target="_blank" rel="noopener noreferrer">
                          <img className="icon-contact-us" src={IconTw} alt="Twitter" />
                        </a>
                        <a href="https://www.youtube.com/channel/UCVN7JrWCgFeYFTNnrcmgYiQ" target="_blank" rel="noopener noreferrer">
                          <img className="icon-contact-us" src={IconYb} alt="Youtube" />
                        </a>
                        <a href="https://www.instagram.com/campusfrancesenegal/?hl=fr" target="_blank" rel="noopener noreferrer">
                          <img className="icon-contact-us" src={IconInsta} alt="Instagram" />
                        </a>
                        <a href="https://www.linkedin.com/company/campus-france-sénégal/" target="_blank" rel="noopener noreferrer">
                          <img className="icon-contact-us" src={IconLkd} alt="LinkedIn" />
                        </a>
                      </div>
                    </div>
                  </div>

                  <p className="copyright">© { this.state.copyrightYear } Copyright: <span className="fw-700 text-white">Campus France Sénégal</span></p>
                </div>
              </footer>;
    }
  }
// export default connect(
//     ({ footer }) => ({ ...footer }),
//     dispatch => bindActionCreators({ ...footerActions }, dispatch)
//   )( footer );