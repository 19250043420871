import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function NewsSkeleton() {
  return (
    <div className="news-skeleton-component">
        <div className="row news-skeleton-row">
            <div className="col-md-4 news-item-skeleton-col">
                <div className="news-item-skeleton">
                    <div className="news-feed-slide-item-container">
                        <div className="bews-feed-slide-item-card">
                            <div className="news-feed-item-image-container">
                                <Skeleton 
                                    height={250}
                                    className="news-image-skeleton"
                                />
                            </div>
                            <div className="news-feed-item-title-container">
                                <h1 className="news-title"> 
                                    <Skeleton 
                                        count={3}
                                    /> 
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 news-item-skeleton-col">
                <div className="news-item-skeleton">
                    <div className="news-feed-slide-item-container">
                        <div className="bews-feed-slide-item-card">
                            <div className="news-feed-item-image-container">
                                <Skeleton 
                                    height={250}
                                    className="news-image-skeleton"
                                />
                            </div>
                            <div className="news-feed-item-title-container">
                                <h1 className="news-title"> 
                                    <Skeleton 
                                        count={3}
                                    /> 
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4 news-item-skeleton-col">
                <div className="news-item-skeleton">
                    <div className="news-feed-slide-item-container">
                        <div className="bews-feed-slide-item-card">
                            <div className="news-feed-item-image-container">
                                <Skeleton 
                                    height={250}
                                    className="news-image-skeleton"
                                />
                            </div>
                            <div className="news-feed-item-title-container">
                                <h1 className="news-title"> 
                                    <Skeleton 
                                        count={3}
                                    /> 
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsSkeleton