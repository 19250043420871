export default function formatDate(date){
    var a = new Date(date);
    var months = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    var days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date_r = a.getDate();
    // var hour = a.getHours();
    // var min = a.getMinutes();
    // var sec = a.getSeconds();
    var format = dayName + ' ' + date_r + ' ' + month + ' ' + year;
    return format;
}

