import React, { Component, Fragment } from 'react'
import './conseillerChatroom.scss'
import Talk from "talkjs";
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import talkjsID from '../../http/TalkJSInfos';

export default class ConseillerOutilsEtudiantChatroom extends Component{
    constructor(props) {
        super(props)
        
        this.state = {
            
        };
    };

    componentDidMount(){
        this.onCreateTalkContext()
    }

    onCreateTalkContext = () => {
        Talk.ready
          .then(() => {
            const campusfrancesn = {
                id: 'oe-campus-france-senegal-conseiller-chatting',
                name: 'Campus France SN',
                email: 'noreplycampusfrancesenegal@withvolkeno.com',
                role: "messager",
                photoUrl: 'https://ui-avatars.com/api/?name=Campus+France&background=random',
                // signature: currentUser.signature
            }

            const me = new Talk.User(campusfrancesn);
            const appId = talkjsID;
  
              //console.log(this.state.userAvatar)
    
              if (!window.talkSession) {
                  window.talkSession = new Talk.Session({
                      appId: appId,
                      me: me,
                    //   signature: this.state.currentUser.signature
                  });
              }
              //console.log(this.state.currentUser)
              this.inbox = window.talkSession.createInbox({
                showMobileBackButton: true,
                feedConversationTitleMode: "subject",
                chatTitleMode: "subject"
              });
            
              this.inbox.mount(this.container);
              
              window.talkSession.unreads.on("change", function (conversationIds) {
                var unreadCount = conversationIds.length;
                if (unreadCount > 0) {
                  document.title = "(" + unreadCount + " message(s) non lus) | Campus France SN";
                } else {
                  document.title = "Campus France SN";
                }
                //document.getElementById("unread-message-count").innerHTML = unreadCount;
                console.log("Conversation ID's", conversationIds);
              });
    
          })
          .catch(e => console.error(e));
      }

    render(){
        return(
            <div className="conseiller-outils-etudiant-chatroom-component">
                <header className="oe-chatroom-dashboard-header">
                    <HeaderOutilsEtudiant isAdminDashboard={true} />
                </header>

                <div className="container oe-chatroom-dashboard-container">
                    <div className="row oe-chatroom-dashboard-row">
                        <div className="col-md-12 oe-chatroom-dashboard-col">
                            <Fragment>
                                <div style={{height: '500px'}} className="conseiller-inbox-container" ref={c => this.container = c}>Loading...</div>
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}