import React, { useEffect, useState } from 'react';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import BackendBaseurl from '../../http/BackendBaseUrl';

const LandingPageEventV2 = () => {
    // eslint-disable-next-line 
    const [eventsInProgress, setEventInProgress] = useState(true);
    const [events, setEvents] = useState([]);
    const [isEmptyEvent, setIsEmptyEvent] = useState();

    const onGetActiveEvents = () => {
        axios.get(ApiBaseUrl + 'active-evenements')
            .then(response => {
                if(response.data.success){
                    setEventInProgress(false);
                    setEvents(response.data.data);
                    if(response.data.is_empty === true){
                        setIsEmptyEvent(true)
                    }
                    if(response.data.is_empty === false){
                        setIsEmptyEvent(false)
                    }
                    console.log(response.data.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        onGetActiveEvents();
    }, [])


    return (
        <section id="v2-events-carousel-section">
            <div className="container-fluid v2-events-carousel-container">
                <div className="events-carousel-body-content">
                    <h2 className="custom-section-title">Événements</h2>

                    {isEmptyEvent ?
                        <div className="row empty-alert-row">
                            <div className="col-md-2 empty-icon-col">
                                <FeatherIcon
                                    icon="alert-circle"
                                    className="m-b-3 empty-alert-icon rotateIn"
                                />
                            </div>
                            <div className="col-md-10 empty-textual-col">
                                <p><strong>Aucun événement n'est prévu pour le moment.</strong></p>
                            </div>
                        </div> :
                    
                        <div className="v2-events-carousel-container">
                            <div id="eventCarouselIndicator" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    {
                                        events?.map((event, index) => {
                                            return (
                                                <li key={'indicatore-' + event?.id} data-target="#eventCarouselIndicator" data-slide-to={index} className={index === 0 && 'active'}></li>
                                            )
                                        })
                                    }
                                </ol>
                                <div className="carousel-inner">
                                    {events?.map((item, ind) => {
                                        return (
                                            <div className={"carousel-item " + (ind === 0 && 'active')} key={'event-' + ind} >
                                                <img className="d-block w-100" src={ BackendBaseurl + '/' + item?.full_image_path } alt="Carousel Item" />
                                                {item?.event_subscribe_link &&
                                                    <div className="carousel-item-btn-container">
                                                        <div className="carousel-item-btn">
                                                            <a 
                                                                // eslint-disable-next-line
                                                                href={item.event_subscribe_link} 
                                                                target="_blank" 
                                                                rel="noopener noreferrer" 
                                                                className="btn btn-success event-subscribe-btn">
                                                                    Inscrivez-vous
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <a className="carousel-control-prev" href="#eventCarouselIndicator" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#eventCarouselIndicator" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default LandingPageEventV2