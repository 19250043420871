import React, { useEffect, useState } from 'react';
import LandingPageHeaderV2 from '../../landingPageV2/LandingPageHeaderV2';
import FooterV2 from '../../footerV2/FooterV2';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import ApiBaseUrl from '../../../http/ApiBaseUrl';
import axios from 'axios';
import WarningIcon from '../../../img/v2/icons/WarningIcon.svg';

const RapportView = () => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [profile, setProfile] = useState();
    const [hasNoProfil, setHasNoProfile] = useState(true);
    // const [requestError, setRequestError] = useState(false);
    const params = useParams();

    
    const getCandidatEvaluatedRapport = (numero_dossier) => {
        axios.get(`${ApiBaseUrl}getCandidatEvaluatedRapport/${numero_dossier}`)
            .then(response => {
                setIsPageLoading(false);
                const rep = response.data;
                console.log('rep', rep)
                if(rep.success && rep.has_profile){
                    setHasNoProfile(false);
                    setProfile(rep.data);
                }
                if(!rep.success && !rep.has_profile){
                    setHasNoProfile(true);
                }
            })
            .catch(error => {
                console.log('error', error);
                // setRequestError(true);
                setIsPageLoading(false);
            })
    }

    useEffect(() => {
        getCandidatEvaluatedRapport(params?.numero_dossier);
    }, [params]);

    return (
        <div className="mon-profil-etudiant-component">
            {isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <LandingPageHeaderV2 />

            <section className="profil-etudiant-section profil-view-by-cf-conseiller" id="profil-section">
                <div className="container profil-etudiant-container">
                    <div className="ae-quizz-page-title">
                        <h1>Rapport d'auto-évaluation</h1>
                    </div>
                    
                    {!hasNoProfil && (
                        <div className="profil-etudiant-page-content">
                            <div className="row profil-etudiant-section-row">
                                <div className="col-md-8 offset-md-2 section-title-container">
                                    <div className="ae-quizz-item-container">
                                        <div className="ae-quizz-item-content-body">
                                            <h2 style={{
                                                color: profile?.flag_color === "FEU VERT" ? "#00AF51" : profile?.flag_color === "FEU ORANGE" ? "#FFCD00" : profile?.flag_color === "FEU ROUGE" && "#E74C3C"
                                            }}>VOUS AVEZ OBTENU UN { profile?.flag_color } </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 profil-etudiant-infos-col">
                                    <div className="profil-etudiant-infos-item-container">
                                        {profile?.flag_color === "FEU VERT" ? (
                                            <div className="rapport-container-view-by-candidat"> 
                                                <p style={{textAlign:'center'}}>Vous venez de terminer le test d’auto-évaluation de votre projet d’études en
                                                France, et nous vous félicitons de votre implication. Votre trouverez ci-après
                                                un rapport explicatif du résultat obtenu.</p>
                                                <p style={{textAlign:'center'}}>Veuillez trouver ci-dessous votre numéro de dossier : </p>
                                                <h1 style={{color:'black',textAlign:'center', fontWeight: '800'}}>{ profile?.numero_dossier }</h1>
                                                <p>Sur la base de vos déclarations, nous pensons que votre dossier est susceptible d’intéresser les établissements d’enseignement supérieur français. </p>
                                                <p><b style={{fontWeight: '600'}}>Cependant Campus France ayant mis en place une liste de critères d’inéligibilité, nous vous invitons à vérifier, avant toute procédure, que vous n’êtes dans aucun des cas listés.</b> Cette liste est disponible sur tous nos supports (calendrier, dépliants, site campus France Sénégal ; plateforme monespacesn, page d’accueil compte Campus France).</p>
                                                <p><b style={{fontWeight: '600'}}>Attention ! Le fait d’avoir un feu vert ne veut pas dire que votre dossier est totalement abouti, il peut toujours être amélioré. </b></p>
                                                <p>Tous les établissements n’ayant pas les mêmes exigences, nous vous invitons, avant de soumettre votre dossier, à vous renseigner sur les attendus de ces derniers (série de Bac prioritaire ou privilégiée, moyennes minimales, tests de langues, tests de compétences …) et remplir votre panier en connaissance de cause (attention aux délais du calendrier Campus France). </p>
                                                <p>Plusieurs éléments peuvent permettre d’améliorer et surtout personnaliser votre dossier qui doit refléter vos motivations, vos envies et vos objectifs de carrière. La cohérence entre vos projets d’études et professionnels est essentielle, voire déterminante, pour obtenir une acceptation au sein d’un établissement d’enseignement supérieur français. </p>
                                                <p>N’hésitez pas à indiquer clairement dans votre dossier, les stages effectués, les associations ou clubs auxquels vous participez ou avez participés ainsi que vos loisirs s’ils sont en lien ou susceptibles d’être un atout pour les études envisagées.  Si vous avez suivi des MOOCs en lien avec la filière d’études envisagée, pensez à le préciser, et éventuellement joindre les certificats obtenus ou décernés, car ils peuvent vous aider à démarquer davantage votre candidature.</p>
                                                <p>Si vous préparez le Bac ou venez juste de l’obtenir, vous pouvez être intéressé par une ou plusieurs filières d’études. Cependant, vous devez vous assurer de remplir les prérequis académiques pour chacune des formations souhaitées (un projet structuré sera un atout de plus). </p>
                                                <p>Pour les étudiants inscrits dans les établissements d’enseignement supérieur (privé comme public), il est important de savoir que les universités ont beaucoup plus d’exigences que vous. En effet, plus votre parcours post-bac est avancé (Bac+3 à + 8), plus les établissements auront des attentes spécifiques par rapport à votre dossier : élaboration d’un projet construit, existence d’un projet professionnel ou de recherche clairement structuré et documenté. Nous vous invitons donc à prévoir, dans votre dossier, un document qui développe votre projet professionnel ou de recherche (par exemple, un mini-projet de mémoire + un plan détaillé au niveau Master). </p>
                                                <p>Pour la réussite de votre projet, il est important de s’informer sur la formation envisagée, son coût, ses prérequis et débouchés mais aussi le coût de la vie en France (ressources financières et logement).</p>
                                                <p>Vous pouvez, si vous le souhaitez, prendre un rendez-vous d’orientation avec un de nos agents pour affiner votre projet ou améliorer votre dossier. Pour cela rendez-vous sur le tchat via <a href="https://monespacesn.campusfrance.org/">(https://monespacesn.campusfrance.org)</a>  et communiquez à l’agent votre code d’identification du test.</p>
                                                <p>A travers notre site internet <a href="https://www.senegal.campusfrance.org/">(https://www.senegal.campusfrance.org)</a>, notre plateforme monespacesn <a href="https://monespacesn.campusfrance.org/">(https://monespacesn.campusfrance.org)</a> et nos différents espaces d’accueil, nous vous renseignons et vous accompagnons gratuitement pour votre procédure d’études en France. Nous vous conseillons de vous investir personnellement dans votre projet et de ne pas confier la gestion de votre dossier à une quelconque personne ou structure.</p>
                                                <p>Nous vous mettons en garde contre la fraude documentaire qui entraînera inévitablement un arrêt définitif, pour la campagne en cours et les 4 années suivantes, de votre projet de mobilité car Campus France vérifie l’authenticité de tous les justificatifs présentés par les candidats.</p>
                                                <p><b style={{fontWeight: '600'}}>La clé d’un dossier attractif est l’implication et la sincérité du candidat ! </b></p>
                                            </div>
                                        ) : profile?.flag_color === "FEU ORANGE" ? (
                                            <div className="rapport-container-view-by-candidat"> 
                                                <p style={{textAlign:'center'}}>Vous venez de terminer le test d’auto-évaluation de votre projet d’études en
                                                France et nous vous félicitons de votre implication. Votre trouverez ci-après
                                                un rapport explicatif du résultat obtenu.</p>

                                                <p style={{textAlign:'center'}}>Veuillez trouver ci-dessous votre numéro de dossier : </p>
                                                <h1 style={{color:'black',textAlign:'center', fontWeight: '800'}}>{ profile?.numero_dossier }</h1>

                                                <p>Votre dossier contient des éléments qui fragilisent votre projet d’étude en
                                                France. <b style={{fontWeight: '600'}}>Il doit être renforcé pour avoir plus de chances d’être accepté au sein
                                                d’un établissement d’enseignement supérieur français.</b> </p>

                                                <p>La demande étant largement supérieure à l’offre, l’accès aux universités françaises
                                                est très sélectif. Il est important de savoir que chaque formation a ses prérequis et
                                                ses attendus qu’il faut remplir.</p>

                                                <p>Au-delà de vos résultats scolaires, qui représentent un critère très important pour
                                                les établissements français, il y a aussi les motivations énoncées ou ressenties,
                                                la cohérence entre les projets d’études et professionnel ainsi que la connaissance
                                                de la formation ciblée (prérequis, déroulement et débouchés).</p>

                                                <p>Pour la réussite de votre projet, il est important de bien s’informer  sur la
                                                formation envisagée, son coût, ses prérequis et débouchés mais aussi le coût de la
                                                vie en France (ressources financières et logement). Comme tout projet, votre projet
                                                de mobilité doit être réaliste et réalisable. </p>

                                                <p>Au vu du résultat mitigé de votre test, et avant d’entamer des démarches pour une
                                                poursuite d’études en France, vous devez vous poser un certain nombre de questions
                                                préalables telles que :
                                                <ul>
                                                    <li>Est-ce que j’ai le niveau requis pour intégrer la formation envisagée ? Vous pouvez le vérifier en comparant de manière objective et sincère le programme de formation (volume horaire des cours et TD) de l’établissement français avec celui de votre établissement d’origine. Assurez-vous de répondre aux exigences des établissements (bons résultats académiques dans l’ensemble et surtout dans les Unités d’Enseignement ou matières fondamentales ; continuité dans le parcours, score et niveau requis dans le(s) test(s) de langue ou de compétences…)</li>
                                                    <li>Est-ce que mon projet d’études est réfléchi et abouti ? Pour répondre à cette question vous devez vous assurer de faire des recherches sérieuses pour avoir une réelle connaissance des formations souhaitées (coûts, déroulé et débouchés). Assurez-vous aussi que ces formations aient un lien avec votre parcours antérieur ou alors qu’elles le complètent.</li>
                                                </ul>
                                                </p>

                                                <p>Est-ce que j’ai bien réfléchi à ce que je souhaite faire après l’obtention de mon diplôme ? Il ne s’agit pas uniquement de répondre OUI à cette question mais de savoir si vous souhaitez poursuivre vos études (vérifiez que votre formation vous le permette), vous engager dans la recherche (pour quel projet ?) ou vous insérer professionnellement.</p>

                                                <p>Est-ce que j’ai bien réfléchi à ce qui me motive à vouloir faire des études en France et est-ce que j’ai bien su retranscrire mes motivations ? Attention, à ce niveau, aux motivations légères ou stéréotypées.</p>

                                                <p>Est-ce que j’ai les moyens de financer mes études ? Il est important de prendre au sérieux cette question, et de vous assurer d’avoir les moyens ou un garant qui en dispose, avant de vous lancer dans un quelconque projet d’études en France.</p>

                                                <p>Après avoir vérifié ces différents éléments d\'appréciation, vous pourrez toujours vous rendre sur le tchat de monespacesn <a href="https://monespacesn.campusfrance.org/">(https://monespacesn.campusfrance.org)</a> pour échanger avec un de nos agents, après lui avoir communiqué votre numéro d’identification du test, sur la manière à améliorer  votre dossier si vous comptez déposer une candidature. </p>

                                                <p><b style={{fontWeight: '600'}}>Attention ! Campus France ayant mis en place une liste de critères d’inéligibilités, nous vous invitons à vérifier, avant toute procédure, que vous n’êtes dans aucun des cas listés.</b> Cette liste est disponible sur tous nos supports (calendrier, dépliants, site campus France Sénégal ; plateforme monespacesn, page d’accueil compte Campus France).</p>

                                                <p>A travers notre site internet <a href="https://www.senegal.campusfrance.org/">(https://www.senegal.campusfrance.org/)</a>, notre plateforme monespacesn <a href="https://www.senegal.campusfrance.org/">(https://monespacesn.campusfrance.org)</a> et nos différents espaces d’accueil, nous vous renseignons et vous accompagnons gratuitement pour vos procédures d’études en France. Nous vous conseillons de vous investir personnellement dans votre projet et de ne pas confier la gestion de votre dossier à une quelconque personne ou structure.</p>

                                                <p>Nous vous mettons en garde contre la fraude documentaire qui entraînera inévitablement un arrêt définitif, pour la campagne en cours et les 4 années suivantes, de votre projet de mobilité car Campus France vérifie l’authenticité de tous les justificatifs présentés par les candidats.</p>
                                            </div>
                                        ) : profile?.flag_color === "FEU ROUGE" && (
                                            <div className="rapport-container-view-by-candidat"> 
                                                <p style={{textAlign:'center'}}>Vous venez de terminer le test d’auto-évaluation de votre projet d’études en France et nous vous félicitons de votre implication. Votre trouverez ci-après un rapport explicatif du résultat obtenu.</p>

                                                <p style={{textAlign:'center'}}>Veuillez trouver ci-dessous votre numéro de dossier : </p>
                                                <h1 style={{color:'black',textAlign:'center', fontWeight: '800'}}>{ profile?.numero_dossier }</h1>

                                                <p>Le feu rouge que vous avez obtenu signifie qu’en l’état, votre dossier n’est pas assez solide ni valorisant pour vous garantir une acceptation au sein d’un établissement d’enseignement supérieur français. <b style={{fontWeight: '600'}}>Vous devez davantage réfléchir à la faisabilité de votre projet et vérifier sa recevabilité.</b> </p>

                                                <p><b style={{fontWeight: '600'}}>En effet, Campus France ayant mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.</b> Cette liste est disponible sur tous nos supports (calendrier, dépliants, site campus France Sénégal ; plateforme monespacesn, page d’accueil compte Campus France).</p>

                                                <p>Il semble que  plusieurs éléments de votre dossier (Parcours / résultats/ projets d’étude et de vie) pourraient constituer un obstacle à la réussite de votre projet de mobilité.</p>

                                                <p>Un parcours abandonné, inachevé ou entaché par de multiples redoublements dans le secondaire et / ou le supérieur n’est pas non plus rassurant pour une mobilité réussie au sein des Etablissements d’Enseignement Supérieur. </p>

                                                <p>De même qu’un projet d’études et/ou professionnel non abouti, bancal ou incohérent ainsi que des motivations légères ou autres que pédagogiques sont également des freins à votre mobilité.  </p>

                                                <p>Au-delà de vos résultats scolaires qui représentent un critère très important pour les établissements français, il y a aussi les motivations énoncées ou ressenties, la cohérence entre les projets d’études et professionnel mais aussi la connaissance de la formation ciblée (prérequis, déroulement et débouchés) et des conditions de vie en France (le coût des études, les ressources financières et le logement).</p>

                                                <p>Il est important de garder à l’esprit que les candidatures sont beaucoup plus nombreuses que les places proposées par les établissements ce qui renforce leur sélectivité ! </p>

                                                <p>Si vous ne répondez pas aux conditions d’admission des universités françaises et /ou aux critères de la mobilité, vous pouvez toujours envisager d’autres solutions au niveau local (Campus Franco-Sénégalais, établissements privés, insertion professionnelle…), finir le parcours déjà entamé (pour rassurer sur vos capacités) avant de tenter une mobilité ou encore repenser votre projet en vous servant d’outils d’orientation disponibles sur Internet tels que l’ONISEP <a href="https://www.onisep.fr">(www.onisep.fr)</a> ou le CIDJ <a href="https://www.cidj.com">(www.cidj.com)</a></p>

                                                <p><b style={{fontWeight: '600'}}>Attention ! Campus France ayant mis en place une liste de critères d’inéligibilités, nous vous invitons à vérifier, avant toute procédure, que vous n’êtes dans aucun des cas listés.</b> Cette liste est disponible sur tous nos supports (calendrier, dépliants, site campus France Sénégal ; plateforme monespacesn, page d’accueil compte Campus France).</p>

                                                <p>A travers notre site internet <a href="https://www.senegal.campusfrance.org/">(https://www.senegal.campusfrance.org)</a>, notre plateforme monespacesn <a href="https://monespacesn.campusfrance.org/">(https://monespacesn.campusfrance.org)</a> et nos différents espaces d’accueil, nous vous renseignons et vous accompagnons gratuitement pour vos procédures d’études en France. Nous vous conseillons de vous investir personnellement dans votre projet et de ne pas confier la gestion de votre dossier à une quelconque personne ou structure.</p>

                                                <p>Nous vous mettons en garde contre la fraude documentaire qui entraînera inévitablement un arrêt définitif, pour la campagne en cours et les 4 années suivantes, de votre projet de mobilité car Campus France vérifie l’authenticité de tous les justificatifs présentés par les candidats.</p>

                                                <p><i>Attention ! Le fait d’avoir un feu rouge ne veut pas dire que votre dossier ne peut pas être amélioré pour intéresser une université. Mais nous vous conseillons de vous rapprocher d’un conseiller pour comprendre les éléments pouvant vous permettre d’y parvenir».</i></p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {hasNoProfil && (
                        <section className="request-error-section">
                            <div className="container request-error-container">
                                <div className="request-error-page-content">
                                    <div className="row request-error-row">
                                        <div className="v2-modal-body-col col-md-12 v2-modal-empty-badge-col">
                                            <div className="v2-modal-empty-badge-body">
                                                <div className="v2-modal-empty-badge-icon-container">
                                                    <img src={WarningIcon} alt="Empty" className="v2-modal-empty-badge-icon" />
                                                </div>
                                                <div className="v2-modal-empty-badge-message-container">
                                                    <span>Oups! Aucun profil n'est associé au numéro de dossier {params?.numero_dossier}. </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </section>

            

            <FooterV2 />
        </div>
    )
}

export default RapportView;