import React, { Component } from 'react';
import './MonEspaceEmptyUserTokenView.css'
import { NavLink } from 'react-router-dom';
import Logo from '../../img/logo-campus-france.png';
import FeatherIcon from 'feather-icons-react';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'

export default class EmptyUserTokenView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="component-mon-espace-empty-user-token-view">
        <HeaderOutilsEtudiant isAdminDashboard={true} />

        <div className="row homepage-content-container">
          <div className="container card-container">
            <div className="row box-row">
              <div className="col-md-10 offset-md-1 card-col">
                <div className="card">
                  <div className="card-body">
                    <div className="boxy-container container">
                      <div className="row box-body-row">
                        <div className="col-md-4 offset-md-4 header-col">
                          <img className="empty-token-page-logo" src={Logo} alt="Mon Espace Campus France SN" />
                        </div>
                        <div className="col-md-12 welcome-text-container">
                          {this.props.type === 'null' &&
                            <p className="welcome">Cette page est sécurisée !</p>
                          }
                          {this.props.type === 'session' &&
                            <p className="welcome">Ouppps votre session est expirée</p>
                          }
                          <p className="welcome-body">
                            Vous devez vous connecter pour accéder aux pages sécurisées de la plateforme.
                          </p>
                        </div>
                        <div className="col fake-box"></div>
                        <div className="col-md-6 align-self-end homagepage-btn-container">
                          <NavLink to="/admin-connexion" type="button" className="btn h-action-btn h-login-btn">
                            <FeatherIcon icon="log-in" className="login-icon" />&nbsp;
                            Se connecter
                          </NavLink>
                          <NavLink to="/" type="button" className="btn h-action-btn h-home-btn">
                            <FeatherIcon icon="home" className="home-icon" />&nbsp;
                            Page d'accueil
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}