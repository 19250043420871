import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import axios from "axios";
import ApiBaseUrl from '../../http/ApiBaseUrl';



const UseAddOrUpdatePlanningForm = (onGetEvents, handleClose, setToEdit, setEventInfos) => {
    const user_id = window.sessionStorage.getItem('user_id')
    const [isRequestLoading, setIsRequestLoading] = useState(false);
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    }
    const validationSchema = useMemo(() => (
        yup.object().shape({
            // event_type: yup.string().required('Ce champ est requis.').notOneOf(['Choisir un type de rencontre'], 'Ce champ est requis.'),
            // ville_id: yup.string().required('Ce champ est requis.').notOneOf(['Choisir une antenne'], 'Ce champ est requis.'),
            // event_dates: yup.mixed().required("Ce champ est requis"),
            // interval_between_seance: yup.string().required("Ce champ est requis"),
            // event_participant_count: yup.string().required("Ce champ est requis"),
            // interval_between_seance: yup.string().required("Ce champ est requis"),
            // is_online: yup.string().required('Ce champ est requis.').notOneOf(['Choisir un statut'], 'Ce champ est requis.'),
            // is_private: yup.string().required('Ce champ est requis.').notOneOf(["Choisir un accès"], 'Ce champ est requis.'),
        })
    ), [])


    const { register, handleSubmit, formState: {errors}, clearErrors, setValue, resetField, reset } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const AddPlanningRequest = (data) => {
        setIsRequestLoading(true);
        let fd = new FormData();
        for (let key in data) {
            if(key === 'event_dates'){
                const dates = data[key];
                for (let i = 0; i < dates?.length; i++) {
                    let date = dates[i];
                    fd.append(`${key}[${i}]`, date);
                }
            }else{
                fd.append(key, data[key])
            }
        }

        axios.post(`${ApiBaseUrl}orientation_remplissage_seances`, fd, requestConfig)
        .then(response => {
            const resp = response.data;
            setIsRequestLoading(false);
            if(resp.success){
                onGetEvents();
                handleClose();
                toast.success('La rencontre a été bien ajoutée.', {
                    duration: 5000,
                    position: 'top-center'
                });

                reset();
            }else{
                console.log(response)
                toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.', {
                    duration: 5000,
                    position: 'top-center'
                });
            }
        })
        .catch(error => {
            console.log(error)
            toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.', {
                duration: 5000,
                position: 'top-center'
            });
            setIsRequestLoading(false);
        })
       
    }

    const UpdatePlanningRequest = (data) => {
        setIsRequestLoading(true);
        // let fd = new FormData();
        // for (let key in data) {
        //     fd.append(key, data[key])
        // }

        axios.put(`${ApiBaseUrl}orientation_remplissage_seances/${data?.id}`, data, requestConfig)
        .then(response => {
            const resp = response.data;
            setIsRequestLoading(false);
            if(resp.success){
                onGetEvents();
                handleClose();
                setToEdit(false);
                setEventInfos([]);
                toast.success('La rencontre a été bien modifiée.', {
                    duration: 5000,
                    position: 'top-center'
                });

                reset();
            }else{
                console.log(response)
                toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.', {
                    duration: 5000,
                    position: 'top-center'
                });
            }
        })
        .catch(error => {
            console.log(error)
            toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.', {
                duration: 5000,
                position: 'top-center'
            });
            setIsRequestLoading(false);
        })
    }

    const SubmitRequest = async (requestData) => {
        requestData['user_id'] = user_id;
        console.log('requestData', requestData)
        if(requestData?.requestType === 'add'){
            AddPlanningRequest(requestData);
        }
        if(requestData?.requestType === 'edit'){
            // requestData['_method'] = 'PUT';
            UpdatePlanningRequest(requestData);
        }
    };

    return {
        register,
        errors,
        clearErrors,
        setValue,
        isRequestLoading,
        reset,
        onSubmit: handleSubmit(SubmitRequest)
    }
}

export default UseAddOrUpdatePlanningForm;
