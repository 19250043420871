import React, { useState, useEffect } from 'react'
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/index';
import axios from 'axios'
import $ from 'jquery'
import 'react-toastify/dist/ReactToastify.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import toast from 'react-hot-toast';
import './JeMinformeManager.css'
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';

const JeMinformeManager = () => {
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    }
    // eslint-disable-next-line
    const token = window.sessionStorage.getItem('token');
    const [isPageLoading, setIsPageLoading] = useState(true)
    // eslint-disable-next-line
    const [isSessionExpiration, setIsSessionExpiration] = useState(false)
    const [jeMinformeData, setJeMinformeData] = useState()
    const [addJeMinformeInProgress, setAddJeMinformeInProgress] = useState(false)
    let [formErrors, setFormErrors] = useState({})
    let [formFields, setFormFields] = useState({})
    const [isEmptyLabel, setIsEmptyLabel] = useState(false)
    // eslint-disable-next-line
    const [isEmptyLink, setIsEmptyLink] = useState(false)
    // eslint-disable-next-line
    const [isEmptyIsActive, setIsEmptyIsActive] = useState(false)
    const userID = window.sessionStorage.getItem('user_id')
    const [isSubmitErrorMessage, setIsSubmitErrorMessage] = useState(false)
    let [jeMinformeItem, setJeMinformeItem] = useState()

    const onGetJeMinformeData = () => {
        
        axios.get(`${ApiBaseUrl}admin_je_minformes`, requestConfig)
            .then(response => {
                setIsPageLoading(false)
                if(response.data.success){
                    console.log(response.data.data)
                    setJeMinformeData(response.data.data)
                }else{
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setIsPageLoading(false)
                if (error.message === 'Request failed with status code 401') {
                    setIsSessionExpiration(true)
                }
            })
    }


    useEffect(() => {
        onGetJeMinformeData()
        // eslint-disable-next-line
    }, [])

    const options = {
        // clearSearch: true,
        // clearSearchBtn: this.createCustomClearButton,
        noDataText: 'Aucune donnée disponible',
        // searchField: this.createCustomSearchField,
        // btnGroup: this.createCustomButtonGroup
    };

    const onGetJeMinformeInfos = (item) => {
        setJeMinformeItem(item)
    }

    const onActionFormatter = (cell, row) => {
        return(
          <div className="actions-btn-container">
            <button className="btn btn-primary action-btn edit-btn" data-toggle="modal" data-target=".edit-jeminforme-modal" onClick={() => onGetJeMinformeInfos(row)}>
              <FeatherIcon icon="edit" className="action-icon" />
            </button>
          </div>
        )
    }

    const handleChange = (e) => {
        let fields = formFields
        fields[e.target.name] = e.target.value
        setFormFields(fields)
        // console.log(formFields)
    }

    const onValidateForm = (verb) => {
        var isValidForm = true
        let errors = formErrors
        if(verb === 'create'){
            if(!formFields['label']){
                isValidForm = false
                setIsEmptyLabel(true)
                errors['label'] = 'Ce champ est requis.'
                setTimeout(() => {
                    setIsEmptyLabel(false)
                }, 5000);
            }
            if(!formFields['link']){
                isValidForm = false
                setIsEmptyLink(true)
                errors['link'] = 'Ce champ est requis.'
                setTimeout(() => {
                    setIsEmptyLink(false)
                }, 5000);
            }
            if(!formFields['is_active']){
                isValidForm = false
                setIsEmptyIsActive(true)
                errors['is_active'] = 'Ce champ est requis.'
                setTimeout(() => {
                    setIsEmptyIsActive(false)
                }, 5000);
            }
        }
        if(verb === 'edit'){
            if(!jeMinformeItem['label']){
                isValidForm = false
                setIsEmptyLabel(true)
                errors['label'] = 'Ce champ est requis.'
                setTimeout(() => {
                    setIsEmptyLabel(false)
                }, 5000);
            }
            if(!jeMinformeItem['link']){
                isValidForm = false
                setIsEmptyLink(true)
                errors['link'] = 'Ce champ est requis.'
                setTimeout(() => {
                    setIsEmptyLink(false)
                }, 5000);
            }
            if(!jeMinformeItem['is_active']){
                isValidForm = false
                setIsEmptyIsActive(true)
                errors['is_active'] = 'Ce champ est requis.'
                setTimeout(() => {
                    setIsEmptyIsActive(false)
                }, 5000);
            }
        }

        setFormErrors(errors)
        return isValidForm
    }

    const onSubmitJeMinforme = (e) => {
        e.preventDefault()

        if(onValidateForm('create')){
            formFields['user_id'] = userID
            setAddJeMinformeInProgress(true)
            console.log(formFields)
            axios.post(`${ApiBaseUrl}je_minformes`, formFields, requestConfig)
                .then(response => {
                    setAddJeMinformeInProgress(false)
                    document.getElementById("add-jeminforme-form").reset();
                    if(response.data.success){
                        onGetJeMinformeData()
                        // window.$('.add-jeminforme-modal').modal('hide')
                        $('.close').click()
                        toast.success('Item ajouté avec succès.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        setFormFields({})
                        setFormErrors({})
                    }else{
                        setIsSubmitErrorMessage(true)
                        setTimeout(() => {
                            setIsSubmitErrorMessage(false)
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.log(error)
                    setAddJeMinformeInProgress(false)
                    setIsSubmitErrorMessage(true)
                    setTimeout(() => {
                        setIsSubmitErrorMessage(false)
                    }, 5000);
                    if (error.message === 'Request failed with status code 401') {
                        setIsSessionExpiration(true)
                    }
                })
        }
    }

    const addedByFormatter = (cell, row) => {
        return(
            <span className="added-by"> { row?.user?.email } </span>
        )
    }

    const statutFormatter = (cell, row) => {
        if(cell === true || cell === 1){
            return <span className="badge badge-success">Visible</span>
        }

        return <span className="badge badge-danger">Non Visible</span>
    }

    const linkFormatter = (cell) => {
        return(
            <a href={cell} className="btn btn-primary link-jm-btn" target="_blank" rel="noopener noreferrer"> Lien </a>
        )
    }

    const editHandleChange = (e) => {
        const newItem = {...jeMinformeItem, [e.target.name]: e.target.value};
        setJeMinformeItem(newItem)
        console.log(newItem)
    }

    const onSubmitEditJeMinforme = (e) => {
        e.preventDefault()
        console.log(jeMinformeItem)
        if(onValidateForm('edit')){
            formFields['user_id'] = userID
            setAddJeMinformeInProgress(true)
            axios.put(`${ApiBaseUrl}je_minformes/${jeMinformeItem.id}`, jeMinformeItem, requestConfig)
                .then(response => {
                    setAddJeMinformeInProgress(false)
                    if(response.data.success){
                        onGetJeMinformeData()
                        // window.$('.add-jeminforme-modal').modal('hide')
                        $('.close').click()
                        toast.success('Item modifié avec succès.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        setFormFields({})
                        setFormErrors({})
                    }else{
                        setIsSubmitErrorMessage(true)
                        setTimeout(() => {
                            setIsSubmitErrorMessage(false)
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.log(error)
                    setAddJeMinformeInProgress(false)
                    setIsSubmitErrorMessage(true)
                    setTimeout(() => {
                        setIsSubmitErrorMessage(false)
                    }, 5000);
                    if (error.message === 'Request failed with status code 401') {
                        setIsSessionExpiration(true)
                    }
                })
        }
    }

    // if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
    //     if(this.state.token === null || this.state.token === undefined){
    //       return <MonEspaceEmptyUserTokenView type="null" />
    //     }else{
    //       return <MonEspaceEmptyUserTokenView type="session" />
    //     }
    // }

    // {
    //     token === null || token === undefined || isSessionExpiration === true 
    //         token === null || token === undefined ?
    //             <MonEspaceEmptyUserTokenView type="null" /> :
    //             <MonEspaceEmptyUserTokenView type="session" />
    // }

    return(
        <div className="component-orientation-remplissage-manager">
            {isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard />

            <section id="cd_container" className="s-h-primo-1">
                <div className="bordure-bas">
                    <div className="row p-block-20 headering-container">
                    <div className="col-md-12 d-flex justify-content-center">
                        <div className="logo-soushaulle">
                        <p className="mb-0 bold color-white mb-3"> Je m'informe manager </p>
                        </div>
                    </div>
                    </div>
                    <div className="ligne mt-3 mb-5"></div>
                </div>

                <div className="row orm-row">
                    <div className="nav-container nav-dashboard-color">
                        <NavVertical />
                    </div>
                    <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-10 col-md-12 content-position-after-header px-md-3 px-0">
                        <div className="row page-content-row">
                            <div className="col-md-4 offset-md-4 orm-add-btn-col">
                            <button className="orm-add-btn btn btn-primary" data-toggle="modal" data-target=".add-jeminforme-modal">
                                <FeatherIcon icon="plus" className="add-plus-icon" />
                            </button>
                            </div>
                            <div className="col-md-12 orm-table-col">
                            <div className="orm-table-container">
                                <BootstrapTable data={jeMinformeData}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={0} options={options}
                                multiColumnSearch={ true }
                                pagination
                                search={true} version='4'>
                                    <TableHeaderColumn dataField='id' isKey={true} hidden={true}>ID</TableHeaderColumn>
                                    <TableHeaderColumn width="300" dataField='label'>Titre du bouton</TableHeaderColumn>
                                    <TableHeaderColumn width="150" dataFormat={(cell) => linkFormatter(cell)} dataField='link'>Liens</TableHeaderColumn>       
                                    <TableHeaderColumn width="150" dataFormat={(cell, row) => statutFormatter(cell, row)} dataField='is_active'>Statuts</TableHeaderColumn>
                                    <TableHeaderColumn width="200" dataFormat={(cell, row) => addedByFormatter(cell, row)} dataField='user_id'>Ajouté par</TableHeaderColumn>
                                    
                                    <TableHeaderColumn width="100" dataField='id' dataFormat={(cell, row) => onActionFormatter(cell, row)}>Action</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* Add Je m'informe Modal */}
            <div className="modal fade add-jeminforme-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addEventModalTitle">Ajouter élément Je m'informe</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <form id="add-jeminforme-form">
                            <div className="row add-event-row">
                                <div className="col-md-6 add-event-col">
                            <label htmlFor="label">Titre du bouton</label>
                            <input onChange={handleChange} type="text" placeholder="Titre du bouton" className="form-control add-event-form-control" name="label" id="label" />
                            {isEmptyLabel &&
                                <div className="alert alert-danger oe-alert-danger"> { formErrors.label } </div>
                            }
                            </div>
                                <div className="col-md-6 add-event-col">
                            <label htmlFor="link">Lien du bouton</label>
                            <input onChange={handleChange} type="text" placeholder="Lien du bouton" className="form-control add-event-form-control" name="link" id="link" />
                            </div>
                                <div className="col-md-6 add-event-col">
                            <label htmlFor="is_active">Statut</label>
                            <select onChange={handleChange} className="form-control add-event-form-control" name="is_active" id="is_active">
                                <option>--Choisir un statut--</option>
                                <option value={1}> Publier en ligne </option>
                                <option value={0}> Annuler </option>
                            </select>
                            </div>

                                {
                                    isSubmitErrorMessage &&
                                    <div className="col-md-12 request-error-message-col">
                                        <div className="alert alert-danger request-error-message-container">
                                            Oups! Une erreur est survenue lors de l'ajout. Veuillez réessayer plus tard.
                                        </div>
                                    </div>
                                }

                                <div className="col-md-4 offset-md-4 add-event-submit-col">
                                    {!addJeMinformeInProgress ?
                                        <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => onSubmitJeMinforme(e) }>
                                            <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                                            <span className="submit-add-label">Ajouter</span>
                                        </button> :
                                        <button className="btn btn-primary add-event-submit-btn">
                                            <i className="fas fa-spin fa-spinner"></i>&nbsp;
                                            <span className="submit-add-label">En cours</span>
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>

                    </div>
                </div>
            </div>

            {/* Edit Je m'informe Modal */}
            <div className="modal fade edit-jeminforme-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addEventModalTitle">Modification</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <form>
                            <div className="row add-event-row">
                                <div className="col-md-6 add-event-col">
                            <label htmlFor="label">Titre du bouton</label>
                            <input value={jeMinformeItem?.label} onChange={editHandleChange} type="text" placeholder="Titre du bouton" className="form-control add-event-form-control" name="label" id="label" />
                            {isEmptyLabel &&
                                <div className="alert alert-danger oe-alert-danger"> { formErrors.label } </div>
                            }
                            </div>
                                <div className="col-md-6 add-event-col">
                            <label htmlFor="link">Lien du bouton</label>
                            <input value={jeMinformeItem?.link} onChange={editHandleChange} type="text" placeholder="Lien du bouton" className="form-control add-event-form-control" name="link" id="link" />
                            </div>
                                <div className="col-md-6 add-event-col">
                            <label htmlFor="is_active">Statut</label>
                            <select onChange={editHandleChange} className="form-control add-event-form-control" name="is_active" id="is_active">
                                <option>--Choisir un statut--</option>
                                {jeMinformeItem?.is_active ? <option value={1} selected> Publier en ligne </option> : <option value={1}> Publier en ligne </option>} 
                                {!jeMinformeItem?.is_active ? <option value={0} selected> Annuler </option> : <option value={0}> Annuler </option>}
                                
                            </select>
                            </div>

                                {
                                    isSubmitErrorMessage &&
                                    <div className="col-md-12 request-error-message-col">
                                        <div className="alert alert-danger request-error-message-container">
                                            Oups! Une erreur est survenue lors de l'ajout. Veuillez réessayer plus tard.
                                        </div>
                                    </div>
                                }

                                <div className="col-md-4 offset-md-4 add-event-submit-col">
                                    {!addJeMinformeInProgress ?
                                        <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => onSubmitEditJeMinforme(e) }>
                                            <FeatherIcon icon="edit" className="submit-add-icon" />&nbsp;
                                            <span className="submit-add-label">Modifier</span>
                                        </button> :
                                        <button className="btn btn-primary add-event-submit-btn">
                                            <i className="fas fa-spin fa-spinner"></i>&nbsp;
                                            <span className="submit-add-label">En cours</span>
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>

                    </div>
                </div>
            </div>
            
            
        </div>
    )
}

export default JeMinformeManager;