import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FeatherIcon from 'feather-icons-react';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import axios from 'axios';
import NewsSkeleton from './Skeletons/NewsSkeleton';
import BackendBaseurl from '../../http/BackendBaseUrl';

const LandingPageNewsFeedV2 = () => {

    const [isSectionLoading, setIsSectionLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [news, setNews] = useState([]);

    const newsFeedSlideSettings = {
        initialSlide: 0,

        dots: true,
        // infinite: true,
        slidesToShow: news?.length === 1 ? 1 : news?.length === 2 ? 2 : news?.length > 2 && 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    const onGetLastNews = () => {
        axios.get(`${ApiBaseUrl}frontend_last_news_scrappeds`)
            .then(response => {
                setIsSectionLoading(false);
                if(response.data.success){
                    setNews(response.data.data);
                    if(response.data.count === 0){
                        setIsEmpty(true)
                    }
                }else{
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setIsSectionLoading(false)
            })
    }

    useEffect(() => {
        onGetLastNews();
    }, []);

    const openLinkInNewTab = (url) => {
        window.open(url, '_blank');
    }
    
    return (
        <section id="news-feed-section">
            <div className="news-feed-container container-fluid">
                <div className="news-feed-body-content">
                    <h2 className="custom-section-title">Actualités</h2>
                    <div className="news-feed-slide-container">
                        {
                            isSectionLoading ?
                                <NewsSkeleton /> :
                                !isEmpty ?
                                <Slider {...newsFeedSlideSettings}>
                                    {
                                        news?.map((item, index) => {
                                            return(
                                                <div className="news-feed-slide-item-container linkable" key={'news-' + index} onClick={() => openLinkInNewTab(item.news_url)}>
                                                    <div className="bews-feed-slide-item-card">
                                                        <div className="news-feed-item-image-container">
                                                            <img 
                                                                src={ (item?.is_manuel_adding === false || item?.is_manuel_adding === 0) ? (
                                                                        item?.news_image_url
                                                                    ) : (
                                                                        BackendBaseurl + item?.news_image_url
                                                                    )
                                                                } 
                                                                alt="Plateforme Mon Espace SN" 
                                                                className="news-feed-item-image" 
                                                            />
                                                        </div>
                                                        <div className="news-feed-item-title-container">
                                                            <h1 className="news-title"> { item?.news_title } </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </Slider> :
                                <div className="container empty-card-container">
                                    <div className="row empty-alert-row">
                                        <div className="col-md-2 empty-icon-col">
                                            <FeatherIcon
                                                icon="alert-circle"
                                                className="m-b-3 empty-alert-icon rotateIn"
                                            />
                                        </div>
                                        <div className="col-md-10 empty-textual-col">
                                            <p><strong>Aucun article n'est disponible pour le moment.</strong></p>
                                        </div>
                                    </div>
                                </div>
                        }
                        
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LandingPageNewsFeedV2