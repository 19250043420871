import React, {Component} from 'react';
import './Configurations.scss';
import axios from 'axios';
// import { NavLink } from 'react-router-dom';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { toast, ToastContainer } from 'react-toastify';
import {BootstrapTable, TableHeaderColumn, ClearSearchButton, SearchField, ButtonGroup} from 'react-bootstrap-table';
import $ from 'jquery'
import formatDate from '../../utils'
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import BackendBaseurl from '../../http/BackendBaseUrl';

export default class Configurations extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          userAvatar: window.sessionStorage.getItem('user_avatar'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userRole: window.sessionStorage.getItem('user_role'),
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: true,
          isSessionExpiration: false,
          fields: {},
          errors: {},
          configs: [],
          addConfigInProgress: false,
          is_empty_evalue: false,
          is_empty_is_active: false,
          is_empty_key: false,
          is_empty_title: false,
          is_empty_value: false,
          isSuccessAdd: false,
          isErrorAdd: false,
          configItem: [],
          file: {}

        };

        this.addConfigHandleChange = this.addConfigHandleChange.bind(this)
        this.onSubmitAddConfig = this.onSubmitAddConfig.bind(this)
        this.handleChangeUpdateConfig = this.handleChangeUpdateConfig.bind(this)
        this.onSubmitUpdateConfig = this.onSubmitUpdateConfig.bind(this)
    }

    componentDidMount(){
      this.onGetConfigurations()
    }

    onGetConfigurations = () => {
      axios.get(`${ApiBaseUrl}configurations`)
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          console.log('first')

          if(response.data.success){
            // console.log(response.data.data)
            this.setState({
              configs: response.data.data
            })
          }else{
            console.log(response)
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false
          })
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    createCustomSearchField = (props) => {
      return (
        <SearchField
            className='search-field'
            placeholder='Rechercher'/>
      );
    }

    createCustomButtonGroup = props => {
      return (
          <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
              {props.exportCSVBtn}
          </ButtonGroup>
      );
    }

    addConfigHandleChange = (e) => {
      let fields = this.state.fields

      if(e.target.name === 'image'){
        fields[e.target.name] = e.target.files[0]
      }else{
        fields[e.target.name] = e.target.value
      }

      console.log(fields)

      this.setState({
        fields
      })
    }

    onValidateAddConfigForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['is_active'] || fields['is_active'] === '--Choisir un statut--'){
        isValidForm = false
        this.setState({
          is_empty_is_active: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_active: false
          })
        }, 5000);
        
        errors['is_active'] = 'Ce champ est obligatoire'
      }

      if(!fields['key']){
        isValidForm = false
        this.setState({
          is_empty_key: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_key: false
          })
        }, 5000);
        
        errors['key'] = 'Ce champ est obligatoire'
      }

      if(!fields['title']){
        isValidForm = false
        this.setState({
          is_empty_title: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_title: false
          })
        }, 5000);
        
        errors['title'] = 'Ce champ est obligatoire'
      }

      if(!fields['value']){
        isValidForm = false
        this.setState({
          is_empty_value: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_evalue: false
          })
        }, 5000);
        
        errors['value'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitAddConfig = (e) => {
      e.preventDefault()
      if(this.onValidateAddConfigForm()){
        var fd = new FormData()
        var data = this.state.fields
        // return console.log(data)
        Object.entries(data).forEach(([key, value]) =>  fd.append(key, value));

        this.setState({
          addConfigInProgress: true
        })
        
        var config = this.state.requestConfig

        axios.post(`${ApiBaseUrl}configurations`, fd, config)
          .then(response => {
            this.setState({
              addConfigInProgress: false
            })

            if(response.data.success){
              this.onGetConfigurations()
              toast.success('Item ajouté avec succès.')
              // window.$('.add-event-modal').modal('hide')
              $('.close').click()
              this.setState({
                isSuccessAdd: true
              })
              setTimeout(() => {
                this.setState({
                  isSuccessAdd: false
                })
              }, 5000);
            }else{
              console.log(response)
              toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.')
              this.setState({
                isErrorAdd: true
              })
              setTimeout(() => {
                this.setState({
                  isErrorAdd: false
                })
              }, 5000);
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.')
            this.setState({
              addConfigInProgress: false,
              isErrorAdd: true
            })
            setTimeout(() => {
              this.setState({
                isErrorAdd: false
              })
            }, 5000);

            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }

          })

      }
    }

    imageFormatter = (cell, row) => {
      if(cell !== null){
        return <img src={`${BackendBaseurl}${row.image_path}`} className="config-item-image" width="90" height="90" alt={"Configuration" + row.id} />
      }else{
        return <span className="badge badge-warning oe-badge-warning"> aucune image associée </span>
      }
    }

    urlFormatter = (cell, row) => {
      if(cell !== null){
        return <a href={ row.link } className="config-item-link" target="_blank" rel="noopener noreferrer"> Visiter </a>
      }else{
        return <span className="badge badge-warning oe-badge-warning"> aucune URL associée </span>
      }
    }

    statusFormatter = (cell, row) => {
      if(cell === true){
        return <span className="badge badge-success oe-badge-success"> activé </span>
      }else{
        return <span className="badge badge-danger oe-badge-danger"> désactivé </span>
      }
    }

    dateFormatter = (cell, row) => {
      if(cell !== null){
        return (
          <div className='date-heure-container'>
            <div className='date-container'> { formatDate(row.date) } </div>
            {row?.hour &&
              <div className='heure-container'> { ' à ' + row?.hour } </div>
            }
          </div>
        )
      }else{
        return <span className="badge badge-warning oe-badge-warning"> aucune date </span>
      }
    }

    onActionFormatter = (cell, row) => {
      return(
        <div className="oe-actions-btn-container">
          <button className="action-btn oe-view-btn color-white" data-toggle="modal" data-target=".show-config-modal" onClick={() => this.onGetConfigItemInfos(row)}>
            <FeatherIcon icon="eye" className="eye-icon" />
          </button>
          <button className="action-btn oe-edit-btn color-white" data-toggle="modal" data-target=".edit-config-modal" onClick={() => this.onGetConfigItemInfos(row)}>
            <FeatherIcon icon="edit" className="edit-icon" />
          </button>
        </div>
      )
    }

    onGetConfigItemInfos = (item) => {
      this.setState({
        configItem: item
      })
    }

    handleChangeUpdateConfig = (e) => {
      e.persist();
      let file = this.state.file
  
      if(e.target.name === 'image'){
        file[e.target.name] = e.target.files[0]
      }else{
        this.setState(prevState => ({
          configItem: { ...prevState.configItem,  [e.target.name]: e.target.value }
        }))
      }
      
      this.setState({
        file
      })
      console.log(this.state.configItem)
    }  

    onValidateUpdateConfigForm = () => {
      let fields = this.state.configItem
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['is_active'] || fields['is_active'] === '--Choisir un statut--'){
        isValidForm = false
        this.setState({
          is_empty_is_active: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_active: false
          })
        }, 5000);
        
        errors['is_active'] = 'Ce champ est obligatoire'
      }

      if(!fields['key']){
        isValidForm = false
        this.setState({
          is_empty_key: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_key: false
          })
        }, 5000);
        
        errors['key'] = 'Ce champ est obligatoire'
      }

      if(!fields['title']){
        isValidForm = false
        this.setState({
          is_empty_title: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_title: false
          })
        }, 5000);
        
        errors['title'] = 'Ce champ est obligatoire'
      }

      if(!fields['value']){
        isValidForm = false
        this.setState({
          is_empty_value: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_evalue: false
          })
        }, 5000);
        
        errors['value'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitUpdateConfig = (e) => {
      e.preventDefault()
      if(this.onValidateUpdateConfigForm()){
        var fd = new FormData()
        var data = this.state.configItem
        // console.log(data)
        Object.entries(data).forEach(([key, value]) =>  fd.append(key, value));

        if(this.state.file.length !== 0){
          fd.append('image', this.state.file.image)
        }
        this.setState({
          addConfigInProgress: true
        })
        
        // var config = this.state.requestConfig
        console.log(Object.fromEntries(fd))
        axios.post(`${ApiBaseUrl}update-configuration/${data.id}`, fd)
          .then(response => {
            this.setState({
              addConfigInProgress: false
            })

            if(response.data.success){
              this.onGetConfigurations()
              toast.success('Item modifié avec succès.')
              $('.close').click()
              $('#edit_image').val('');
              this.setState({
                configItem: []
              })
              this.setState({
                isSuccessAdd: true
              })
              setTimeout(() => {
                this.setState({
                  isSuccessAdd: false
                })
              }, 5000);
            }else{
              console.log(response)
              toast.error('Oupsss! Une erreur est survenue lors de la modification.')
              this.setState({
                isErrorAdd: true
              })
              setTimeout(() => {
                this.setState({
                  isErrorAdd: false
                })
              }, 5000);
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Oupsss! Une erreur est survenue lors de la modification.')
            this.setState({
              addConfigInProgress: false,
              isErrorAdd: true
            })
            setTimeout(() => {
              this.setState({
                isErrorAdd: false
              })
            }, 5000);

            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }

          })

      }
    }

    valueFormatter = (cell, row) => {
      return (
        <div className='value-container'>
          { cell }
        </div>
      )
    }


    render() {
      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {

        const options = {
          clearSearch: true,
          clearSearchBtn: this.createCustomClearButton,
          noDataText: 'Aucune donnée disponible',
          searchField: this.createCustomSearchField,
          btnGroup: this.createCustomButtonGroup
        };

        return(
          <div className="component-configurations">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <section id="cd_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="mb-0 bold color-white mb-3"> Configurations </p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

              </div>

              <div className="row orm-row">
                <div className="nav-container nav-dashboard-color">
                    <NavVertical />
                </div>
                
                <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 orm-table-col">
                  <div className="row content-row">
                    <div className="col-md-4 offset-md-4 orm-add-btn-col">
                      <button className="orm-add-btn btn btn-primary" data-toggle="modal" data-target=".add-config-modal">
                        <FeatherIcon icon="plus" className="add-plus-icon" />
                      </button>
                    </div>
                    <div className="col-md-12 table-col">
                      <div className="orm-table-container">
                        <BootstrapTable data={this.state.configs}
                          striped={true} hover={true} condensed={true}
                          multiColumnSort={0} options={options}
                          multiColumnSearch={ true }
                          pagination
                          search={true} version='4'>
                            <TableHeaderColumn dataField='id' isKey={true} hidden={true}>Numero</TableHeaderColumn>
                            <TableHeaderColumn width="270" dataField='key'>Clé</TableHeaderColumn>
                            <TableHeaderColumn width="150" dataField='title'>Titre</TableHeaderColumn>
                            <TableHeaderColumn width="200" dataField='image_path' dataFormat={this.imageFormatter.bind(this)}>Image</TableHeaderColumn>
                            <TableHeaderColumn width="300" dataField='value' dataFormat={this.valueFormatter.bind(this)}>Valeur</TableHeaderColumn>
                            <TableHeaderColumn width="200" dataField='link' dataFormat={this.urlFormatter.bind(this)}>URL</TableHeaderColumn>
                            <TableHeaderColumn width="120" dataField='is_active' dataFormat={this.statusFormatter.bind(this)} >Visibilité</TableHeaderColumn>
                            <TableHeaderColumn width="200" dataField='date' dataFormat={this.dateFormatter.bind(this)}>Date et Heure</TableHeaderColumn>
                            <TableHeaderColumn width="150" dataField='id' dataFormat={this.onActionFormatter.bind(this)}>Action</TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

              {/* <AdminRightSidebar isAdmin={true} /> */}

            </section>


            {/* Add Modal */}
            <div className="modal fade add-config-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Ajouter un item de configuration</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="key">Clé Unique <span className="required-icon">*</span> </label>
                          {/* <input onChange={this.addConfigHandleChange} type="text" placeholder="Clé" className="form-control add-event-form-control" name="key" id="key" /> */}
                          <select onChange={this.addConfigHandleChange} className="form-control add-event-form-control" name="key" id="key">
                            <option selected disabled value="">--Choisir un statut--</option>
                            <option value="INFOS-UTILE"> INFOS-UTILE </option>
                            <option value="ZOOM-INFOS"> ZOOM-INFOS </option>
                            <option value="YOUTUBE-LIVE">YOUTUBE-LIVE</option>
                            <option value="WEBINAIRE">WEBINAIRE</option>
                          </select>
                          {this.state.is_empty_key &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.key } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="title">Titre <span className="required-icon">*</span> </label>
                          <input onChange={this.addConfigHandleChange} type="text" placeholder="Titre" className="form-control add-event-form-control" name="title" id="title" />
                        
                          {this.state.is_empty_title &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.title } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="link">Lien</label>
                          <input onChange={this.addConfigHandleChange} type="text" placeholder="URL" className="form-control add-event-form-control" name="link" id="link" />
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="is_active">Statut <span className="required-icon">*</span> </label>
                          <select onChange={this.addConfigHandleChange} className="form-control add-event-form-control" name="is_active" id="is_active">
                            <option>--Choisir un statut--</option>
                            <option value={1}> Publier en ligne </option>
                            <option value={0}> Annuler </option>
                          </select>

                          {this.state.is_empty_is_active &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_active } </div>
                          }
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="date">Date</label>
                          <input onChange={this.addConfigHandleChange} type="date" placeholder="Date" className="form-control add-event-form-control" name="date" id="date" />
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="event_start_time">Heure</label>
                          <input onChange={this.addConfigHandleChange} type="time" placeholder="Heure de début de la rencontre" className="form-control add-event-form-control" name="hour" id="event_start_time" />
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="image">Image</label>
                          <input onChange={this.addConfigHandleChange} type="file" className="form-control add-event-form-control" name="image" id="image" />
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="value">Valeur (Contenu textuel) <span className="required-icon">*</span> </label>
                          <textarea onChange={this.addConfigHandleChange} placeholder="Valeur" className="form-control" name="value" id="value" />
                        
                          {this.state.is_empty_value &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.value } </div>
                          }
                        </div>
                        

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.addConfigInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitAddConfig(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Ajouter</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            {/* Show Modal */}
            <div className="modal fade show-config-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="showConfModalTitle"> 
                      { `[${this.state.configItem.key}] - ${this.state.configItem.title}` } &nbs;&nbsp;
                      { this.state.configItem.is_active === true ?
                        <span className="badge badge-success show-badge-success">
                          actif
                        </span> :
                        <span className="badge badge-danger show-badge-danger">
                          désactivé
                        </span>
                      }
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="row showing-item-row">
                      {(this.state.configItem.image_path !== null) &&
                          <div className="col-md-12 showing-item-col">
                            <h3 className="showing-item-label"> Image </h3>
                            <div className="showing-item-content">
                              <img src={`${BackendBaseurl}${this.state.configItem.image_path}`} className="showing-image" alt={`ConfigurationItem${this.state.configItem.id}`} />
                            </div>
                          </div>
                      }
                      <div className="col-md-12 showing-item-col">
                        <h3 className="showing-item-label"> Valeur </h3>
                        <div className="showing-item-content">
                          { this.state.configItem.value }
                        </div>
                      </div>
                      {/* <div className="col-md-12 showing-item-col">
                        <h3 className="showing-item-label"> Statut </h3>
                        <div className="showing-item-content">
                          { this.state.configItem.is_active === true ?
                            <span className="badge badge-success show-badge-success">
                              actif
                            </span> :
                            <span className="badge badge-danger show-badge-danger">
                              désactivé
                            </span>
                          }
                        </div>
                      </div> */}
                      {(this.state.configItem.date !== null && this.state.configItem.hour !== null) &&
                          <div className="col-md-12 showing-item-col">
                            <h3 className="showing-item-label"> Date - Heure </h3>
                            <div className="showing-item-content">
                              { 
                                this.state.configItem.date + ' à ' + this.state.configItem.hour
                              }
                            </div>
                          </div>
                      }
                      {(this.state.configItem.link !== null) &&
                          <div className="col-md-12 showing-item-col">
                            <h3 className="showing-item-label"> Lien </h3>
                            <div className="showing-item-content">
                              { this.state.configItem.link }
                            </div>
                          </div>
                      }
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* Edit Modal */}
            <div className="modal fade edit-config-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="updateConfModalTitle">Modifier</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_key">Clé Unique <span className="required-icon">*</span> </label>
                          {/* <input value={this.state.configItem.key} onChange={this.handleChangeUpdateConfig} type="text" placeholder="Clé" className="form-control add-event-form-control" name="key" id="edit_key" /> */}
                          <select value={this.state.configItem.key} onChange={this.handleChangeUpdateConfig} className="form-control add-event-form-control" name="key" id="key">
                            <option disabled value="">--Choisir un statut--</option>
                            <option value="INFOS-UTILE"> INFOS-UTILE </option>
                            <option value="ZOOM-INFOS"> ZOOM-INFOS </option>
                            <option value="YOUTUBE-LIVE">YOUTUBE-LIVE</option>
                            <option value="WEBINAIRE">WEBINAIRE</option>
                          </select>

                          {this.state.is_empty_key &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.key } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_title">Titre <span className="required-icon">*</span> </label>
                          <input value={this.state.configItem.title} onChange={this.handleChangeUpdateConfig} type="text" placeholder="Titre" className="form-control add-event-form-control" name="title" id="edit_title" />
                        
                          {this.state.is_empty_title &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.title } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_link">Lien</label>
                          <input value={this.state.configItem.link} onChange={this.handleChangeUpdateConfig} type="text" placeholder="URL" className="form-control add-event-form-control" name="link" id="edit_link" />
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_is_active">Statut <span className="required-icon">*</span> </label>
                          <select value={this.state.configItem.is_active} onChange={this.handleChangeUpdateConfig} className="form-control add-event-form-control" name="is_active" id="edit_is_active">
                            <option>--Choisir un statut--</option>
                            <option value={true} > Publier en ligne </option> 
                            <option value={false} > Annuler </option>
                          </select>

                          {this.state.is_empty_is_active &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_active } </div>
                          }
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_date">Date</label>
                          <input value={moment(this.state.configItem.date).format('YYYY-MM-DD')} onChange={this.handleChangeUpdateConfig} type="date" placeholder="Date" className="form-control add-event-form-control" name="date" id="edit_date" />
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_hour">Heure</label>
                          <input value={this.state.configItem.hour} onChange={this.handleChangeUpdateConfig} type="time" placeholder="Heure de début de la rencontre" className="form-control add-event-form-control" name="hour" id="edit_hour" />
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="edit_image">Image</label>
                          <input onChange={this.handleChangeUpdateConfig} type="file" className="form-control add-event-form-control" name="image" id="edit_image" />
                        </div>

                        <div className="col-md-12 add-event-col">
                          <label htmlFor="edit_value">Valeur (Contenu textuel) <span className="required-icon">*</span> </label>
                          <textarea value={this.state.configItem.value} onChange={this.handleChangeUpdateConfig} placeholder="Valeur" className="form-control" name="value" id="edit_value" />
                        
                          {this.state.is_empty_value &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.value } </div>
                          }
                        </div>
                        

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.addConfigInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitUpdateConfig(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>



            {this.state.isSuccessAdd || this.state.isErrorAdd ?
              <ToastContainer /> : ''
            }
          </div>
        );
      }
    }
}