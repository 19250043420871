import React, {Component} from 'react';
import './Page404.scss'
import { NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';

export default class Page404 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
      return(
        <div className="component-page404">
          <div id="notfound">
            <div className="notfound">
              <div className="notfound-404">
                <h1>Oops!</h1>
                <h2>404 - Page non trouvée</h2>
              </div>
              <NavLink to="/">
                <FeatherIcon icon="home" className="home-icon" />&nbsp;
                Retourner à la page d'accueil
              </NavLink>
            </div>
          </div>
        </div>
      );
    }
  }
