import React, {Component} from 'react';
import './EventSubscribersNotifications.scss';
import axios from 'axios';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
// import AdminRightSidebar from '../adminRightSidebar/AdminRightSidebar'
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn, ClearSearchButton, SearchField, ButtonGroup} from 'react-bootstrap-table';
// import formatDate from '../../utils'
import moment from 'moment'
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';


export default class EventSubscribersNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          userAvatar: window.sessionStorage.getItem('user_avatar'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userRole: window.sessionStorage.getItem('user_role'),
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: true,
          isSessionExpiration: false,
          notifications: [],
          notificationInfos: [],

        };
    }

    componentDidMount(){
      this.onGetNotifications()
    }

    onGetNotifications = () => {
      
      var config = this.state.requestConfig

      axios.get(`${ApiBaseUrl}subscribed_checkout_notifications`, config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          if(response.data.success){
            console.log(response.data.data)
            this.setState({
              notifications: response.data.data
            })
          }else{
            console.log(response)
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false
          })
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    createCustomSearchField = (props) => {
      return (
        <SearchField
            className='search-field'
            placeholder='Rechercher'/>
      );
    }

    createCustomButtonGroup = props => {
      return (
          <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
              {props.exportCSVBtn}
          </ButtonGroup>
      );
    }

    onActionFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button className="btn btn-primary action-btn edit-btn" data-toggle="modal" data-target=".showNotificationModal" onClick={() => this.onGetNotificationInfos(row)}>
            <FeatherIcon icon="eye" className="action-icon" />
          </button>
        </div>
      )
    }

    onGetNotificationInfos = (item) => {
      this.setState({
        notificationInfos: item
      })
    }

    createdAtFormatter = (cell) => {
      return moment(cell).format('DD-MM-YYYY');
    }

    dateSeanceFormatter = (cell, row) => {
      return(
        cell + ' de ' + row.event_start_time + ' à ' + row.event_end_time
      )
    }

    render() {
      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {

        const options = {
          clearSearch: true,
          clearSearchBtn: this.createCustomClearButton,
          noDataText: 'Aucune donnée disponible',
          searchField: this.createCustomSearchField,
          btnGroup: this.createCustomButtonGroup
        };

        return(
          <div className="component-tutoriel-video-manager">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <section id="cd_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="mb-0 bold color-white mb-3">Inscription aux séances - Notifications envoyées  </p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

              </div>

              <div className="row tvm-row">
                <div className="nav-container nav-dashboard-color">
                    <NavVertical />
                </div>
                <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-10 col-md-12 content-position-after-header px-md-3 px-0">
                  <div className="col-md-12 orm-table-col">
                    <div className="orm-table-container">
                      <BootstrapTable data={this.state.notifications}
                        striped={true} hover={true} condensed={true}
                        options={options}
                        multiColumnSearch={ true }
                        pagination
                        search={true} version='4'>
                          <TableHeaderColumn dataField='id' isKey={true} hidden={true}>Numero</TableHeaderColumn>
                          <TableHeaderColumn width="150"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} 
                            dataField='created_at' dataFormat={this.createdAtFormatter.bind(this)}>Date d'inscription</TableHeaderColumn>
                          <TableHeaderColumn width="200"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} 
                            dataField='subscriber_fullname'>Prénom et Nom de l'inscrit</TableHeaderColumn>
                          <TableHeaderColumn width="150" dataField='event_name' 
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Type de séance</TableHeaderColumn>
                          <TableHeaderColumn width="200" dataField='email_destinataire' 
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Email de l'inscrit</TableHeaderColumn>
                          <TableHeaderColumn width="200" dataFormat={this.dateSeanceFormatter.bind(this)} dataField='event_date'filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Date de la séance</TableHeaderColumn>
                          <TableHeaderColumn width="100" dataField='id' dataFormat={this.onActionFormatter.bind(this)}>Action</TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                </div>

              </div>
            </section>



            {/* View Notification Modal */}
            <div className="modal fade showNotificationModal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Notification envoyée le { `${moment(this.state.notificationInfos.created_at).format('DD-MM-YYYY')} à ${this.state.notificationInfos.subscriber_fullname}` }</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="row add-event-row">
                      <div className="col-md-12 show-notif-col">
                        <div className="show-notif-item-label-container">
                          <div className="show-notif-item-label">
                            <span className="labelyiste"> De : </span> &nbsp;
                            <span className="labeluiste-content"> {`Campus France Sénégal <${this.state.notificationInfos.email_expediteur}>`} </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 show-notif-col">
                        <div className="show-notif-item-label-container">
                          <div className="show-notif-item-label">
                            <span className="labelyiste"> À : </span> &nbsp;
                            <span className="labeluiste-content"> {`${this.state.notificationInfos.subscriber_fullname} <${this.state.notificationInfos.email_destinataire}>`} </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 show-notif-col">
                        <div className="show-notif-item-label-container">
                          <div className="show-notif-item-label">
                            <span className="labelyiste"> Objet : </span> &nbsp;
                            <span className="labeluiste-content"> {`${this.state.notificationInfos.objet_notification}`} </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 show-notif-contenu-container">
                        <div className="show-notif-item-label-container">
                          <div className="show-notif-item-contenu-body">
                            <div dangerouslySetInnerHTML={{ __html: this.state.notificationInfos.contenu_notification }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
}
