import React, { useState } from 'react';
import FooterLogo from '../../img/v2/footer-logo.png';
import FacebookIcon from '../../img/v2/icons/FacebookIcon.svg';
import TwitterIcon from '../../img/v2/icons/TwitterIcon.svg';
import YoutubeIcon from '../../img/v2/icons/YoutubeIcon.svg';
import InstagramIcon from '../../img/v2/icons/InstagramIcon.svg';
import LinkedinIcon from '../../img/v2/icons/LinkedinIcon.svg';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import axios from 'axios';
import toast from 'react-hot-toast';
import packageJson from '../../../package.json';

const FooterV2 = () => {
    const [inProgress, setInProgress] = useState(false);
    const [email, setEmail] = useState('');

    const onSubmitNewsletterSubscribe = (e) => {
        e.preventDefault();
        setInProgress(true);

        axios.post(ApiBaseUrl + 'newsletters', {
            user_email: email
        })
            .then(response => {
                setInProgress(false);
                if(response.data.success){
                    document.getElementById('v2-footer-newsletter-form').reset();
                    toast.success('Votre adresse email a été bien ajoutée à notre liste de diffusion.', {
                        duration: 5000,
                        position: 'top-center'
                    });
                }
                if(!response.data.success){
                    toast.error('Oupss! Une erreur est survenue. Veuillez réessayer plus tard.', {
                        duration: 5000,
                        position: 'top-center'
                    });
                }
            })
            .catch(error => {
                console.log(error);
                toast.error('Oupss! Une erreur est survenue. Veuillez réessayer plus tard.', {
                    duration: 5000,
                    position: 'top-center'
                });
            })
    }


    return (
        <footer id="v2-footer">
            <div className="v2-footer-container container">
                <div className="v2-footer-content-body">
                    <div className="v2-footer-org-infos-container">
                        <div className="footer-logo-container">
                            <img src={ FooterLogo } alt="Campus France Sénégal" className="footer-logo-image" />
                        </div>
                        <div className="footer-org-newsletter-container">
                            <p>Pour ne rien manquer de notre actualité, inscrivez-vous à notre newsletter</p>
                            <div className="footer-org-newsletter-card">
                                <form id="v2-footer-newsletter-form" onSubmit={(e) => onSubmitNewsletterSubscribe(e)}>
                                    <div className="v2-footer-form-group-container">
                                        <div className="v2-footer-form-group form-group">
                                            <input onChange={(e) => setEmail(e.target.value)} required autoComplete="off" name="email" type="text" placeholder="Adresse email" className="form-control v2-footer-newsletter-form-control" />
                                        </div>
                                        <div className="v2-footer-newsletter-submit-btn-container">
                                            <button type="submit" className="btn btn-danger v2-footer-newsletter-submit-btn">
                                                {inProgress ? <i className="fa fa-spin fa-spinner"></i> : 'Souscrire'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="v2-footer-org-contact-container">
                        <div className="v2-footer-contacts-container">
                            <h4 className="v2-footer-contact-title">Contact</h4>
                            <span className="v2-footer-contact-label">
                                km1 Avenue Cheikh Anta Diop, Dakar<br />
                                (+221) 33 963 25 85
                            </span>
                        </div>
                        <div className="v2-footer-social-medias-container">
                            <h4 className="v2-footer-social-medias-title">Retrouvez-nous sur les réseaux sociaux</h4>
                            <div className="v2-social-medias-card">
                                <div className="v2-social-media-item-container">
                                    <a rel="noopener noreferrer" href="https://www.facebook.com/Senegal.CampusFrance" className="v2-social-media-item-link" target="_blank">
                                        <img src={ FacebookIcon } alt="Campus France SN sur Facebook" className="v2-social-media-icon" />
                                    </a>
                                </div>
                                <div className="v2-social-media-item-container">
                                    <a rel="noopener noreferrer" href="https://twitter.com/CFsenegal" className="v2-social-media-item-link" target="_blank">
                                        <img src={ TwitterIcon } alt="Campus France SN sur Twitter" className="v2-social-media-icon" />
                                    </a>
                                </div>
                                <div className="v2-social-media-item-container">
                                    <a rel="noopener noreferrer" href="https://www.youtube.com/channel/UCVN7JrWCgFeYFTNnrcmgYiQ" className="v2-social-media-item-link" target="_blank">
                                        <img src={ YoutubeIcon } alt="Campus France SN sur Youtube" className="v2-social-media-icon" />
                                    </a>
                                </div>
                                <div className="v2-social-media-item-container">
                                    <a rel="noopener noreferrer" href="https://www.instagram.com/campusfrancesenegal/?hl=fr" className="v2-social-media-item-link" target="_blank">
                                        <img src={ InstagramIcon } alt="Campus France SN sur Instagram" className="v2-social-media-icon" />
                                    </a>
                                </div>
                                <div className="v2-social-media-item-container">
                                    <a rel="noopener noreferrer" href="https://www.linkedin.com/company/campus-france-sénégal/" className="v2-social-media-item-link" target="_blank">
                                        <img src={ LinkedinIcon } alt="Campus France SN sur LinkedIn" className="v2-social-media-icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="v2-footer-copyrights-container">
                    <span>© Campus France 2022</span>&nbsp;{`v${packageJson?.version}`}
                </div>
            </div>
        </footer>
    );
}

export default FooterV2