import React, { useEffect, useState } from 'react';
import './LandingPageV2.css';
import LandingPageHeaderV2 from './LandingPageHeaderV2';
import LandingPageCarouselV2 from './LandingPageCarouselV2';
import LandingPageServicesV2 from './LandingPageServicesV2';
import LandingPageNewsFeedV2 from './LandingPageNewsFeedV2';
import FooterV2 from '../footerV2/FooterV2';
import LandingPageEventV2 from './LandingPageEventV2';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import BackendBaseurl from '../../http/BackendBaseUrl';
import axios from 'axios';
// eslint-disable-next-line
import $ from 'jquery';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const LandingPageV2 = (props) => {
    // eslint-disable-next-line
    const [isPageLoading, setIsPageLoading] = useState(true);
    // eslint-disable-next-line
    const [configs, setConfigs] = useState([]);
    const [infosUtile, setInfosUtile] = useState([]);
    // eslint-disable-next-line
    const [zoomInfos, setZoomInfos] = useState([]);
    const [youtubeInfos, setYoutubeInfos] = useState([]);
    const [onShowChat, setOnShowChat] = useState(false);
    const [onShowOrientation, setOnShowOrientation] = useState(false);

    const params = useParams();
  
    const onGetConfigurations = () => {
        axios.get(`${ApiBaseUrl}active-configurations`)
            .then(response => {
                setIsPageLoading(false)
    
                if(response.data.success){
                    setConfigs(response.data.data)
                    response.data.data.forEach(element => {
                        if(element.key === "INFOS-UTILE"){
                            setInfosUtile(element)
                            if(props?.location?.state?.flag !== 'orange' && props?.location?.state?.flag !== 'vert' && !params?.hasOwnProperty('event_type')){
                                window.$('#monespacesn-modal-alerty').modal();
                            }
                        }
                    });
                    response.data.data.forEach(element => {
                        if(element.key === "YOUTUBE-LIVE"){
                            setYoutubeInfos(element)
                        }
                    });
                    response.data.data.forEach(element => {
                        if(element.key === "ZOOM-INFOS"){
                            setZoomInfos(element)
                        }
                    });
                }else{
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                setIsPageLoading(false)
            })
    }

    useEffect(() => {
        onGetConfigurations();
        if(props?.location?.state?.flag === 'orange'){
            setOnShowChat(true);
        }
        if(props?.location?.state?.flag === 'vert'){
            setOnShowOrientation(true);
        }
        // eslint-disable-next-line
    }, [])
    
    // eslint-disable-next-line
    // const openLinkInNewTab = (url) => {
    //     window.open(url, '_blank');
    // }

    return (
        <div className="v2 landing-page-component">
            <LandingPageHeaderV2 />
            
            <LandingPageCarouselV2 />

            <LandingPageServicesV2 
                onShowChat={onShowChat}
                onShowOrientation={onShowOrientation}
                params={params}
            />

            <LandingPageNewsFeedV2 />
            
            <LandingPageEventV2 />

            <FooterV2 />

            

            <div className="modal fade" id="monespacesn-modal-alerty" tabIndex="-1" role="dialog" aria-labelledby="monespacesn-modal-alertyLabel" aria-hidden="true">
                <div className="modal-dialog oe-modal-dialog" role="document">
                    <div className="modal-content oe-modal-content">
                        <div className="modal-header oe-modal-header">
                        <h5 className="modal-title oe-modal-title" id="monespacesn-modal-alertyLabel">Information Utile</h5>
                        <button type="button" className="close eo-close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body oe-modal-body">
                            <div className="row infos-row">
                                <h2 className="infos-title"> { infosUtile.title } </h2>
                                {(infosUtile.image_path !== null) &&
                                    <div className="col-md-12 showing-item-col">
                                        <div className="showing-item-content">
                                            {youtubeInfos !== null && youtubeInfos.link !== null ?
                                            <img 
                                                src={`${BackendBaseurl}${infosUtile.image_path}`} 
                                                className="showing-image linkable" alt={`ConfigurationItem${infosUtile.id}`} 
                                                onClick={() => this.openLinkInNewTab(youtubeInfos.link)}
                                            /> :
                                            <img src={`${BackendBaseurl}${infosUtile.image_path}`} className="showing-image" alt={`ConfigurationItem${infosUtile.id}`} />
                                            }
                                            
                                        </div>
                                    </div>
                                }
                                <div className="col-md-12 infos-value">
                                    { infosUtile.value }
                                </div>
                                {infosUtile.link !== null &&
                                    <div className="col-md-6 offset-md-3 infos-btn-action-container">
                                        <a target="_blank" href={`${infosUtile.link}`} className="btn btn-primary infos-btn" rel="noopener noreferrer"> Plus de détails </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPageV2;