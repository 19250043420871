import React, { useEffect, useState } from 'react';
import './AutoEvaluationQuizz.css';
import FooterV2 from '../../footerV2/FooterV2';
import LandingPageHeaderV2 from '../../landingPageV2/LandingPageHeaderV2';
import { Link, useHistory } from 'react-router-dom';
import LightVertFonce from '../../../img/v2/LightVertFonce.png';
import LightVertClair from '../../../img/v2/LightVertClair.png';
import LightOrange from '../../../img/v2/LightOrange.png';
import LightRouge from '../../../img/v2/LightRouge.png';
import $ from 'jquery';
import ApiBaseUrl from '../../../http/ApiBaseUrl';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
    CountrySelector,
    StateSelector
} from 'volkeno-react-countries-states-cities-in-french'
import 'volkeno-react-countries-states-cities-in-french/dist/index.css';
import PhoneInput from 'react-phone-input-2'
import { ValidateEmail } from '../../../modules/ValidateEmail/ValidateEmail';

const AutoEvaluationQuizz = (props) => {
    const history = useHistory();
    const today = new Date();
    const [score, setScore] = useState(0);
    const [summing, setSumming] = useState([]);
    const [counter, setCounter] = useState(30);
    const [sendQuizzerInProgress, setSendQuizzerInProgress] = useState(false);
    const [profilEtudiant, setProfilEtudiant] = useState({});
    const [profilEtudiantErrors, setProfilEtudiantErrors] = useState({});
    const [is_empty_nom, set_is_empty_nom] = useState(false);
    const [is_empty_prenom, set_is_empty_prenom] = useState(false);
    const [is_empty_sexe, set_is_empty_sexe] = useState(false);
    const [is_empty_date_naissance, set_is_empty_date_naissance] = useState(false);
    const [is_empty_nationalite, set_is_empty_nationalite] = useState(false);
    const [is_empty_autre_nationalite, set_is_empty_autre_nationalite] = useState(false);
    const [is_empty_etude_travail, set_is_empty_etude_travail] = useState(false);
    const [is_empty_numero_cni, set_is_empty_numero_cni] = useState(false);
    const [is_empty_email, set_is_empty_email] = useState(false);
    const [is_empty_telephone, set_is_empty_telephone] = useState(false);
    const [is_empty_pays_residence, set_is_empty_pays_residence] = useState(false);
    const [is_empty_region_residence, set_is_empty_region_residence] = useState(false);
    const [is_empty_ville_residence, set_is_empty_ville_residence] = useState(false);
    const [is_empty_ville_id, set_is_empty_ville_id] = useState(false);
    const [onShowAutreNationaliteInput, setOnShowAutreNationaliteInput] = useState(false);
    const [savedProfilEtudiant, setSavedProfilEtudiant] = useState([]);
    const [savedProfilEtudiantResponses, setSavedProfilEtudiantResponses] = useState({});
    const [sendResponseInProgress, setSendResponseInProgress] = useState(false);
    const [showNextButton, setShowNextButton] = useState(true);
    const [showMentionStep, setShowMentionStep] = useState(false);
    const [showAutreBacBtInput, setShowAutreBacBtInput] = useState(false);
    const [showDureeTravailOuState, setShowDureeTravailOuState] = useState(false);
    const [showNiveauEtudeActuelle, setShowNiveauEtudeActuelle] = useState(false);
    const [isEleveTerminal, setIsEleveTerminal] = useState(false);
    const [isBachelier, setIsBachelier] = useState(false);
    const [showNombreAcceptation, setShowNombreAcceptation] = useState(false);
    const [showInformationSurFormation, setShowInformationSurFormation] = useState(false);
    const [showEtudeComment, setShowEtudeComment] = useState(false);
    const [showAchevementCycleActuel, setShowAchevementCycleActuel] = useState(false);
    const [showFormationSuivieDepuis, setShowFormationSuivieDepuis] = useState(false);
    const [showPourquoiReorientation, setShowPourquoiReorientation] = useState(false);
    const [showProjetRecherche, setShowProjetRecherche] = useState(false);
    const [showProjetApresFormation, setShowProjetApresFormation] = useState(false);
    const [showCoutFormationOption1, setShowCoutFormationOption1] = useState(false);
    const [showCoutFormationOption2, setShowCoutFormationOption2] = useState(false);
    const [quizzIsDone, setQuizzIsDone] = useState(false);
    const [showAutreTypeDiplomeObtenu, setShowAutreTypeDiplomeObtenu] = useState(false);
    const [onDisablePoursuivreCheckbox, setOnDisablePoursuivreCheckbox] = useState(false);
    const [onDisableCommentCheckbox, setOnDisableCommentCheckbox] = useState(false);
    const [is_syntax_telephone_error, set_is_syntax_telephone_error] = useState(false);
    const [showPremiereAnneeMoyenne, setShowPremiereAnneeMoyenne] = useState(false);
    const [showDeuxiemeAnneeMoyenne, setShowDeuxiemeAnneeMoyenne] = useState(false);
    const [showTroisiemeAnneeMoyenne, setShowTroisiemeAnneeMoyenne] = useState(false);
    const [showQuatriemeAnneeMoyenne, setShowQuatriemeAnneeMoyenne] = useState(false);
    const [showCinquiemeAnneeMoyenne, setShowCinquiemeAnneeMoyenne] = useState(false);
    const [showDoctorantAnneeMoyenne, setShowDoctorantAnneeMoyenne] = useState(false);
    // eslint-disable-next-line
    const [isProfessionnel, setIsProfessionnel] = useState(false);
    const [isProfessionnelBachelier, setIsProfessionnelBachelier] = useState(false);
    const [isProfessionnelBTS, setIsProfessionnelBTS] = useState(false);
    const [isProfessionnelLicence, setIsProfessionnelLicence] = useState(false);
    const [isProfessionnelMaster, setIsProfessionnelMaster] = useState(false);
    const [isProfessionnelDoctorant, setIsProfessionnelDoctorant] = useState(false);
    const [premierMoyenneAnnuelle, setPremierMoyenneAnnuelle] = useState(0.00);
    const [deuxiemeMoyenneAnnuelle, setDeuxiemeMoyenneAnnuelle] = useState(0.00);
    const [troisiemeMoyenneAnnuelle, setTroisiemeMoyenneAnnuelle] = useState(0.00);
    const [isProfessionnelAvecAutreDiplome, setIsProfessionnelAvecAutreDiplome] = useState(false);
    // eslint-disable-next-line
    const [autoEvalSavedReturnData, setAutoEvalSavedReturnData] = useState();

    const [isNotEligible, setIsNotEligible] = useState(false);

    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [openHorsSenegalRegionInput, setOpenHorsSenegalRegionInput] = useState('');
    const [openSenegalRegionInput, setOpenSenegalRegionInput] = useState('');
    const [villes, setVilles] = useState([]);
    const [is_invalid_email_syntax, set_is_invalid_email_syntax] = useState(false);

    const consent = props?.location?.state?.consent;
    // eslint-disable-next-line
    const btn = document.getElementsByClassName('carousel-control-next')[0];

    var part1Title = document.getElementById('part_1_title');
    var part2Title = document.getElementById('part_2_title');
    var part3Title = document.getElementById('part_3_title');
    var part4Title = document.getElementById('part_4_title');
    var idSlide = document.getElementById('identity_slide');
    var noteSlide = document.getElementById('gestion_note');
    var lastSlide = document.getElementById('last_slide');
    var drapVert = document.getElementById('drap_vert_slide');
    var drapOrange = document.getElementById('drap_orange_slide');
    var drapRouge = document.getElementById('drap_rouge_slide');
    var notEligibleStopping = document.getElementById('not-eligible-stopping');
    const nextBtn = document.querySelectorAll('.carousel-control-next');

    var checkbox_1 = document.getElementById('checkbox_1');
    var checkbox_1_bis = document.getElementById('checkbox_1_bis');
    var checkbox_2 = document.getElementById('checkbox_2');
    var checkbox_2_bis = document.getElementById('checkbox_2_bis');

    const [departements, setDepartements] = useState([]);
    const [showDepartementSelect, setShowDepartementSelect] = useState(false);
    const [choosedVilleId, setChoosedVilleId] = useState();
    const [antenne, setAntenne] = useState('');
    const [showResult, setShowResult] = useState(false);
    const [phone, setPhone] = useState('');

    const onSelectCountry = (val) => {
        console.log('val', val)
        setCountry(val);
        setProfilEtudiant({
            ...profilEtudiant, 'pays_residence': val?.name
        })
        if(val?.name !== 'Sénégal'){
            setOpenHorsSenegalRegionInput(true);
            setOpenSenegalRegionInput(false);

            setIsNotEligible(true);
            $(".carousel-control-next").click();
            console.log('el', isNotEligible)
            // return false;
            toast.error("Désolé! Vous n'êtes pas éligible pour continuer l'auto évaluation", {
                duration: 10000,
                position: 'top-center'
            });
            return history.push('/accueil-auto-evaluation')
        }else{
            setOpenHorsSenegalRegionInput(false);
            setOpenSenegalRegionInput(true);
            
        }
        console.log('profilEtudiant', profilEtudiant)
    }
    const onSelectRegion = (val) => {
        
        if(val){
            console.log('val', val)
            setRegion(val);
            let ville_id = null;

            if(val.country_name === "Senegal"){
                console.log('country test')
                let departements = [];
                const region = val.name;
                let data = RegionsAndDepartements?.filter((dep) => (dep?.region === region || ((dep?.region + ' Region') === region )));
                data.forEach(item => {
                    departements.push(item?.departement)
                });
                setDepartements(departements);
                setAntenne(data[0]?.antenne)
    
                let antenneByDepartement = villes?.filter((ville) => ville?.name === data[0]?.antenne)[0];
                setChoosedVilleId(antenneByDepartement?.id);
                ville_id = antenneByDepartement?.id
    
                setShowDepartementSelect(true);
            }else{
                setShowDepartementSelect(false)
            }
    
            setProfilEtudiant({
                ...profilEtudiant, 'region_residence': val?.label, 'ville_id': ville_id
            })
        }

        console.log('profilEtudiant', profilEtudiant)
    }

    const onGetVilles = () => {
        axios.get(`${ApiBaseUrl}villes`)
          .then(response => {
    
            if (response.data.success) {
              setVilles(response.data.data)
            } else {
              console.log(response)
            }
          })
          .catch(error => {
            console.log(error);
          })
    }

    // useEffect(() => {
    //     if(consent !== 'ok'){
    //         toast.error("Vous devez au préalable accepter les conditions d'utilisations pour pouvoir effectuer l'auto-évaluation", {
    //             duration: 8000,
    //             position: 'top-center'
    //         });
    //         setTimeout(() => {
    //             history.push("/");
    //         }, 8000);
    //     }
    // }, [consent, history]);

    useEffect(() => {
        onGetVilles();
    }, []);

    useEffect(() => {
        nextBtn.forEach(btn => {
            btn.addEventListener('click', function handleClick(event) {
                if((lastSlide?.classList?.contains('active') && lastSlide?.classList?.contains('active') !== undefined)){
                    setCounter(30);
                }else if((noteSlide?.classList?.contains('active') && noteSlide?.classList?.contains('active') !== undefined)){
                    setCounter(90); // 90
                }else if((checkbox_1?.classList?.contains('active') && checkbox_1?.classList?.contains('active') !== undefined)){
                    setCounter(60); // 90
                }else if((checkbox_1_bis?.classList?.contains('active') && checkbox_1_bis?.classList?.contains('active') !== undefined)){
                    setCounter(60); // 90
                }else if((checkbox_2?.classList?.contains('active') && checkbox_2?.classList?.contains('active') !== undefined)){
                    setCounter(60); // 90
                }else if((checkbox_2_bis?.classList?.contains('active') && checkbox_2_bis?.classList?.contains('active') !== undefined)){
                    setCounter(60); // 90
                }
                else{
                    setCounter(30); // 30
                }
            });
        });
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        if(((!idSlide?.classList?.contains('active') && idSlide?.classList?.contains('active') !== undefined) &&
            (!part1Title?.classList?.contains('active') && part1Title?.classList?.contains('active') !== undefined) &&
            (!part2Title?.classList?.contains('active') && part2Title?.classList?.contains('active') !== undefined) &&
            (!part3Title?.classList?.contains('active') && part3Title?.classList?.contains('active') !== undefined) &&
            (!part4Title?.classList?.contains('active') && part4Title?.classList?.contains('active') !== undefined)
            ) &&
            ((!drapVert?.classList?.contains('active') && drapVert?.classList?.contains('active') !== undefined) ||
            (!drapOrange?.classList?.contains('active') && drapOrange?.classList?.contains('active') !== undefined) ||
            (!drapRouge?.classList?.contains('active') && drapRouge?.classList?.contains('active') !== undefined))
            ){
            if(timer === false){
                if((notEligibleStopping?.classList?.contains('active') && notEligibleStopping?.classList?.contains('active') !== undefined)){
                    setCounter(30) // 30
                }else{
                    setCounter(30); // 30
                    $('.carousel-control-next').click();
                }
                
            }
            
        }else{
            setCounter(30); // 30
        }
        return () => clearInterval(timer);
        // eslint-disable-next-line
    }, [counter]);

    const QuizzHandleChange = (e) => {
        if(e.target.name === 'nationalite' && e.target.value === 'Autre'){
            setOnShowAutreNationaliteInput(true);
        }
        if(e.target.name === 'nationalite' && e.target.value === 'Sénégalaise'){
            setOnShowAutreNationaliteInput(false);
        }
       
        setProfilEtudiant({
            ...profilEtudiant, [e.target.name]: e.target.value === 'Oui' ? 1 : e.target.value === 'Non' ? 0 : e.target.name === 'email' ? e.target.value.toLowerCase() : e.target.value
        });

        console.log('profil', profilEtudiant);
    }

    const onValidateProfilEtudiant = () => {
        var isValidForm = true;

        if(!profilEtudiant['pays_residence']){
            isValidForm = false;
            console.log('pays résidence')
            set_is_empty_pays_residence(true);
            setTimeout(() => {
                set_is_empty_pays_residence(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, pays_residence: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['region_residence']){
            isValidForm = false;
            console.log('région residence')
            set_is_empty_region_residence(true);
            setTimeout(() => {
                set_is_empty_region_residence(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, region_residence: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['ville_residence']){
            isValidForm = false;
            console.log('ville residence')
            set_is_empty_ville_residence(true);
            setTimeout(() => {
                set_is_empty_ville_residence(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, ville_residence: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['ville_id'] && (openSenegalRegionInput && !openHorsSenegalRegionInput)){
            console.log('ville')
            isValidForm = false;
            set_is_empty_ville_id(true);
            setTimeout(() => {
                set_is_empty_ville_id(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, ville_id: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['nom']){
            isValidForm = false;
            console.log('nom')
            set_is_empty_nom(true);
            setTimeout(() => {
                set_is_empty_nom(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, nom: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['prenom']){
            isValidForm = false;
            console.log('prenom')
            set_is_empty_prenom(true);
            setTimeout(() => {
                set_is_empty_prenom(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, prenom: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['sexe']){
            isValidForm = false;
            console.log('sexe')
            set_is_empty_sexe(true);
            setTimeout(() => {
                set_is_empty_sexe(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, sexe: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['date_naissance']){
            isValidForm = false;
            console.log('date naissance')
            set_is_empty_date_naissance(true);
            setTimeout(() => {
                set_is_empty_date_naissance(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, date_naissance: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['nationalite']){
            isValidForm = false;
            console.log('nationalite')
            set_is_empty_nationalite(true);
            setTimeout(() => {
                set_is_empty_nationalite(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, nationalite: 'Ce Champ est requis.'
            })
        }
        if((profilEtudiant['etude_ou_travail'] !== "Oui" ) && (profilEtudiant['etude_ou_travail'] !== "Non") && (profilEtudiant['etude_ou_travail'] !== 1 && (profilEtudiant['etude_ou_travail'] !== 0)) ){
            isValidForm = false;
            console.log('etude ou travail')
            set_is_empty_etude_travail(true);
            setTimeout(() => {
                set_is_empty_etude_travail(false); 
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, etude_ou_travail: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['numero_cni']){
            isValidForm = false;
            console.log('CNI')
            set_is_empty_numero_cni(true);
            setTimeout(() => {
                set_is_empty_numero_cni(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, numero_cni: 'Ce Champ est requis.'
            })
        }
        if(!profilEtudiant['email']){
            console.log('email')
            isValidForm = false;
            set_is_empty_email(true);
            setTimeout(() => {
                set_is_empty_email(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, email: 'Ce Champ est requis.'
            })
        }
        if(profilEtudiant['email'] && !ValidateEmail(profilEtudiant['email'])){
            isValidForm = false;
            set_is_invalid_email_syntax(true);
            setTimeout(() => {
                set_is_invalid_email_syntax(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, invalid_email_syntax: "Votre adresse email n'est pas valide."
            })
        }
        if(!phone){
            console.log('telephone')
            isValidForm = false;
            set_is_empty_telephone(true);
            setTimeout(() => {
                set_is_empty_telephone(false);
            }, 5000);
            setProfilEtudiantErrors({
                ...profilEtudiantErrors, telephone: 'Ce Champ est requis.'
            })
        }
        
        if(typeof profilEtudiant['nationalite'] !== undefined && profilEtudiant['nationalite'] === 'Autre'){
            if(!profilEtudiant['autre_nationalite']){
                console.log('autre nationalite')
                isValidForm = false;
                set_is_empty_autre_nationalite(true);
                setTimeout(() => {
                    set_is_empty_autre_nationalite(false);
                }, 5000);
                setProfilEtudiantErrors({
                    ...profilEtudiantErrors, autre_nationalite: 'Ce Champ est requis.'
                })
            }
        }

        return isValidForm;
    }

    const onSendQuizzerInfos = (e) => {
        e.preventDefault();

        if(onValidateProfilEtudiant()){
            // if(profilEtudiant?.nationalite === 'Autre' && profilEtudiant?.autre_nationalite?.length > 0){
            //     if(profilEtudiant?.etude_ou_travail === 0 ||
            //         profilEtudiant?.etude_ou_travail === "Non" || 
            //         profilEtudiant?.etude_ou_travail === false
            //     ){
            //         setIsNotEligible(true);
            //         $(".carousel-control-next").click();
            //         console.log('el', isNotEligible)
            //         // return false;
            //         toast.error("Désolé! Vous n'êtes pas éligible pour continuer l'auto évaluation", {
            //             duration: 10000,
            //             position: 'top-center'
            //         });
            //         return history.push('/accueil-auto-evaluation')
            //     }
            // }
            
            setSendQuizzerInProgress(true);
            profilEtudiant['telephone'] = phone;
            axios.post(`${ApiBaseUrl}profil_etudiants`, profilEtudiant)
                .then(response => {
                    if(response.data.success && !response.data.has_profile){
                        setSendQuizzerInProgress(false);
                        setSavedProfilEtudiant(response.data.data);
                        setCounter(20);
                        $(".nexty").click();
                    }
                    if(!response.data.success && response.data.has_profile){
                        toast.error(response.data.message, {
                            duration: 10000,
                            position: 'top-center',
                            style: {
                                border: '1px solid #C10046',
                                padding: '16px',
                                color: '#ffffff',
                                background: '#C10046',
                                fontSize: '17px',
                                textAlign: 'justify'
                            }
                        });
                        setTimeout(() => {
                            history.push('/');
                        }, 7000);
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    setSendQuizzerInProgress(false);
                    toast.error('Oups! Une erreur est survenue lors de la sauvegarde vos informations personnelles.', {
                        duration: 5000,
                        position: 'top-center'
                    });
                })
        }else{
            console.log('pas valide')
        }
    }

    const onHandleChangeProfilEtudiantResponse = (e) => {
        // pourquoi_poursuivre_etude_en_france
        if(e.target.name === "pourquoi_poursuivre_etude_en_france"){
            if(savedProfilEtudiantResponses.hasOwnProperty('pourquoi_poursuivre_etude_en_france')){
                let poursuivreArray = savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france;
                if(poursuivreArray?.includes(e.target.value)){
                    let index = poursuivreArray?.indexOf(e.target.value);
                    poursuivreArray.splice(index, 1);
                }else{
                    poursuivreArray.push(e.target.value)
                };
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    [e.target.name]: poursuivreArray
                });
            }else{
                let poursuivreArray = [];
                poursuivreArray.push(e.target.value);
                savedProfilEtudiantResponses['pourquoi_poursuivre_etude_en_france'] = poursuivreArray
            }
            let arrayPoursuivre = savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france;
            console.log('array poursuivre', arrayPoursuivre)
            if(arrayPoursuivre?.length === 2){
                setOnDisablePoursuivreCheckbox(true);
            }
            if(arrayPoursuivre?.length < 2){
                setOnDisablePoursuivreCheckbox(false);
            }
        }else if(e.target.name === "idee_etude_fr_comment_quel_moyen"){
            if(savedProfilEtudiantResponses?.hasOwnProperty('idee_etude_fr_comment_quel_moyen')){
                let commentArray = savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen;
                if(commentArray?.includes(e.target.value)){
                    let index = commentArray?.indexOf(e.target.value);
                    commentArray.splice(index, 1);
                }else{
                    commentArray.push(e.target.value)
                };
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    [e.target.name]: commentArray
                });
            }else{
                let commentArray = [];
                commentArray.push(e.target.value);
                // setSavedProfilEtudiantResponses({
                //     ...savedProfilEtudiantResponses, 
                //     [e.target.name]: commentArray
                // });
                savedProfilEtudiantResponses['idee_etude_fr_comment_quel_moyen'] = commentArray
            }
            let arrayComment = savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen;
            if(arrayComment?.length === 2){
                setOnDisableCommentCheckbox(true);
            }
            if(arrayComment?.length < 2){
                setOnDisableCommentCheckbox(false);
            }
        }else{
            // setSavedProfilEtudiantResponses({
            //     ...savedProfilEtudiantResponses, 
            //     [e.target.name]: e.target.value
            // });
            const inputName = e.target.name;
            savedProfilEtudiantResponses[inputName] = e.target.value;
        }

        console.log('details data', savedProfilEtudiantResponses);
        

        if(e.target.name === 'bac_bt_obtenu' && e.target.value === "Je ne l’ai pas obtenu et ne le prévoit plus de le repasser"){
            setShowNextButton(false);
            toast.error("Désolé! Vous n'êtes pas éligible à continuer l'auto-évaluation en nous basant sur les informations que vous avez fournies.", {
                duration: 12000,
                position: 'top-center'
            });
            setTimeout(() => {
                history.push('/')
            }, 10000);
        }
        if(e.target.name === 'bac_bt_obtenu' && 
            (e.target.value === "Je viens de l'obtenir" ||
            e.target.value === "Je l’ai obtenu l’année dernière" ||
            e.target.value === "Je l’ai obtenu il y a plus d’un an")
        ){
            setShowMentionStep(true);
        }
        if(e.target.name === "troisieme_annee_encours"){
            const checkedStatus = document.getElementById('troisieme_annee_encours');
            if (checkedStatus.checked) {
                document.getElementById("troisieme_moyenne_semestre_1").disabled = true;
                document.getElementById("troisieme_moyenne_semestre_2").disabled = true;
            } else {
                document.getElementById("troisieme_moyenne_semestre_1").disabled = false;
                document.getElementById("troisieme_moyenne_semestre_2").disabled = false;
            }
        }
        if(e.target.name === "premiere_annee_encours"){
            const checkedStatus = document.getElementById('premiere_annee_encours');
            if (checkedStatus.checked) {
                document.getElementById("premier_moyenne_semestre_1").disabled = true;
                document.getElementById("premier_moyenne_semestre_2").disabled = true;
            } else {
                document.getElementById("premier_moyenne_semestre_1").disabled = false;
                document.getElementById("premier_moyenne_semestre_2").disabled = false;
            }
        }
        if(e.target.name === "deuxieme_annee_encours"){
            const checkedStatus = document.getElementById('deuxieme_annee_encours');
            if (checkedStatus.checked) {
                document.getElementById("deuxieme_moyenne_semestre_1").disabled = true;
                document.getElementById("deuxieme_moyenne_semestre_2").disabled = true;
            } else {
                document.getElementById("deuxieme_moyenne_semestre_1").disabled = false;
                document.getElementById("deuxieme_moyenne_semestre_2").disabled = false;
            }
        }
        if(e.target.name === "mention_bac_bt"){
            if(e.target.value === "Passable"){
                setScore(score + 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_mention_bac_bt: '5'
                });
                setSumming(state => [...state, 5]);
            }
            if(e.target.value === "Assez Bien"){
                setScore(score + 7);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_mention_bac_bt: '7'
                });
                setSumming(state => [...state, 7]);
            }
            if(e.target.value === "Bien"){
                setScore(score + 9);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_mention_bac_bt: '9'
                });
                setSumming(state => [...state, 9]);
            }
            if(e.target.value === "Très Bien"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_mention_bac_bt: '10'
                });
                setSumming(state => [...state, 10]);
            }
        }
        if(e.target.name === "nombre_passage_examen_bac_bt"){
            if(e.target.value === "1x"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_passage_examen_bac_bt: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "2x"){
                setScore(score + 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_passage_examen_bac_bt: '5'
                });
                setSumming(state => [...state, 5]);
            }
            if(e.target.value === "+4x"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_passage_examen_bac_bt: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === 'serie_bac_bt' && e.target.value === "Autre (à préciser)"){
            setShowAutreBacBtInput(true);
        }
        if(e.target.name === 'serie_bac_bt' && e.target.value !== "Autre (à préciser)"){
            setShowAutreBacBtInput(false);
        }
        if(e.target.name === "situation_actuelle" && 
            (e.target.value === "Etudiant en stage ou en activité professionnelle" ||
             e.target.value === "Professionnel")
        ){
            setShowDureeTravailOuState(true);
        }
        if(e.target.name === "situation_actuelle" && (
            e.target.value === "Professionnel"
        )){
            setIsProfessionnel(true);
        }
        if(e.target.name === "situation_actuelle" && 
            (e.target.value !== "Etudiant en stage ou en activité professionnelle" &&
             e.target.value !== "Professionnel")
        ){
            setShowDureeTravailOuState(false);
        }
        if(e.target.name === "situation_actuelle" && (
            e.target.value !== "Professionnel"
        )){
            setIsProfessionnel(false);
        }
        if(e.target.name === "situation_actuelle"){
            if(e.target.value === "Etudiant"){
                setShowNiveauEtudeActuelle(true);
            }
            if(e.target.value === "Etudiant en stage ou en activité professionnelle"){
                setShowNiveauEtudeActuelle(true);
            }
        }
        if(e.target.name === "situation_actuelle" && (
            e.target.value === "Professionnel"
        )){
            setIsProfessionnel(false);
        }

        // Moyenne An calculate
        if(e.target.name === "premier_moyenne_semestre_1"){
            if(savedProfilEtudiantResponses?.premier_moyenne_semestre_2){
                if(e.target.value.length === 0){
                    let semestre2 = parseFloat(savedProfilEtudiantResponses?.premier_moyenne_semestre_1);
                    let moyenneAnnuelle = parseFloat(semestre2).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle);
                }
                if(e.target.value.length !== 0){
                    let semestre1 = parseFloat(e.target.value);
                    let semestre2 = parseFloat(savedProfilEtudiantResponses?.premier_moyenne_semestre_1);
                    let moyenneAnnuelle = (parseFloat((semestre1 + semestre2) / 2)).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle);
                }
            }
            if(!savedProfilEtudiantResponses?.premier_moyenne_semestre_2){
                if(e.target.value.length === 0){
                    let semestre1 = parseFloat(0);
                    let moyenneAnnuelle1 = parseFloat(semestre1).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle1);
                }
                if(e.target.value.length !== 0){
                    let semestre1 = parseFloat(e.target.value);
                    let moyenneAnnuelle1 = parseFloat(semestre1).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle1);
                }
            }
        }
        if(e.target.name === "premier_moyenne_semestre_2"){
            if(savedProfilEtudiantResponses?.premier_moyenne_semestre_1){
                if(e.target.value.length === 0){
                    let semestre1 = parseFloat(savedProfilEtudiantResponses?.premier_moyenne_semestre_1);
                    let moyenneAnnuelle = parseFloat(semestre1).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle);
                }
                if(e.target.value.length !== 0){
                    let semestre1 = parseFloat(savedProfilEtudiantResponses?.premier_moyenne_semestre_1);
                    let semestre2 = parseFloat(e.target.value);
                    let moyenneAnnuelle = (parseFloat((semestre1 + semestre2) / 2)).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle);
                }
            }
            if(!savedProfilEtudiantResponses?.premier_moyenne_semestre_1){
                if(e.target.value.length === 0){
                    let semestre2 = parseFloat(0);
                    let moyenneAnnuelle2 = parseFloat(semestre2).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle2);
                }
                if(e.target.value.length !== 0){
                    let semestre2 = parseFloat(e.target.value);
                    let moyenneAnnuelle2 = parseFloat(semestre2).toFixed(2);
                    setPremierMoyenneAnnuelle(moyenneAnnuelle2);
                }
            }
        }

        if(e.target.name === "deuxieme_moyenne_semestre_1"){
            if(savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_2){
                if(e.target.value.length === 0){
                    let d_semestre2 = parseFloat(savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_1);
                    let d_moyenneAnnuelle = parseFloat(d_semestre2).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle);
                }
                if(e.target.value.length !== 0){
                    let d_semestre1 = parseFloat(e.target.value);
                    let d_semestre2 = parseFloat(savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_1);
                    let d_moyenneAnnuelle = (parseFloat((d_semestre1 + d_semestre2) / 2)).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle);
                }
            }
            if(!savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_2){
                if(e.target.value.length === 0){
                    let d_semestre1 = parseFloat(0);
                    let d_moyenneAnnuelle1 = parseFloat(d_semestre1).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle1);
                }
                if(e.target.value.length !== 0){
                    let d_semestre1 = parseFloat(e.target.value);
                    let d_moyenneAnnuelle1 = parseFloat(d_semestre1).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle1);
                }
            }
        }
        if(e.target.name === "deuxieme_moyenne_semestre_2"){
            if(savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_1){
                if(e.target.value.length === 0){
                    let d_semestre1 = parseFloat(savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_1);
                    let d_moyenneAnnuelle = parseFloat(d_semestre1).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle);
                }
                if(e.target.value.length !== 0){
                    let d_semestre1 = parseFloat(savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_1);
                    let d_semestre2 = parseFloat(e.target.value);
                    let d_moyenneAnnuelle = (parseFloat((d_semestre1 + d_semestre2) / 2)).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle);
                }
            }
            if(!savedProfilEtudiantResponses?.deuxieme_moyenne_semestre_1){
                if(e.target.value.length === 0){
                    let d_semestre2 = parseFloat(0);
                    let d_moyenneAnnuelle2 = parseFloat(d_semestre2).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle2);
                }
                if(e.target.value.length !== 0){
                    let d_semestre2 = parseFloat(e.target.value);
                    let d_moyenneAnnuelle2 = parseFloat(d_semestre2).toFixed(2);
                    setDeuxiemeMoyenneAnnuelle(d_moyenneAnnuelle2);
                }
            }
        }

        if(e.target.name === "troisieme_moyenne_semestre_1"){
            if(savedProfilEtudiantResponses?.troisieme_moyenne_semestre_2){
                if(e.target.value.length === 0){
                    let t_semestre2 = parseFloat(savedProfilEtudiantResponses?.troisieme_moyenne_semestre_1);
                    let t_moyenneAnnuelle = parseFloat(t_semestre2).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle);
                }
                if(e.target.value.length !== 0){
                    let t_semestre1 = parseFloat(e.target.value);
                    let t_semestre2 = parseFloat(savedProfilEtudiantResponses?.troisieme_moyenne_semestre_1);
                    let t_moyenneAnnuelle = (parseFloat((t_semestre1 + t_semestre2) / 2)).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle);
                }
            }
            if(!savedProfilEtudiantResponses?.troisieme_moyenne_semestre_2){
                if(e.target.value.length === 0){
                    let t_semestre1 = parseFloat(0);
                    let t_moyenneAnnuelle1 = parseFloat(t_semestre1).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle1);
                }
                if(e.target.value.length !== 0){
                    let t_semestre1 = parseFloat(e.target.value);
                    let t_moyenneAnnuelle1 = parseFloat(t_semestre1).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle1);
                }
            }
        }
        if(e.target.name === "troisieme_moyenne_semestre_2"){
            if(savedProfilEtudiantResponses?.troisieme_moyenne_semestre_1){
                if(e.target.value.length === 0){
                    let t_semestre1 = parseFloat(savedProfilEtudiantResponses?.troisieme_moyenne_semestre_1);
                    let t_moyenneAnnuelle = parseFloat(t_semestre1).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle);
                }
                if(e.target.value.length !== 0){
                    let t_semestre1 = parseFloat(savedProfilEtudiantResponses?.troisieme_moyenne_semestre_1);
                    let t_semestre2 = parseFloat(e.target.value);
                    let t_moyenneAnnuelle = (parseFloat((t_semestre1 + t_semestre2) / 2)).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle);
                }
            }
            if(!savedProfilEtudiantResponses?.troisieme_moyenne_semestre_1){
                if(e.target.value.length === 0){
                    let t_semestre2 = parseFloat(0);
                    let t_moyenneAnnuelle2 = parseFloat(t_semestre2).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle2);
                }
                if(e.target.value.length !== 0){
                    let t_semestre2 = parseFloat(e.target.value);
                    let t_moyenneAnnuelle2 = parseFloat(t_semestre2).toFixed(2);
                    setTroisiemeMoyenneAnnuelle(t_moyenneAnnuelle2);
                }
            }
        }
        
        // Moyenne showing
        if(e.target.name === 'niveau_etude' && 
            (savedProfilEtudiantResponses?.situation_actuelle === 'Etudiant' ||
            savedProfilEtudiantResponses?.situation_actuelle === 'Etudiant en stage ou en activité professionnelle')
        ){
            if(e.target.value === '1ére Année'){
                setShowPremiereAnneeMoyenne(true);
            }
            if(e.target.value !== '1ére Année'){
                setShowPremiereAnneeMoyenne(false);
            }
            if(e.target.value === '2éme Année'){
                setShowDeuxiemeAnneeMoyenne(true);
            }
            if(e.target.value !== '2éme Année'){
                setShowDeuxiemeAnneeMoyenne(false);
            }
            if(e.target.value === '3éme Année'){
                setShowTroisiemeAnneeMoyenne(true);
            }
            if(e.target.value !== '3éme Année'){
                setShowTroisiemeAnneeMoyenne(false);
            }
            if(e.target.value === '4éme Année'){
                setShowQuatriemeAnneeMoyenne(true);
            }
            if(e.target.value !== '4éme Année'){
                setShowQuatriemeAnneeMoyenne(false);
            }
            if(e.target.value === '5éme Année'){
                setShowCinquiemeAnneeMoyenne(true);
            }
            if(e.target.value !== '5éme Année'){
                setShowCinquiemeAnneeMoyenne(false);
            }
            if(e.target.value === 'Doctorant'){
                setShowDoctorantAnneeMoyenne(true);
            }
            if(e.target.value !== 'Doctorant'){
                setShowDoctorantAnneeMoyenne(false);
            }
        }
        if(e.target.name === 'type_dernier_diplome_obtenu' && (
            savedProfilEtudiantResponses?.situation_actuelle === 'Professionnel' ||
            savedProfilEtudiantResponses?.situation_actuelle === 'Etudiant en stage ou en activité professionnelle'
        )){
            console.log('type_dernier_diplome_obtenu', 'professionnel')
            if(e.target.value === 'BAC /BT'){
                setIsProfessionnelBachelier(true);
            }
            if(e.target.value !== 'BAC /BT'){
                setIsProfessionnelBachelier(false);
            } 
            if(e.target.value === 'BTS/DUT/DISEP'){
                setIsProfessionnelBTS(true);
            }
            if(e.target.value !== 'BTS/DUT/DISEP'){
                setIsProfessionnelBTS(false);
            } 
            if(e.target.value === 'Licence'){
                setIsProfessionnelLicence(true);
            }
            if(e.target.value !== 'Licence'){
                setIsProfessionnelLicence(false);
            }
            if(e.target.value === 'Master'){
                setIsProfessionnelMaster(true);
            }
            if(e.target.value !== 'Master'){
                console.log('type_dernier_diplome_obtenu', 'master')
                setIsProfessionnelMaster(false);
            }
            if(e.target.value === 'Doctorat'){
                console.log('type_dernier_diplome_obtenu', 'doctorant')
                setIsProfessionnelDoctorant(true);
            }
            if(e.target.value !== 'Doctorat'){
                setIsProfessionnelDoctorant(false);
            }
            if(savedProfilEtudiantResponses?.type_dernier_diplome_obtenu !== "Autre (à préciser)"){
                setIsProfessionnelAvecAutreDiplome(false);
            }
        }
        if(e.target.name === "autre_type_dernier_diplome_obtenu"){
            if(savedProfilEtudiantResponses?.type_dernier_diplome_obtenu === "Autre (à préciser)"){
                setIsProfessionnelAvecAutreDiplome(true);
            }
            if(savedProfilEtudiantResponses?.type_dernier_diplome_obtenu !== "Autre (à préciser)"){
                setIsProfessionnelAvecAutreDiplome(false);
            }
        }
        if(e.target.name === "autre_type_dernier_diplome_obtenu"){
            if(savedProfilEtudiantResponses?.type_dernier_diplome_obtenu === "Autre (à préciser)"){
                setIsProfessionnelAvecAutreDiplome(true);
            }
            if(savedProfilEtudiantResponses?.type_dernier_diplome_obtenu !== "Autre (à préciser)"){
                setIsProfessionnelAvecAutreDiplome(false);
            }
        }
        if(e.target.name === "situation_actuelle" && (
            e.target.value !== "Etudiant" && 
            e.target.value !== "Etudiant en stage ou en activité professionnelle"
        )){
            setShowNiveauEtudeActuelle(false);
        }
        if(e.target.name === "situation_actuelle"){
            if(e.target.value === "Elève en terminale (au lycée)"){
                setIsEleveTerminal(true);
            }
            if(e.target.value !== "Elève en terminale (au lycée)"){
                setIsEleveTerminal(false);
            }
        }
        if(e.target.name === "situation_actuelle" && (
            e.target.value === "Bachelier"
        )){
            setIsBachelier(true);
        }
        if(e.target.name === "situation_actuelle" && (
            e.target.value !== "Bachelier"
        )){
            setIsBachelier(false);
        } 
        
        
        if(e.target.name === 'nombre_redoublement'){
            if(e.target.value === "0"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_redoublement: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "1"){
                setScore(score + 7);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_redoublement: '7'
                });
                setSumming(state => [...state, 7]);
            }
            if(e.target.value === "2"){
                setScore(score + 3);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_redoublement: '3'
                });
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "3"){
                setScore(score + 0);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_redoublement: '0'
                });
                setSumming(state => [...state, 0]);
            }
            if(e.target.value === "4 et plus"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_redoublement: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "etude_type_etablissement"){
            if(e.target.value === "Privé"){
                setScore(score + 7);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_etude_type_etablissement: '7'
                });
                setSumming(state => [...state, 7]);
            }
            if(e.target.value === "Public"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_etude_type_etablissement: '10'
                });
                setSumming(state => [...state, 10]);
            }
        }
        if(e.target.name === "nombre_procedure_campus_france"){
            if(e.target.value === "3"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_procedure_campus_france: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "4 et plus"){
                setScore(score - 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_procedure_campus_france: '-10'
                });
                setSumming(state => [...state, -10]);
            }
            if(e.target.value !== "0"){
                setShowNombreAcceptation(true);
            }
            if(e.target.value === "0"){
                setShowNombreAcceptation(false);
            }
            
        }
        if(e.target.name === "nombre_acceptation_procedure_campus_france"){
            if(e.target.value === "1"){
                setScore(score + 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_acceptation_procedure_campus_france: '5'
                });
                setSumming(state => [...state, 5]);
            }
            if(e.target.value === "2"){
                setScore(score + 7);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_acceptation_procedure_campus_france: '7'
                });
                setSumming(state => [...state, 7]);
            }
            if(e.target.value === "3"){
                setScore(score + 9);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_acceptation_procedure_campus_france: '9'
                });
                setSumming(state => [...state, 9]);
            }
            if(e.target.value === "4 et plus"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_nombre_acceptation_procedure_campus_france: '10'
                });
                setSumming(state => [...state, 10]);
            }
        }
        if(e.target.name === "pourquoi_poursuivre_etude_en_france"){
            if(e.target.value === "La spécialisation que je souhaite faire n’existe pas au SN"){
                setScore(score + 6);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '6'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '6'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '6'
                    });
                }
                
                setSumming(state => [...state, 6]);
            }
            if(e.target.value === "Cout d’études plus abordables par rapport aux pays étranger"){
                setScore(score + 3);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '3'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '3'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '3'
                    });
                }
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "Besoin d'une formation plus pratique (encadrement et matériel)"){
                setScore(score + 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '5'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '5'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '5'
                    });
                }
                setSumming(state => [...state, 5]);
            }
            if(e.target.value === "Possibilité d’alternance/facilité d’avoir un stage"){
                setScore(score + 3);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '3'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '3'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '3'
                    });
                }
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "Rapprochement familial"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '-5'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '-5'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '-5'
                    });
                }
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Découverte d'une autre culture ou autre pays"){
                setScore(score + 3);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '3'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '3'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '3'
                    });
                }
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "Reconnaissance des diplômes"){
                setScore(score + 3);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '3'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '3'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '3'
                    });
                }
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "Expériences internationales"){
                setScore(score + 3);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '3'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '3'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '3'
                    });
                }
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "Mêmes avantages que les étudiants français"){
                setScore(score - 3)
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '-3'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '-3'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '-3'
                    });
                }
                setSumming(state => [...state, -3]);
            }
            if(e.target.value === "Qualité de la formation"){
                setScore(score + 2);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '2'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '2'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '2'
                    });
                }
                setSumming(state => [...state, 2]);
            }
            if(e.target.value === "Besoin d’évoluer professionnellement"){
                setScore(score + 6);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_poursuivre_etude_en_france: '6'
                });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_pourquoi_poursuivre_etude_en_france')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france_2: '6'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_pourquoi_poursuivre_etude_en_france: '6'
                    });
                }
                setSumming(state => [...state, 6]);
            }
        }
        if(e.target.name === "idee_etude_en_france"){
            if(e.target.value === "Oui"){
                setShowInformationSurFormation(true);
            }
            if(e.target.value === "Non"){
                setShowInformationSurFormation(false);
            }
        }
        if(e.target.name === "information_sur_formation_voulue"){
            if(e.target.value === "Oui"){
                setShowEtudeComment(true);
            }
            if(e.target.value === "Non"){
                setShowEtudeComment(false);
            }
        }
        if(e.target.name === "idee_etude_fr_comment_quel_moyen"){
            if(e.target.value === "Monespacesn / Campus France"){
                setScore(score + 2);
                // setSavedProfilEtudiantResponses({
                //     ...savedProfilEtudiantResponses, 
                //     score_idee_etude_fr_comment_quel_moyen: '2'
                // });
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_idee_etude_fr_comment_quel_moyen')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen_2: '2'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen: '2'
                    });
                }
                setSumming(state => [...state, 2]);
            }
            if(e.target.value === "Sites d’orientation (Onisep, CIDJ, parcoursup)"){
                setScore(score + 2);
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_idee_etude_fr_comment_quel_moyen')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen_2: '2'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen: '2'
                    });
                }
                setSumming(state => [...state, 2]);
            }
            if(e.target.value === "Site internet des établissements"){
                setScore(score + 3);
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_idee_etude_fr_comment_quel_moyen')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen_2: '3'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen: '3'
                    });
                }
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "Un ami en France dans la même formation"){
                setScore(score + 1);
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_idee_etude_fr_comment_quel_moyen')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen_2: '1'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen: '1'
                    });
                }
                setSumming(state => [...state, 1]);
            }
            if(e.target.value === "Salon / Forum"){
                setScore(score + 2);
                if(savedProfilEtudiantResponses?.hasOwnProperty('score_idee_etude_fr_comment_quel_moyen')){
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen_2: '2'
                    });
                }else{
                    setSavedProfilEtudiantResponses({
                        ...savedProfilEtudiantResponses, 
                        score_idee_etude_fr_comment_quel_moyen: '2'
                    });
                }
                setSumming(state => [...state, 2]);
            }
        }
        if(e.target.name === "dynamique_projet_etude"){
            if(e.target.value === "Changement de filière"){
                setShowAchevementCycleActuel(true);
            }
            if(e.target.value !== "Changement de filière"){
                setShowAchevementCycleActuel(false);
            }
        }
        if(e.target.name === "achevement_cycle_actuel"){
            if(e.target.value === "Non"){
                setShowFormationSuivieDepuis(true);
            }
            if(e.target.value === "Oui"){
                setShowFormationSuivieDepuis(false);
            }
        }
        if(e.target.name === "formation_suivie_depuis_combien_detemps"){
            if(e.target.value === "Depuis 2 ans et +"){
                setShowPourquoiReorientation(true);
            }
            if(e.target.value !== "Depuis 2 ans et +"){
                setShowPourquoiReorientation(false);
            }
        }
        if(e.target.name === "pourquoi_reorientation"){
            if(e.target.value === "Orientation de départ subie ou non souhaitée"){
                setScore(score - 2);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_reorientation: '-2'
                });
                setSumming(...summing, -2);
            }
            if(e.target.value === "Préfère un cursus moins long et plus professionnalisant"){
                setScore(score + 2);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_reorientation: '2'
                });
                setSumming(state => [...state, 2]);
            }
            if(e.target.value === "Formation suivie qui ne répond pas à mes attentes (en termes de contenu, débouchés)"){
                setScore(score + 3);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_reorientation: '3'
                });
                setSumming(state => [...state, 3]);
            }
            if(e.target.value === "Formation trop difficile pour moi"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_pourquoi_reorientation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "souhait_niveau_etude_france"){
            // if(e.target.value === "BAC+1" || e.target.value === "BAC+2"){
            //     setShowProjetRecherche(true);
            //     setShowProjetApresFormation(true);
            // }
            // if(e.target.value !== "BAC+1" || e.target.value !== "BAC+2"){
            //     setShowProjetRecherche(false);
            //     setShowProjetApresFormation(false);
            // }
            if(e.target.value !== "BAC+1" || e.target.value !== "BAC+2"){
                setShowProjetRecherche(true);
                setShowProjetApresFormation(true);
            }
            if(e.target.value === "BAC+1" || e.target.value === "BAC+2"){
                setShowProjetRecherche(false);
                setShowProjetApresFormation(false);
            }
            if(e.target.value === "BAC+1" || e.target.value === "BAC+2" || e.target.value === "BAC+3"){
                setShowCoutFormationOption1(true);
                setShowCoutFormationOption2(false);
            }
            if(e.target.value === "BAC+4" || e.target.value === "BAC+5" || e.target.value === "BAC+6 et plus"){
                setShowCoutFormationOption1(false);
                setShowCoutFormationOption2(true);
            }
        }
        if(e.target.name === "definition_projet_recherche_ouprofessionnel"){
            if(e.target.value === "Je vais m’y mettre"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_definition_projet_recherche_ouprofessionnel: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "projet_apres_formation"){
            if(e.target.value === "Je ne me suis pas encore décidé"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_definition_projet_recherche_ouprofessionnel: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "licence_universite_publique_cout_formation"){
            if(e.target.value === "- 1000 euros"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 1000 et 2000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 2000 et 3000"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_publique_cout_formation: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "Entre 3000 et 4000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "4000 euros et plus"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "licence_universite_privee_cout_formation"){
            if(e.target.value === "- 1000 euros"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_privee_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 1000 et 2000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_privee_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 2000 et 3000"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_privee_cout_formation: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "Entre 3000 et 4000"){
                setScore(score + 7);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_privee_cout_formation: '7'
                });
                setSumming(state => [...state, 7]);
            }
            if(e.target.value === "4000 euros et plus"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_licence_universite_privee_cout_formation: '10'
                });
                setSumming(state => [...state, 10]);
            }
        }
        if(e.target.name === "master_universite_publique_cout_formation"){
            if(e.target.value === "- 1000 euros"){
                setScore(score - 5)
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 1000 et 2000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 2000 et 3000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 3000 et 4000"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_publique_cout_formation: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "4000 euros et plus"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Je ne sais pas"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_publique_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "master_universite_privee_cout_formation"){
            if(e.target.value === "- 1000 euros"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_privee_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 1000 et 2000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_privee_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 2000 et 3000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_privee_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 3000 et 4000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_privee_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "4000 euros et plus"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_privee_cout_formation: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "Je ne sais pas"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_master_universite_privee_cout_formation: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "frais_de_vie_annee_etude"){
            if(e.target.value === "– 5000 euros"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_frais_de_vie_annee_etude: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 5000 et 7000"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_frais_de_vie_annee_etude: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Entre 7000 et 10000"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_frais_de_vie_annee_etude: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "10000 et plus"){
                setScore(score + 10);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_frais_de_vie_annee_etude: '10'
                });
                setSumming(state => [...state, 10]);
            }
            if(e.target.value === "Je ne sais pas"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_frais_de_vie_annee_etude: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "financement_etude"){
            if(e.target.value === "Travailler en France"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_financement_etude: '-5'
                });
                setSumming(state => [...state, -5]);
            }
            if(e.target.value === "Trouver un petit boulot"){
                setScore(score - 5);
                setSavedProfilEtudiantResponses({
                    ...savedProfilEtudiantResponses, 
                    score_financement_etude: '-5'
                });
                setSumming(state => [...state, -5]);
            }
        }
        if(e.target.name === "type_dernier_diplome_obtenu"){
            if(e.target.value === "Autre (à préciser)"){
                setShowAutreTypeDiplomeObtenu(true);
            }
            if(e.target.value !== "Autre (à préciser)"){
                setShowAutreTypeDiplomeObtenu(false);
            }
        }

        console.log('score', score)
        console.log('projet_recherche', showProjetRecherche)
        console.log('projet_apres_formation', showProjetApresFormation)
        console.log('option 1', showCoutFormationOption1)
        console.log('option 2', showCoutFormationOption2)
        console.log('summing', summing)
    }

    const onSubmitProfilEtudiantResponse = () => {
        // e.preventDefault();
        setSendResponseInProgress(true);
        savedProfilEtudiantResponses["profil_etudiant_id"] = savedProfilEtudiant?.id;
        savedProfilEtudiantResponses["score"] = score;

        if(premierMoyenneAnnuelle && premierMoyenneAnnuelle !== 0.00){
            savedProfilEtudiantResponses["premier_moyenne_annuelle"] = String(premierMoyenneAnnuelle);
        }
        if(deuxiemeMoyenneAnnuelle && deuxiemeMoyenneAnnuelle !== 0.00){
            savedProfilEtudiantResponses["deuxieme_moyenne_annuelle"] = String(deuxiemeMoyenneAnnuelle);
        }
        if(troisiemeMoyenneAnnuelle && troisiemeMoyenneAnnuelle !== 0.00){
            savedProfilEtudiantResponses["troisieme_moyenne_annuelle"] = String(troisiemeMoyenneAnnuelle);
        }
        if(isBachelier || isEleveTerminal || isProfessionnelBachelier){
            savedProfilEtudiantResponses["check_premiere_annee"] = "Seconde";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "Première";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "Terminale";
        }
        if(showPremiereAnneeMoyenne){
            savedProfilEtudiantResponses["check_premiere_annee"] = "1ére Année";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "Terminale";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "Première";
        }
        if(showDeuxiemeAnneeMoyenne){
            savedProfilEtudiantResponses["check_premiere_annee"] = "2éme Année";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "1ére Année";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "Terminale";
        }
        if(showTroisiemeAnneeMoyenne){
            savedProfilEtudiantResponses["check_premiere_annee"] = "3éme Année";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "2éme Année";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "1ére Année";
        }
        if(showQuatriemeAnneeMoyenne){
            savedProfilEtudiantResponses["check_premiere_annee"] = "4éme Année";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "3éme Année";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "2éme Année";
        }
        if(showCinquiemeAnneeMoyenne){
            savedProfilEtudiantResponses["check_premiere_annee"] = "5éme Année";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "4éme Année";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "3éme Année";
        }
        if(showDoctorantAnneeMoyenne || isProfessionnelDoctorant){
            savedProfilEtudiantResponses["check_premiere_annee"] = "1ére Année Doctorat";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "2éme Année Doctorat";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "3éme Année Doctorat";
        }
        if(isProfessionnelBTS){
            savedProfilEtudiantResponses["check_premiere_annee"] = "1ére Année BTS";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "2éme Année BTS";
        }
        if(isProfessionnelLicence){
            savedProfilEtudiantResponses["check_premiere_annee"] = "1ére Année Licence";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "2éme Année Licence";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "3éme Année Licence";
        }
        if(isProfessionnelMaster){
            savedProfilEtudiantResponses["check_premiere_annee"] = "3éme Année Licence";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "2éme Année Master";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "1ére Année Master";
        }
        if(isProfessionnelDoctorant){
            savedProfilEtudiantResponses["check_premiere_annee"] = "3éme Année Doctorat";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "2éme Année Doctorat";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "1ére Année Doctorat";
        }
        if(isProfessionnelAvecAutreDiplome){
            savedProfilEtudiantResponses["check_premiere_annee"] = "Année 1";
            savedProfilEtudiantResponses["check_deuxieme_annee"] = "Année 2";
            savedProfilEtudiantResponses["check_troisieme_annee"] = "Année 3";
        }
        
        console.log('responses', savedProfilEtudiantResponses);
        const sum =  summing.reduce((result, number)=> result + number);

        console.log("final sum", sum);

        axios.post(`${ApiBaseUrl}profil_etudiant_details`, savedProfilEtudiantResponses)
            .then(response => {
                if(response.data.success){
                    setSendResponseInProgress(false);
                    // setQuizzIsDone(true);
                    setAutoEvalSavedReturnData(response.data.data)
                    // $(".carousel-control-next").click();
                    setShowResult(true);
                }
            })
            .catch(error => {
                onDeleteProfilEtudiant(savedProfilEtudiant?.id)
                setSendResponseInProgress(false);
                console.log('error', error);
                toast.error('Oups! Une erreur est survenue lors de la sauvegarde de vos réponses. Nous vous suggérons de reprendre l\'auto-évaluation. Si le problème persiste, veuillez rentrer en contact avec un conseiller.', {
                    duration: 10000,
                    position: 'top-center'
                });
                setTimeout(() => {
                    history.push('/accueil-auto-evaluation')
                }, 10000);
            })
    }

    const onDeleteProfilEtudiant = (id) => {
        axios.delete(`${ApiBaseUrl}profil_etudiants/${savedProfilEtudiant?.id}`)
            .then(resp => {
                if(resp.data.success){
                    console.log(resp.data.message)
                }
            })
            .catch(error => {
                console.log('deleted error', error)
            })
    }


    return (
        <div className="auto-eval-quiz-component">
            <LandingPageHeaderV2 />
            <div className="fil-ariane-container">
                <div className="fil-ariane-body">
                    <div className="fil-ariane-content">
                        <Link className="faria-link" to="/">Accueil</Link> &gt;
                        <Link className="faria-link" to="/accueil-auto-evaluation">Mon Espace SN</Link> &gt;
                        <span>Outil d'auto évaluation</span>
                    </div>
                </div>
            </div>
            <div className="ae-quizz-page-title">
                <h1>Outil d’auto-évaluation</h1>
            </div>

            <div className="ae-quizz-container container">
                <div className="ae-quizz-body">
                    <div className="ae-quizz-content-page">
                        <form id="ae-quizz-form">
                            <div id="auto-eval-quizz-carousel" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" id="part_1_title">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 1 : VOS INFORMATIONS PERSONNELLES</h2>
                                                <div className="ae-quizz-title-infos-textual-container">
                                                    <div className="ae-quizz-title-infos-textual-content">
                                                        <p>
                                                            Les informations personnelles recueillies sur ce formulaire sont enregistrées dans un fichier informatisé pour vous aider au mieux à évaluer votre projet.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next-quizz-btn-container">
                                                <button className="carousel-control-next" data-target="#auto-eval-quizz-carousel" data-slide="next">
                                                    Suivant
                                                </button>
                                            </div>
                                            {/* {
                                                consent === "ok" &&
                                                <>
                                                    <div className="ae-quizz-item-content-body">
                                                        <h2>PARTIE 1 : VOS INFORMATIONS PERSONNELLES</h2>
                                                        <div className="ae-quizz-title-infos-textual-container">
                                                            <div className="ae-quizz-title-infos-textual-content">
                                                                <p>
                                                                    Les informations personnelles recueillies sur ce formulaire sont enregistrées dans un fichier informatisé pour vous aider au mieux à évaluer votre projet.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="next-quizz-btn-container">
                                                        <button className="carousel-control-next" data-target="#auto-eval-quizz-carousel" data-slide="next">
                                                            Suivant
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                            {
                                                consent !== "ok" &&
                                                <div className="consentement-nok-alert-container">
                                                    <div className="consentement-nok-content-body">
                                                        <div className="alert alert-danger consentement-nok-alert-danger">
                                                            Vous ne pouvez pas accéder à l'outils d'auto-évaluation parce que vous n'avez pas au prélable accepter nos conditions d'utilisation.
                                                        </div>
                                                    </div>
                                                </div>
                                            } */}
                                        </div>
                                    </div>

                                    

                                    <div className="carousel-item" id="identity_slide">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <div className="require-warning-container">
                                                    <div className="alert alert-warning ae-required-warning">
                                                        Les champs avec un astérisque rouge sont obligatoires.
                                                    </div>

                                                    <div className="fcounter-container">
                                                        <span className="question-numero">1</span>
                                                        <span className="vdp">Vos données personnelles</span>
                                                    </div>
                                                </div>
                                                <div className="row ae-quizzform-row">
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="nom">Nom <span className="ae-required-field">*</span> </label>
                                                            <input onChange={QuizzHandleChange} type="text" name="nom" placeholder="Nom" id="nom" className="form-control ae-quizz-form-control" />
                                                        </div>
                                                        {is_empty_nom && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="prenom">Prénom <span className="ae-required-field">*</span> </label>
                                                            <input onChange={QuizzHandleChange} type="text" name="prenom" placeholder="Prénom" id="prenom" className="form-control ae-quizz-form-control" />
                                                        </div>
                                                        {is_empty_prenom && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="sexe">Sexe <span className="ae-required-field">*</span> </label>
                                                            <select onChange={QuizzHandleChange} name="sexe" id="sexe" className="form-control ae-quizz-form-control ae-quizz-select-input">
                                                                <option disabled selected>Choisir</option>
                                                                <option value="Masculin">Masculin</option>
                                                                <option value="Féminin">Féminin</option>
                                                            </select>
                                                        </div>
                                                        {is_empty_sexe && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="date_naissance">Date de naissance <span className="ae-required-field">*</span> </label>
                                                            <input onChange={QuizzHandleChange} type="date" name="date_naissance" id="date_naissance" className="form-control ae-quizz-form-control" />
                                                        </div>
                                                        {is_empty_date_naissance && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* Pays de résidence */}
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="date_naissance">Pays de résidence <span className="ae-required-field">*</span> </label>
                                                            <CountrySelector
                                                                className="form-control ae-quizz-form-control ae-quizz-select-input"
                                                                onChange={onSelectCountry}
                                                                optionClass="form-control ae-quizz-form-control ae-quizz-select-input"
                                                                name='pays_residence'
                                                                placeholder='Choisir un pays'
                                                                value={country}
                                                            />
                                                        </div>
                                                        {is_empty_pays_residence && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    {(openSenegalRegionInput && !openHorsSenegalRegionInput) && (
                                                        <>
                                                            <div className="col-md-6 ae-quizz-form-col">
                                                                <div className="form-group ae-quizz-form-group">
                                                                    <label htmlFor="region_residence">Région de résidence <span className="ae-required-field">*</span> </label>
                                                                    <StateSelector
                                                                        onChange={onSelectRegion}
                                                                        optionClass="form-control ae-quizz-form-control ae-quizz-select-input"
                                                                        name='region_residence'
                                                                        placeholder='Choisir une région'
                                                                        value={region}
                                                                        country={country}
                                                                    />
                                                                </div>
                                                                {is_empty_region_residence && (
                                                                    <div className="alert alert-danger ae-quizz-alert-danger">
                                                                        Ce champ est requis
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {showDepartementSelect && (
                                                                <>
                                                                    <div className="col-md-6 ae-quizz-form-col">
                                                                        <div className="form-group ae-quizz-form-group">
                                                                            <label htmlFor="ville_residence">Ville de résidence <span className="ae-required-field">*</span> </label>
                                                                            <select onChange={QuizzHandleChange} className="form-control ae-quizz-form-control ae-quizz-select-input" name="ville_residence" id="ville_residence">
                                                                                <option disabled selected>Choisir une ville</option>
                                                                                {departements?.map((departement, ind) => {
                                                                                    return (
                                                                                        <option key={`departement-cf-${ind}`} value={ departement }> {`${departement}`} </option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                        {is_empty_ville_residence && (
                                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                                Ce champ est requis
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className="col-md-6 ae-quizz-form-col">
                                                                        <div className="form-group ae-quizz-form-group">
                                                                            <label htmlFor="ville_id">Antenne Campus France la plus proche <span className="ae-required-field">*</span> </label>
                                                                            <select disabled value={choosedVilleId} name="ville_id" id="ville_id" className="form-control ae-quizz-form-control ae-quizz-select-input">
                                                                                <option disabled selected>Choisir</option>
                                                                                {villes?.length !== 0 ? (
                                                                                    villes?.map((ville, index) => {
                                                                                        return (
                                                                                            <option key={`antenne-cf-${index}`} value={ ville?.id }> {`Antenne Campus France de ${ville?.name}`} </option>
                                                                                        )
                                                                                    })
                                                                                ) : 'Aucune ville'}
                                                                            </select>
                                                                        </div>
                                                                        {is_empty_ville_id && (
                                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                                Ce champ est requis
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {(!openSenegalRegionInput && openHorsSenegalRegionInput) && (
                                                        <>
                                                            <div className="col-md-6 ae-quizz-form-col">
                                                                <div className="form-group ae-quizz-form-group">
                                                                    <label htmlFor="region_residence">Région de résidence <span className="ae-required-field">*</span> </label>
                                                                    <input onChange={QuizzHandleChange} placeholder="Région de résidence" type="text" name="region_residence" id="region_residence" className="form-control ae-quizz-form-control" />
                                                                </div>
                                                                {is_empty_region_residence && (
                                                                    <div className="alert alert-danger ae-quizz-alert-danger">
                                                                        Ce champ est requis
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-md-6 ae-quizz-form-col">
                                                                <div className="form-group ae-quizz-form-group">
                                                                    <label htmlFor="ville_residence">Ville de résidence <span className="ae-required-field">*</span> </label>
                                                                    <input onChange={QuizzHandleChange} placeholder="Ville de résidence" type="text" name="ville_residence" id="ville_residence" className="form-control ae-quizz-form-control" />
                                                                </div>
                                                                {is_empty_ville_residence && (
                                                                    <div className="alert alert-danger ae-quizz-alert-danger">
                                                                        Ce champ est requis
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="nationalite">Nationalité <span className="ae-required-field">*</span> </label>
                                                            <select onChange={QuizzHandleChange} name="nationalite" id="nationalite" className="form-control ae-quizz-form-control ae-quizz-select-input">
                                                                <option disabled selected>Choisir</option>
                                                                <option value="Sénégalaise">Sénégalaise</option>
                                                                <option value="Autre">Autre</option>
                                                            </select>
                                                        </div>
                                                        {is_empty_nationalite && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    {onShowAutreNationaliteInput && (
                                                        <div className="col-md-6 ae-quizz-form-col">
                                                            <div className="form-group ae-quizz-form-group">
                                                                <label htmlFor="autre_nationalite">Autre Nationalité <span className="ae-required-field">*</span> </label>
                                                                <input onChange={QuizzHandleChange} type="text" name="autre_nationalite" placeholder="Autre Nationalité" id="autre_nationalite" className="form-control ae-quizz-form-control" />
                                                            </div>
                                                            {is_empty_autre_nationalite && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                        </div>
                                                    )}
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group input-radio-checkbox-group">
                                                            <div className="ae-form-question">Avez-vous effectué au moins une année d’études / activité professionnelle au Sénégal ?  <span className="ae-required-field">*</span> </div>
                                                            <div className="radio-checkbox-inline-type-container">
                                                                <label htmlFor="duree_presence_senegal_oui">
                                                                    <input onChange={QuizzHandleChange} type="radio" value="Oui" name="etude_ou_travail" id="duree_presence_senegal_oui" className="ae-radio-checkbox-form-control" /> &nbsp;
                                                                    <span className="presence_label">Oui</span>
                                                                </label>
                                                                <label htmlFor="duree_presence_senegal_non">
                                                                    <input onChange={QuizzHandleChange} type="radio" value="Non" name="etude_ou_travail" id="duree_presence_senegal_non" className="ae-radio-checkbox-form-control" /> &nbsp;
                                                                    <span className="presence_label">Non</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {is_empty_etude_travail && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="numero_piece_identite">Numéro de votre pièce d'identité <span className="ae-required-field">*</span> </label>
                                                            <input onChange={QuizzHandleChange} type="text" name="numero_cni" placeholder="Numéro de votre pièce d'identité" id="numero_piece_identite" className="form-control ae-quizz-form-control" />
                                                        </div>
                                                        {is_empty_numero_cni && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group">
                                                            <label htmlFor="email">Email <span className="ae-required-field">*</span> </label>
                                                            <input onChange={QuizzHandleChange} type="email" name="email" placeholder="Email" id="email" className="form-control ae-quizz-form-control" />
                                                        </div>
                                                        {is_empty_email && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                        {is_invalid_email_syntax &&
                                                            <div className="alert alert-danger oe-alert-danger"> 
                                                                Votre adresse email n'est pas valide.
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-md-6 ae-quizz-form-col">
                                                        <div className="form-group ae-quizz-form-group input-phone-container">
                                                            <label htmlFor="telephone">Téléphone <span className="ae-required-field">*</span> </label>
                                                            {/* <input onChange={QuizzHandleChange} type="text" name="telephone" placeholder="Téléphone" id="telephone" className="form-control ae-quizz-form-control" /> */}
                                                            <PhoneInput
                                                                country={'sn'}
                                                                className="phone-input"
                                                                value={phone}
                                                                onChange={phone => {
                                                                    console.log('phone', phone);
                                                                    setPhone(`+${phone}`);
                                                                }}
                                                                prefix="+"
                                                            />
                                                            {/* <i>Le téléphone doit être au format 780237814 et composer de 9 caractéres</i> */}
                                                        </div>
                                                        {is_empty_telephone && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Ce champ est requis
                                                            </div>
                                                        )}
                                                        {is_syntax_telephone_error && (
                                                            <div className="alert alert-danger ae-quizz-alert-danger">
                                                                Le numéro de téléphone n'est pas valide.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="next-quizz-btn-container">
                                                    {
                                                        sendQuizzerInProgress ? (
                                                            <button className="carousel-control-next">
                                                                En Cours
                                                            </button> 
                                                        ) : (
                                                            <button type="submit" className="submit-btn" onClick={(e) => onSendQuizzerInfos(e)}>
                                                                Valider
                                                            </button>
                                                        )
                                                    }
                                                    <button style={{display: "none"}} className="nexty" data-target="#auto-eval-quizz-carousel" data-slide="next">
                                                        Suivant
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(profilEtudiant?.autre_nationalite?.length > 0 && profilEtudiant?.etude_ou_travail === 0)  && (
                                        <div className="carousel-item" id="not-eligible-stopping">
                                            <div className="ae-quizz-item-container">
                                                <div className="ae-quizz-item-content-body">
                                                    <div className="ae-quizz-item-row">
                                                        <div className="ae-quizz-item-question-container">
                                                            <div className="not-eligible-message-container">
                                                                <div className="not-eligible-message-body">
                                                                    <h3 className="not-eligible-alert-infos-title">Alerte Info</h3>
                                                                    <div className="not-eligible-message-content">
                                                                        <p>
                                                                            Nous sommes au regret de vous informer que vous ne pouvez pas continuer l'auto évaluation parce que
                                                                            vous ne remplissez pas toutes les conditions nécessaires selon les informations que vous avez fournies.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="next-quizz-btn-container">
                                                                <Link to="/" className="return-back-not-eligible-btn btn" >
                                                                    Retourner sur la page d'accueil
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="carousel-item" id="part_2_title">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 2 : VOTRE PARCOURS ACADEMIQUE</h2>
                                                <div className="ae-quizz-title-infos-textual-container">
                                                    <div className="ae-quizz-title-infos-textual-content">
                                                        <p>
                                                            Cette partie du test répertorie votre parcours académique. Quelles écoles avez-vous fréquenter ? Combien de temps ? Quel diplôme avez-vous obtenu ? Quelle mention avez-vous reçue ?
                                                        </p>
                                                        <p>
                                                            Vous devez indiquer fidèlement ces informations et en toute transparence.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next-quizz-btn-container">
                                                <button className="carousel-control-next" data-target="#auto-eval-quizz-carousel" data-slide="next">
                                                    Suivant
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"> <span className="question-numero">2</span> Avez-vous obtenu le bac ou le BT national ? (Une seule réponse possible)</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-8 offset-md-2 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="en_preparation">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="En préparation" name="bac_bt_obtenu" id="en_preparation" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">En préparation</span>
                                                                        </label>
                                                                        <label htmlFor="je_viens_de_lobtenir">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je viens de l'obtenir" name="bac_bt_obtenu" id="je_viens_de_lobtenir" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Je viens de l'obtenir</span>
                                                                        </label>
                                                                        <label htmlFor="obtenu_annee_passee">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je l’ai obtenu l’année dernière" name="bac_bt_obtenu" id="obtenu_annee_passee" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Je l’ai obtenu l’année dernière</span>
                                                                        </label>
                                                                        <label htmlFor="obtenu_plus_dun_an">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je l’ai obtenu il y a plus d’un an" name="bac_bt_obtenu" id="obtenu_plus_dun_an" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Je l’ai obtenu il y a plus d’un an</span>
                                                                        </label>
                                                                        <label htmlFor="jamais_obtenu_ne_prevois_pas">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne l’ai pas obtenu et ne le prévoit plus de le repasser" name="bac_bt_obtenu" id="jamais_obtenu_ne_prevois_pas" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Je ne l’ai pas obtenu et ne le prévoit plus de le repasser</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        {
                                                            showNextButton && (
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* {(noHaveBac)  && (
                                        <div className="carousel-item" id="not-eligible-stopping-not-bac">
                                            <div className="ae-quizz-item-container">
                                                <div className="ae-quizz-item-content-body">
                                                    <div className="ae-quizz-item-row">
                                                        <div className="ae-quizz-item-question-container">
                                                            <div className="not-eligible-message-container">
                                                                <div className="not-eligible-message-body">
                                                                    <h3 className="not-eligible-alert-infos-title">Alerte Info</h3>
                                                                    <div className="not-eligible-message-content">
                                                                        <p>
                                                                            Nous sommes au regret de vous informer que vous ne pouvez pas continuer l'auto évaluation parce que
                                                                            vous ne remplissez pas toutes les conditions nécessaires selon les informations que vous avez fournies.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="next-quizz-btn-container">
                                                                <Link to="/" className="return-back-not-eligible-btn btn" >
                                                                    Retourner sur la page d'accueil
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}

                                    {
                                        showMentionStep && (
                                            <>
                                                <div className="carousel-item">
                                                    <div className="ae-quizz-item-container">
                                                        <div className="counter-countdown-container">
                                                            <div className="counter-countdown-content">
                                                                <h2 style={{
                                                                    color: counter <= 10 ? "red" : "#273375"
                                                                }}> { counter } </h2>
                                                            </div>
                                                        </div>
                                                        <div className="ae-quizz-item-content-body">
                                                            <div className="ae-quizz-item-row">
                                                                <div className="ae-quizz-item-question-container">
                                                                    <h6 className="ae-quizz-item-question"><span className="question-numero">3</span>Votre Mention au BAC/BT ?</h6>
                                                                    <div className="ae-quizz-item-response-suggestion-container">
                                                                        <div className="row ae-quizz-item-response-suggestion-row">
                                                                            <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                                <div className="ae-quizz-response-suggestion-item">
                                                                                    <label htmlFor="passable">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Passable" name="mention_bac_bt" id="passable" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Passable</span>
                                                                                    </label>
                                                                                    <label htmlFor="assez_bien">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Assez Bien" name="mention_bac_bt" id="assez_bien" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Assez Bien</span>
                                                                                    </label>
                                                                                    <label htmlFor="bien">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Bien" name="mention_bac_bt" id="bien" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Bien</span>
                                                                                    </label>
                                                                                    <label htmlFor="tres_bien">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Très Bien" name="mention_bac_bt" id="tres_bien" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Très Bien</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="next-quizz-btn-container">
                                                                        <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                            Suivant
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="carousel-item">
                                                    <div className="ae-quizz-item-container">
                                                        <div className="counter-countdown-container">
                                                            <div className="counter-countdown-content">
                                                                <h2 style={{
                                                                    color: counter <= 10 ? "red" : "#273375"
                                                                }}> { counter } </h2>
                                                            </div>
                                                        </div>
                                                        <div className="ae-quizz-item-content-body">
                                                            <div className="ae-quizz-item-row">
                                                                <div className="ae-quizz-item-question-container">
                                                                    <h6 className="ae-quizz-item-question"><span className="question-numero">4</span>Combien de fois avez-vous passé cet examen (Bac ou BT) ?</h6>
                                                                    <div className="ae-quizz-item-response-suggestion-container">
                                                                        <div className="row ae-quizz-item-response-suggestion-row">
                                                                            <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                                <div className="ae-quizz-response-suggestion-item">
                                                                                    <label htmlFor="une_fois">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="1x" name="nombre_passage_examen_bac_bt" id="une_fois" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">1x</span>
                                                                                    </label>
                                                                                    <label htmlFor="deux_fois">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="2x" name="nombre_passage_examen_bac_bt" id="deux_fois" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">2x</span>
                                                                                    </label>
                                                                                    <label htmlFor="trois_fois">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="3x" name="nombre_passage_examen_bac_bt" id="trois_fois" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">3x</span>
                                                                                    </label>
                                                                                    <label htmlFor="plus_de_quatre_fois">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="+4x" name="nombre_passage_examen_bac_bt" id="plus_de_quatre_fois" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">+4x</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="next-quizz-btn-container">
                                                                        <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                            Suivant
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    
                                    {/* Autres avec input */}
                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">5</span>Série du BAC ou du BT <i>(en cours de préparation ou obtenu)</i> </h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="litterature">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Littéraire" name="serie_bac_bt" id="litterature" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Littéraire</span>
                                                                        </label>
                                                                        <label htmlFor="scientifique">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Scientifique" name="serie_bac_bt" id="scientifique" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Scientifique</span>
                                                                        </label>
                                                                        <label htmlFor="economique_gestion">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Economique – Gestion" name="serie_bac_bt" id="economique_gestion" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Economique – Gestion</span>
                                                                        </label>
                                                                        <label htmlFor="technique">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Technique" name="serie_bac_bt" id="technique" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Technique</span>
                                                                        </label>
                                                                        <label htmlFor="electronique">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Electronique" name="serie_bac_bt" id="electronique" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Electronique</span>
                                                                        </label>
                                                                        <div className="autre-reponse-group-input-container">
                                                                            <label htmlFor="autre_serie">
                                                                                <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Autre (à préciser)" name="serie_bac_bt" id="autre_serie" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                <span className="suggestion-label">Autre (à préciser)</span>
                                                                            </label>
                                                                            {
                                                                                showAutreBacBtInput && (
                                                                                    <div className="form-group ae-quizz-form-group">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="text" name="autre_serie_bac_bt" placeholder="Autre série à préciser" id="autre_serie_bac_btn" className="form-control ae-quizz-form-control" />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">6</span>Quelle est votre situation actuelle ? </h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-6 offset-md-3 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="terminale">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Elève en terminale (au lycée)" name="situation_actuelle" id="terminale" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Elève en terminale (au lycée)</span>
                                                                        </label>
                                                                        <label htmlFor="bachelier">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Bachelier" name="situation_actuelle" id="bachelier" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Bachelier</span>
                                                                        </label>
                                                                        <label htmlFor="etudiant">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Etudiant" name="situation_actuelle" id="etudiant" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Etudiant</span>
                                                                        </label>
                                                                        <label htmlFor="etudiant_stage_activite_pro">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Etudiant en stage ou en activité professionnelle" name="situation_actuelle" id="etudiant_stage_activite_pro" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Etudiant en stage ou en activité professionnelle</span>
                                                                        </label>
                                                                        <label htmlFor="professionnel">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Professionnel" name="situation_actuelle" id="professionnel" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Professionnel</span>
                                                                        </label>
                                                                        <label htmlFor="fais_rien">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne fais rien" name="situation_actuelle" id="fais_rien" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Je ne fais rien</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        showDureeTravailOuState && (
                                            <>
                                                <div className="carousel-item">
                                                    <div className="ae-quizz-item-container">
                                                        <div className="counter-countdown-container">
                                                            <div className="counter-countdown-content">
                                                                <h2 style={{
                                                                    color: counter <= 10 ? "red" : "#273375"
                                                                }}> { counter } </h2>
                                                            </div>
                                                        </div>
                                                        <div className="ae-quizz-item-content-body">
                                                            <div className="ae-quizz-item-row">
                                                                <div className="ae-quizz-item-question-container">
                                                                    <h6 className="ae-quizz-item-question"><span className="question-numero">7</span>Depuis combien de temps travaillez-vous ou êtes-vous en stage ? </h6>
                                                                    <div className="ae-quizz-item-response-suggestion-container">
                                                                        <div className="row ae-quizz-item-response-suggestion-row">
                                                                            <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                                <div className="ae-quizz-response-suggestion-item">
                                                                                    <label htmlFor="moins_6mois">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Moins de 6 mois" name="duree_travail_ou_stage" id="moins_6mois" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Moins de 6 mois</span>
                                                                                    </label>
                                                                                    <label htmlFor="un_an">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="1 an" name="duree_travail_ou_stage" id="un_an" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">1 an</span>
                                                                                    </label>
                                                                                    <label htmlFor="deux_ans">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="2 ans" name="duree_travail_ou_stage" id="deux_ans" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">2 ans</span>
                                                                                    </label>
                                                                                    <label htmlFor="trois_ans">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="3 ans" name="duree_travail_ou_stage" id="trois_ans" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">3 ans</span>
                                                                                    </label>
                                                                                    <label htmlFor="quatre_ans_et_plus">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4 ans et plus" name="duree_travail_ou_stage" id="quatre_ans_et_plus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">4 ans et plus</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="next-quizz-btn-container">
                                                                        <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                            Suivant
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="carousel-item">
                                                    <div className="ae-quizz-item-container">
                                                        <div className="counter-countdown-container">
                                                            <div className="counter-countdown-content">
                                                                <h2 style={{
                                                                    color: counter <= 10 ? "red" : "#273375"
                                                                }}> { counter } </h2>
                                                            </div>
                                                        </div>
                                                        <div className="ae-quizz-item-content-body">
                                                            <div className="ae-quizz-item-row">
                                                                <div className="ae-quizz-item-question-container">
                                                                    <h6 className="ae-quizz-item-question"><span className="question-numero">8</span>Type du dernier diplôme obtenu ? </h6>
                                                                    <div className="ae-quizz-item-response-suggestion-container">
                                                                        <div className="row ae-quizz-item-response-suggestion-row">
                                                                            <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                                <div className="ae-quizz-response-suggestion-item">
                                                                                    <label htmlFor="diplome_bac_bt">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BAC /BT" name="type_dernier_diplome_obtenu" id="diplome_bac_bt" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">BAC /BT</span>
                                                                                    </label>
                                                                                    <label htmlFor="bts_dut_disep">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BTS/DUT/DISEP" name="type_dernier_diplome_obtenu" id="bts_dut_disep" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">BTS/DUT/DISEP</span>
                                                                                    </label>
                                                                                    <label htmlFor="diplome_licence">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Licence" name="type_dernier_diplome_obtenu" id="diplome_licence" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Licence</span>
                                                                                    </label>
                                                                                    <label htmlFor="diplome_master">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Master" name="type_dernier_diplome_obtenu" id="diplome_master" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Master</span>
                                                                                    </label>
                                                                                    <label htmlFor="diplome_doctorat">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Doctorat" name="type_dernier_diplome_obtenu" id="diplome_doctorat" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Doctorat</span>
                                                                                    </label>
                                                                                    <div className="autre-reponse-group-input-container">
                                                                                        <label htmlFor="autre_diplome_apreciser">
                                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Autre (à préciser)" name="type_dernier_diplome_obtenu" id="autre_diplome_apreciser" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                            <span className="suggestion-label">Autre (à préciser)</span>
                                                                                        </label>

                                                                                        {
                                                                                            showAutreTypeDiplomeObtenu && (
                                                                                                <div className="form-group ae-quizz-form-group">
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="text" name="autre_type_dernier_diplome_obtenu" placeholder="Autre diplôme à préciser" id="autre_type_dernier_diplome_obtenu" className="form-control ae-quizz-form-control" />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="next-quizz-btn-container">
                                                                        <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                            Suivant
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="carousel-item">
                                                    <div className="ae-quizz-item-container">
                                                        <div className="counter-countdown-container">
                                                            <div className="counter-countdown-content">
                                                                <h2 style={{
                                                                    color: counter <= 10 ? "red" : "#273375"
                                                                }}> { counter } </h2>
                                                            </div>
                                                        </div>
                                                        <div className="ae-quizz-item-content-body">
                                                            <div className="ae-quizz-item-row">
                                                                <div className="ae-quizz-item-question-container">
                                                                    <h6 className="ae-quizz-item-question"><span className="question-numero">9</span>Depuis combien de temps avez-vous obtenu votre dernier diplôme ?  </h6>
                                                                    <div className="ae-quizz-item-response-suggestion-container">
                                                                        <div className="row ae-quizz-item-response-suggestion-row">
                                                                            <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                                <div className="ae-quizz-response-suggestion-item">
                                                                                    <label htmlFor="diplome_moins_6mois">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Moins de 6 mois" name="obtention_diplome_depuis" id="diplome_moins_6mois" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">Moins de 6 mois</span>
                                                                                    </label>
                                                                                    <label htmlFor="diplome_un_an">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="1 an" name="obtention_diplome_depuis" id="diplome_un_an" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">1 an</span>
                                                                                    </label>
                                                                                    <label htmlFor="diplome_deux_ans">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="2 ans" name="obtention_diplome_depuis" id="diplome_deux_ans" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">2 ans</span>
                                                                                    </label>
                                                                                    <label htmlFor="diplome_trois_ans">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="3 ans" name="obtention_diplome_depuis" id="diplome_trois_ans" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">3 ans</span>
                                                                                    </label>
                                                                                    <label htmlFor="diplome_quatre_ans_et_plus">
                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4 ans et plus" name="obtention_diplome_depuis" id="diplome_quatre_ans_et_plus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                        <span className="suggestion-label">4 ans et plus</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="next-quizz-btn-container">
                                                                        <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                            Suivant
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    {
                                        (showNiveauEtudeActuelle && 
                                         savedProfilEtudiantResponses?.situation_actuelle !== "Etudiant en stage ou en activité professionnelle"
                                        )  && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">10</span>Quel est votre niveau d’études actuel ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="niveau_premiere_annee">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="1ére Année" name="niveau_etude" id="niveau_premiere_annee" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">1ére Année</span>
                                                                                </label>
                                                                                <label htmlFor="niveau_deuxieme_anne">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="2éme Année" name="niveau_etude" id="niveau_deuxieme_anne" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">2éme Année</span>
                                                                                </label>
                                                                                <label htmlFor="niveau_troisieme_annee">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="3éme Année" name="niveau_etude" id="niveau_troisieme_annee" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">3éme Année</span>
                                                                                </label>
                                                                                <label htmlFor="niveau_quatrieme_annee">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4éme Année" name="niveau_etude" id="niveau_quatrieme_annee" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">4éme Année</span>
                                                                                </label>
                                                                                <label htmlFor="niveau_cinquieme_annee">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="5éme Année" name="niveau_etude" id="niveau_cinquieme_annee" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">5éme Année</span>
                                                                                </label>
                                                                                <label htmlFor="niveau_doctorant">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Doctorant" name="niveau_etude" id="niveau_doctorant" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Doctorant</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="carousel-item" id="gestion_note">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">11</span>Combien de fois avez-vous redoublé ?</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="red_0">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="0" name="nombre_redoublement" id="red_0" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">0</span>
                                                                        </label>
                                                                        <label htmlFor="red_1">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="1" name="nombre_redoublement" id="red_1" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">1</span>
                                                                        </label>
                                                                        <label htmlFor="red_2">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="2" name="nombre_redoublement" id="red_2" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">2</span>
                                                                        </label>
                                                                        <label htmlFor="red_3">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="3" name="nombre_redoublement" id="red_3" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">3</span>
                                                                        </label>
                                                                        <label htmlFor="red_4_plus">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4 et plus" name="nombre_redoublement" id="red_4_plus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">4 et plus</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Gestion des Notes */}
                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">12</span>Moyennes annuelles obtenues durant les trois dernières années d’études validées (2 années pour les élèves de terminale)</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-12 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">

                                                                        {
                                                                            (isBachelier || isEleveTerminal || isProfessionnelBachelier) && (
                                                                                <>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">Seconde</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_1" id="premier_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_2" id="premier_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" />
                                                                                                    {/* <input value={premierMoyenneAnnuelle} disabled placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">Première</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_1" id="deuxieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_2" id="deuxieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" />
                                                                                                    {/* <input value={deuxiemeMoyenneAnnuelle} disabled placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">Terminale</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_1" id="troisieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_2" id="troisieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" id="troisieme_moyenne_annuelle" />
                                                                                                    {/* <input value={troisiemeMoyenneAnnuelle} disabled placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" id="troisieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                                <div className="annee-encours-checkbox-container">
                                                                                                    <label htmlFor="troisieme_annee_encours">
                                                                                                        <input disable-data="troisieme" onChange={onHandleChangeProfilEtudiantResponse} type="checkbox" value="En cours" name="troisieme_annee_encours" id="troisieme_annee_encours" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">En cours</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (!isBachelier && 
                                                                                !isEleveTerminal && 
                                                                                !isProfessionnelBachelier &&
                                                                                !isProfessionnelBTS &&
                                                                                !isProfessionnelLicence &&
                                                                                !isProfessionnelMaster &&
                                                                                !isProfessionnelDoctorant &&
                                                                                !isProfessionnelAvecAutreDiplome
                                                                            ) && (
                                                                                <>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        {
                                                                                                            showPremiereAnneeMoyenne && "1ére Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showDeuxiemeAnneeMoyenne && "2éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showTroisiemeAnneeMoyenne && "3éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showQuatriemeAnneeMoyenne && "4éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showCinquiemeAnneeMoyenne && "5éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showDoctorantAnneeMoyenne && "1ére Année Doctorat"
                                                                                                        }
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_1" id="premier_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_2" id="premier_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" />
                                                                                                    {/* <input  disabled value={premierMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                                
                                                                                                <div className="annee-encours-checkbox-container">
                                                                                                    <label htmlFor="premiere_annee_encours">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="checkbox" value="En cours" name="premiere_annee_encours" id="premiere_annee_encours" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">En cours</span>
                                                                                                    </label>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        {
                                                                                                            showPremiereAnneeMoyenne && "Terminale"
                                                                                                        }
                                                                                                        {
                                                                                                            showDeuxiemeAnneeMoyenne && "1ére Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showTroisiemeAnneeMoyenne && "2éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showQuatriemeAnneeMoyenne && "3éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showCinquiemeAnneeMoyenne && "4éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showDoctorantAnneeMoyenne && "2éme Année Doctorat"
                                                                                                        }
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={deuxiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        {
                                                                                                            showPremiereAnneeMoyenne && "1ére"
                                                                                                        }
                                                                                                        {
                                                                                                            showDeuxiemeAnneeMoyenne && "Terminale"
                                                                                                        }
                                                                                                        {
                                                                                                            showTroisiemeAnneeMoyenne && "1éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showQuatriemeAnneeMoyenne && "2éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showCinquiemeAnneeMoyenne && "3éme Année"
                                                                                                        }
                                                                                                        {
                                                                                                            showDoctorantAnneeMoyenne && "3éme Année Doctorat"
                                                                                                        }
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={troisiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (isProfessionnelBTS) && (
                                                                                <>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        1ére année BTS
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" />
                                                                                                    {/* <input  disabled value={premierMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        2éme Année BTS
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_1" id="deuxieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_2" id="deuxieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={deuxiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                                <div className="annee-encours-checkbox-container">
                                                                                                    <label htmlFor="deuxieme_annee_encours">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="checkbox" value="En cours" name="deuxieme_annee_encours" id="deuxieme_annee_encours" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">En cours</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (isProfessionnelLicence) && (
                                                                                <>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        1ére Année Licence
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" />
                                                                                                    {/* <input  disabled value={premierMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        2éme Année Licence
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={deuxiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">3é Année Licence</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_1" id="troisieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_2" id="troisieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={troisiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                                <div className="annee-encours-checkbox-container">
                                                                                                    <label htmlFor="troisieme_annee_encours">
                                                                                                        <input disable-data="troisieme" onChange={onHandleChangeProfilEtudiantResponse} type="checkbox" value="En cours" name="troisieme_annee_encours" id="troisieme_annee_encours" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">En cours</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (isProfessionnelMaster) && (
                                                                                <>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        3éme Année Licence
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" />
                                                                                                    {/* <input  disabled value={premierMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">1ére Année Master</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={deuxiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        2éme Année Master
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_1" id="troisieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_2" id="troisieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={troisiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                                <div className="annee-encours-checkbox-container">
                                                                                                    <label htmlFor="troisieme_annee_encours">
                                                                                                        <input disable-data="troisieme" onChange={onHandleChangeProfilEtudiantResponse} type="checkbox" value="En cours" name="troisieme_annee_encours" id="troisieme_annee_encours" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">En cours</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (isProfessionnelDoctorant) && (
                                                                                <>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        3éme Année Doctorat
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_1" id="premier_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_2" id="premier_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" />
                                                                                                    {/* <input  disabled value={premierMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                                <div className="annee-encours-checkbox-container">
                                                                                                    <label htmlFor="premiere_annee_encours">
                                                                                                        <input disable-data="premier" onChange={onHandleChangeProfilEtudiantResponse} type="checkbox" value="En cours" name="premiere_annee_encours" id="troisieme_annee_encours" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">En cours</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        2éme Année Doctorat
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_1" id="deuxieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_2" id="deuxieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={deuxiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">1ére Année Doctorat</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_1" id="troisieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_1" id="troisieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={troisiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            (isProfessionnelAvecAutreDiplome) && (
                                                                                <>
                                                                                    <div className="require-warning-container">
                                                                                        <div className="alert alert-warning ae-required-warning">
                                                                                            T, représente ici l'année en cours { `(${today.getFullYear()})` }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        Année (T-2)
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_1" id="premier_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_semestre_2" id="premier_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" />
                                                                                                    {/* <input  disabled value={premierMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="premier_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">
                                                                                                        Année (T-1)
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_1" id="deuxieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_semestre_2" id="deuxieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={deuxiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="deuxieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="special-ae-quizz-reponse-item-content">
                                                                                        <div className="special-ae-input-container">
                                                                                            <div className="special-ae-radio-box">
                                                                                                <label htmlFor="moyenne_seconde">
                                                                                                    <span className="suggestion-label">Année (T)</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="moyennes-container">
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem1</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_1" id="troisieme_moyenne_semestre_1" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. Sem2</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_semestre_2" id="troisieme_moyenne_semestre_2" />
                                                                                                </div>
                                                                                                <div className="special-ae-input-item-content">
                                                                                                    <span>Moy. An</span>
                                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" />
                                                                                                    {/* <input disabled value={troisiemeMoyenneAnnuelle} placeholder="00.00" className="special-ae-input-form-control" type="text" name="troisieme_moyenne_annuelle" /> */}
                                                                                                </div>
                                                                                                <div className="annee-encours-checkbox-container">
                                                                                                    <label htmlFor="troisieme_annee_encours">
                                                                                                        <input disable-data="premier" onChange={onHandleChangeProfilEtudiantResponse} type="checkbox" value="En cours" name="troisieme_annee_encours" id="troisieme_annee_encours" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">En cours</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        (!isEleveTerminal && !isBachelier) && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">13</span>Précisez le domaine d’études dans le supérieur</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-12 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <div className="row ae-quizzform-row">
                                                                                    <div className="col-md-6 offset-md-3 ae-quizz-form-col">
                                                                                        <div className="form-group ae-quizz-form-group">
                                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="text" name="domaine_etudes_superieures" placeholder="Domaine d’études dans le supérieur" id="domaine_etudes_superieures" className="form-control ae-quizz-form-control" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">14</span>Dans quel type d’établissement étudiez-vous/avez-vous étudié ?</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="etablissement_prive">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Privé" name="etude_type_etablissement" id="etablissement_prive" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Privé</span>
                                                                        </label>
                                                                        <label htmlFor="etablissement_public">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Public" name="etude_type_etablissement" id="etablissement_public" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Public</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">15</span>Précisez le nom du dernier établissement fréquenté</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-12 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <div className="row ae-quizzform-row">
                                                                            <div className="col-md-6 offset-md-3 ae-quizz-form-col">
                                                                                <div className="form-group ae-quizz-form-group">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="text" name="nom_dernier_etablissement" placeholder="Nom du dernier établissement fréquenté" id="nom_dernier_etablissement" className="form-control ae-quizz-form-control" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        (!isEleveTerminal && !isBachelier) && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">16</span>Votre formation dans le supérieur est reconnue par</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="anaqsup">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="L’AnaqSup" name="formation_superieure_reconnue_par" id="anaqsup" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">L’AnaqSup</span>
                                                                                </label>
                                                                                <label htmlFor="le_cames">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Le Cames" name="formation_superieure_reconnue_par" id="le_cames" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Le Cames</span>
                                                                                </label>
                                                                                <label htmlFor="je_ne_sais_pas">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne sais pas" name="formation_superieure_reconnue_par" id="je_ne_sais_pas" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Je ne sais pas</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="carousel-item" id="part_3_title">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 3 : PROJET D’ETUDES</h2>
                                                <div className="ae-quizz-title-infos-textual-container">
                                                    <div className="ae-quizz-title-infos-textual-content">
                                                        <p>
                                                            Cette partie du test consiste à situer votre projet d’études. C’est le cheminement académique que vous avez décidé de suivre pour réaliser votre projet professionnel.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next-quizz-btn-container">
                                                <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                    Suivant
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item" id="checkbox_1">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">17</span>Combien de fois avez-vous fait la procédure Campus France ?</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="cf_0">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="0" name="nombre_procedure_campus_france" id="cf_0" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">0</span>
                                                                        </label>
                                                                        <label htmlFor="cf_1">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="1" name="nombre_procedure_campus_france" id="cf_1" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">1</span>
                                                                        </label>
                                                                        <label htmlFor="cf_2">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="2" name="nombre_procedure_campus_france" id="cf_2" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">2</span>
                                                                        </label>
                                                                        <label htmlFor="cf_3">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="3" name="nombre_procedure_campus_france" id="cf_3" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">3</span>
                                                                        </label>
                                                                        <label htmlFor="cf_4_et_plus">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4 et plus" name="nombre_procedure_campus_france" id="cf_4_et_plus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">4 et plus</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        showNombreAcceptation && (
                                            <div className="carousel-item" id="checkbox_1_bis">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">18</span>Combien d’acceptations avez-vous eu ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="acf_0">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="0" name="nombre_acceptation_procedure_campus_france" id="acf_0" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">0</span>
                                                                                </label>
                                                                                <label htmlFor="cf_1">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="1" name="nombre_acceptation_procedure_campus_france" id="cf_1" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">1</span>
                                                                                </label>
                                                                                <label htmlFor="acf_2">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="2" name="nombre_acceptation_procedure_campus_france" id="acf_2" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">2</span>
                                                                                </label>
                                                                                <label htmlFor="acf_3">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="3" name="nombre_acceptation_procedure_campus_france" id="acf_3" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">3</span>
                                                                                </label>
                                                                                <label htmlFor="acf_4_et_plus">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4 et plus" name="nombre_acceptation_procedure_campus_france" id="acf_4_et_plus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">4 et plus</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">19</span>Pourquoi souhaitez-vous poursuivre vos études en France ? (Choix multiples / 2 choix maximum)</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-8 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="spec_pas_au_sn">
                                                                            <input 
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                } 
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="La spécialisation que je souhaite faire n’existe pas au SN" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="spec_pas_au_sn" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("La spécialisation que je souhaite faire n’existe pas au SN")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                La spécialisation que je souhaite faire n’existe pas au SN
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="cond_favorable">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                } 
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Les Conditions d’études sont plus favorables" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="cond_favorable" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Les Conditions d’études sont plus favorables")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            } >
                                                                                Les Conditions d’études sont plus favorables
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="cout_abordable">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                } 
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Cout d’études plus abordables par rapport aux pays étranger" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="cout_abordable" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Cout d’études plus abordables par rapport aux pays étranger")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Cout d’études plus abordables par rapport aux pays étranger
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="besoin_formation_pratique">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                } 
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Besoin d'une formation plus pratique (encadrement et matériel)" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="besoin_formation_pratique" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Besoin d'une formation plus pratique (encadrement et matériel)")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Besoin d'une formation plus pratique (encadrement et matériel)
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="facilite_stage">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                }  
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Possibilité d’alternance/facilité d’avoir un stage" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="facilite_stage" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Possibilité d’alternance/facilité d’avoir un stage")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Possibilité d’alternance/facilité d’avoir un stage
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="approf_connaissance">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                }
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Approfondissement des connaissances" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="approf_connaissance" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Approfondissement des connaissances")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Approfondissement des connaissances 
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="rapprochement_familial">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                } 
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Rapprochement familial" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="rapprochement_familial" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Rapprochement familial")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Rapprochement familial 
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="dec_autre_culture_pays">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                } 
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Découverte d'une autre culture ou autre pays" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="dec_autre_culture_pays" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Découverte d'une autre culture ou autre pays")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Découverte d'une autre culture ou autre pays 
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="reconnaissance_diplome">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                }
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Reconnaissance des diplômes" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="reconnaissance_diplome" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Reconnaissance des diplômes")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Reconnaissance des diplômes  
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="exp_internationale">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                }
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Expériences internationales" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="exp_internationale" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Expériences internationales")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Expériences internationales 
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="meme_avatange_francais">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                }
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Mêmes avantages que les étudiants français" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="meme_avatange_francais" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Mêmes avantages que les étudiants français")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Mêmes avantages que les étudiants français 
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="qualite_formation">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                } 
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Qualité de la formation" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="qualite_formation" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Qualité de la formation")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Qualité de la formation 
                                                                            </span>
                                                                        </label>
                                                                        <label htmlFor="evol_professionnel">
                                                                            <input
                                                                                disabled={
                                                                                    (onDisablePoursuivreCheckbox)
                                                                                }  
                                                                                onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                type="checkbox" 
                                                                                value="Besoin d’évoluer professionnellement" 
                                                                                name="pourquoi_poursuivre_etude_en_france" 
                                                                                id="evol_professionnel" 
                                                                                className="ae-suggestion-radio-checkbox-form-control" 
                                                                            /> &nbsp;
                                                                            <span className="suggestion-label" style={
                                                                                { textDecoration: ((!savedProfilEtudiantResponses?.pourquoi_poursuivre_etude_en_france?.includes("Besoin d’évoluer professionnellement")) && onDisablePoursuivreCheckbox) && 'line-through'} 
                                                                            }>
                                                                                Besoin d’évoluer professionnellement 
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item" id="checkbox_2">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">20</span>Avez-vous une idée de ce que vous souhaitez étudier en France ?</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="ide_fr_oui">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Oui" name="idee_etude_en_france" id="ide_fr_oui" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Oui</span>
                                                                        </label>
                                                                        <label htmlFor="ide_fr_non">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Non" name="idee_etude_en_france" id="ide_fr_non" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Non</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        showInformationSurFormation && (
                                            <div className="carousel-item" id="checkbox_2_bis">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">21</span>Est-ce que vous vous êtes renseignés sur la formation envisagée ? (Contenu de la formation, prérequis et modalités d’inscription, durée de la formation, débouchés possible…)</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="renseignement_fr_oui">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Oui" name="information_sur_formation_voulue" id="renseignement_fr_oui" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Oui</span>
                                                                                </label>
                                                                                <label htmlFor="renseignement_fr_non">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Non" name="information_sur_formation_voulue" id="renseignement_fr_non" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Non</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showEtudeComment && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">22</span>Si oui, comment ? par quel moyen ? (2 maximum)</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-8 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="monespacesn_cf">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        }  
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Monespacesn / Campus France" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="monespacesn_cf" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Monespacesn / Campus France")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Monespacesn / Campus France
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="sites_orientation">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        }  
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Sites d’orientation (Onisep, CIDJ, parcoursup)" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="sites_orientation" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Sites d’orientation (Onisep, CIDJ, parcoursup)")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Sites d’orientation (Onisep, CIDJ, parcoursup)
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="google">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        } 
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Google" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="google" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Google")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Google
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="site_web_etablissement">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        } 
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Site internet des établissements" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="site_web_etablissement" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Site internet des établissements")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Site internet des établissements
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="un_proche">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        } 
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Un proche" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="un_proche" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Un proche")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Un proche
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="ami_en_france">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        } 
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Un ami en France dans la même formation" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="ami_en_france" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Un ami en France dans la même formation")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Un ami en France dans la même formation 
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="une_agence">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        } 
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Une agence" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="une_agence" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Une agence")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Une agence 
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="salon_forum">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        } 
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Salon / Forum" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="salon_forum" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Salon / Forum")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Salon / Forum  
                                                                                    </span>
                                                                                </label>
                                                                                <label htmlFor="reseaux_sociaux">
                                                                                    <input
                                                                                        disabled={
                                                                                            (onDisableCommentCheckbox)
                                                                                        } 
                                                                                        onChange={onHandleChangeProfilEtudiantResponse} 
                                                                                        type="checkbox" 
                                                                                        value="Réseaux sociaux (facebook, twitter, instagram, youtube)" 
                                                                                        name="idee_etude_fr_comment_quel_moyen" 
                                                                                        id="reseaux_sociaux" 
                                                                                        className="ae-suggestion-radio-checkbox-form-control" 
                                                                                    /> &nbsp;
                                                                                    <span className="suggestion-label"
                                                                                        style={{
                                                                                            textDecoration: ((!savedProfilEtudiantResponses?.idee_etude_fr_comment_quel_moyen?.includes("Réseaux sociaux (facebook, twitter, instagram, youtube)")) && onDisableCommentCheckbox) && 'line-through'
                                                                                        }}
                                                                                    >
                                                                                        Réseaux sociaux (facebook, twitter, instagram, youtube) 
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">23</span>Quelle année d’étude souhaitez-vous intégrer en France ?</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="bac_plus_un_fr">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BAC+1" name="souhait_niveau_etude_france" id="bac_plus_un_fr" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">BAC+1</span>
                                                                        </label>
                                                                        <label htmlFor="bac_plus_deux_fr">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BAC+2" name="souhait_niveau_etude_france" id="bac_plus_deux_fr" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">BAC+2</span>
                                                                        </label>
                                                                        <label htmlFor="bac_plus_trois_fr">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BAC+3" name="souhait_niveau_etude_france" id="bac_plus_trois_fr" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">BAC+3</span>
                                                                        </label>
                                                                        <label htmlFor="bac_plus_quatre_fr">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BAC+4" name="souhait_niveau_etude_france" id="bac_plus_quatre_fr" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">BAC+4</span>
                                                                        </label>
                                                                        <label htmlFor="bac_plus_cinq_fr">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BAC+5" name="souhait_niveau_etude_france" id="bac_plus_cinq_fr" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">BAC+5</span>
                                                                        </label>
                                                                        <label htmlFor="bac_plus_six_et_plus_fr">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="BAC+6 et plus" name="souhait_niveau_etude_france" id="bac_plus_six_et_plus_fr" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">BAC+6 et plus</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">24</span>Votre projet s’inscrit dans une dynamique de</h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-8 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="meme_niveau">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Rester sur le même niveau " name="dynamique_projet_etude" id="meme_niveau" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Rester sur le même niveau </span>
                                                                        </label>
                                                                        <label htmlFor="niveau_superieur">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Poursuivre vos études sur un niveau supérieur " name="dynamique_projet_etude" id="niveau_superieur" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Poursuivre vos études sur un niveau supérieur </span>
                                                                        </label>
                                                                        <label htmlFor="niveau_inferieur">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Reprendre une année d’études inférieure à votre niveau actuel" name="dynamique_projet_etude" id="niveau_inferieur" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Reprendre une année d’études inférieure à votre niveau actuel</span>
                                                                        </label>
                                                                        <label htmlFor="changement_filiere">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Changement de filière" name="dynamique_projet_etude" id="changement_filiere" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Changement de filière</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        showAchevementCycleActuel && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">25</span>Avez-vous achevé votre cycle actuel ? (BTS ou Disep ou Licence ou Master)</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-2 offset-md-5 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="cycle_actuel_oui">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Oui" name="achevement_cycle_actuel" id="cycle_actuel_oui" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Oui</span>
                                                                                </label>
                                                                                <label htmlFor="cycle_actuel_non">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Non" name="achevement_cycle_actuel" id="cycle_actuel_non" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Non</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showFormationSuivieDepuis && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">26</span>Depuis combien de temps suivez-vous cette formation ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-6 offset-md-3 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="vient_de_commencer">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je viens de la commencer" name="formation_suivie_depuis_combien_detemps" id="vient_de_commencer" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Je viens de la commencer</span>
                                                                                </label>
                                                                                <label htmlFor="depuis_1_an">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Depuis 1 an" name="formation_suivie_depuis_combien_detemps" id="depuis_1_an" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Depuis 1 an</span>
                                                                                </label>
                                                                                <label htmlFor="depuis_2_ans_et_plus">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Depuis 2 ans et +" name="formation_suivie_depuis_combien_detemps" id="depuis_2_ans_et_plus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Depuis 2 ans et +</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showPourquoiReorientation && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">27</span>Pourquoi vous souhaitez vous réorienter ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-10 offset-md-1 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="orientation_subie">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Orientation de départ subie ou non souhaitée" name="pourquoi_reorientation" id="orientation_subie" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Orientation de départ subie ou non souhaitée</span>
                                                                                </label>
                                                                                <label htmlFor="moins_long">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Préfère un cursus moins long et plus professionnalisant" name="pourquoi_reorientation" id="moins_long" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Préfère un cursus moins long et plus professionnalisant</span>
                                                                                </label>
                                                                                <label htmlFor="repond_pas_attentes">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Formation suivie qui ne répond pas à mes attentes (en termes de contenu, débouchés)" name="pourquoi_reorientation" id="repond_pas_attentes" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Formation suivie qui ne répond pas à mes attentes (en termes de contenu, débouchés)</span>
                                                                                </label>
                                                                                <label htmlFor="formation_difficile">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Formation trop difficile pour moi" name="pourquoi_reorientation" id="formation_difficile" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Formation trop difficile pour moi</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showProjetRecherche && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">28</span>Avez-vous défini votre projet de recherche / Professionnel ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-6 offset-md-3 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="projet_rediger">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Oui je l’ai rédigé" name="definition_projet_recherche_ouprofessionnel" id="projet_rediger" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Oui je l’ai rédigé</span>
                                                                                </label>
                                                                                <label htmlFor="projet_quelques_idees">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="J’ai quelques idées" name="definition_projet_recherche_ouprofessionnel" id="projet_quelques_idees" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">J’ai quelques idées</span>
                                                                                </label>
                                                                                <label htmlFor="vas_sy_mettre">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je vais m’y mettre" name="definition_projet_recherche_ouprofessionnel" id="vas_sy_mettre" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Je vais m’y mettre</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showProjetApresFormation && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">29</span>Quel est votre projet après la formation visée ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-6 offset-md-3 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <label htmlFor="poursuite_etude">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Poursuite d’études" name="projet_apres_formation" id="poursuite_etude" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Poursuite d’études</span>
                                                                                </label>
                                                                                <label htmlFor="insertion_domaine_pro">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Insertion dans le domaine pro" name="projet_apres_formation" id="insertion_domaine_pro" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Insertion dans le domaine pro</span>
                                                                                </label>
                                                                                <label htmlFor="pas_encore_decider">
                                                                                    <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne me suis pas encore décidé" name="projet_apres_formation" id="pas_encore_decider" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                    <span className="suggestion-label">Je ne me suis pas encore décidé</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="carousel-item">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">30</span>Vous souhaitez poursuivre vos études dans un établissement </h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="poursuivre_etablissement_prive">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Privé" name="etude_type_etablissement_fr" id="poursuivre_etablissement_prive" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Privé</span>
                                                                        </label>
                                                                        <label htmlFor="poursuivre_etablissement_public">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Public" name="etude_type_etablissement_fr" id="poursuivre_etablissement_public" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Public</span>
                                                                        </label>
                                                                        <label htmlFor="pas_encore_decider_a">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je n’ai pas encore décidé" name="etude_type_etablissement_fr" id="pas_encore_decider_a" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Je n’ai pas encore décidé</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item" id="part_4_title">
                                        <div className="ae-quizz-item-container">
                                            <div className="ae-quizz-item-content-body">
                                                <h2>PARTIE 4 : PROJET DE DEPART / DE VIE</h2>
                                                <div className="ae-quizz-title-infos-textual-container">
                                                    <div className="ae-quizz-title-infos-textual-content">
                                                        <p>
                                                            Cette partie du test consiste à situer vos connaissances et votre maitrise des différents coûts, De votre prise en charge financière.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="next-quizz-btn-container">
                                                <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                    Suivant
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        showCoutFormationOption1 && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">31</span>Selon vous, quel est le cout de la formation ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-6 offset-md-3 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <ul className="ae-quizz-reponse-option-ul">
                                                                                    <li>
                                                                                        <span className="ae-quizz-reponse-option-label">Option 1 : pour la licence</span>
                                                                                        <ul className="ae-quizz-reponse-option-ul-second-level">
                                                                                            <li>
                                                                                                <span className="ae-quizz-reponse-option-label-second-level">Universités Publiques</span>
                                                                                                <div className="ae-quizz-reponse-option-label-second-level-suggestion">
                                                                                                    <label htmlFor="public_licence_moins_1000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="- 1000 euros" name="licence_universite_publique_cout_formation" id="public_licence_moins_1000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">- 1000 euros</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_licence_entre_1000_2000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 1000 et 2000" name="licence_universite_publique_cout_formation" id="public_licence_entre_1000_2000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 1000 et 2000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_licence_entre_2000_3000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 2000 et 3000" name="licence_universite_publique_cout_formation" id="public_licence_entre_2000_3000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 2000 et 3000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_licence_entre_3000_4000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 3000 et 4000" name="licence_universite_publique_cout_formation" id="public_licence_entre_3000_4000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 3000 et 4000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_licence_4000euros_etplus">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4000 euros et plus" name="licence_universite_publique_cout_formation" id="public_licence_4000euros_etplus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">4000 euros et plus</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_licence_je_ne_sais_pas">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne sais pas" name="licence_universite_publique_cout_formation" id="public_licence_je_ne_sais_pas" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Je ne sais pas</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span className="ae-quizz-reponse-option-label-second-level">Universités Privées</span>
                                                                                                <div className="ae-quizz-reponse-option-label-second-level-suggestion">
                                                                                                    <label htmlFor="privee_licence_moins_1000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="- 1000 euros" name="licence_universite_privee_cout_formation" id="licence_moins_1000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">- 1000 euros</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_licence_entre_1000_2000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 1000 et 2000" name="licence_universite_privee_cout_formation" id="privee_licence_entre_1000_2000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 1000 et 2000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_licence_entre_2000_3000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 2000 et 3000" name="licence_universite_privee_cout_formation" id="privee_licence_entre_2000_3000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 2000 et 3000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_licence_entre_3000_4000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 3000 et 4000" name="licence_universite_privee_cout_formation" id="privee_licence_entre_3000_4000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 3000 et 4000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_licence_4000euros_etplus">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4000 euros et plus" name="licence_universite_privee_cout_formation" id="privee_licence_4000euros_etplus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">4000 euros et plus</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_licence_je_ne_sais_pas">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne sais pas" name="licence_universite_privee_cout_formation" id="privee_licence_je_ne_sais_pas" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Je ne sais pas</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        showCoutFormationOption2 && (
                                            <div className="carousel-item">
                                                <div className="ae-quizz-item-container">
                                                    <div className="counter-countdown-container">
                                                        <div className="counter-countdown-content">
                                                            <h2 style={{
                                                                color: counter <= 10 ? "red" : "#273375"
                                                            }}> { counter } </h2>
                                                        </div>
                                                    </div>
                                                    <div className="ae-quizz-item-content-body">
                                                        <div className="ae-quizz-item-row">
                                                            <div className="ae-quizz-item-question-container">
                                                                <h6 className="ae-quizz-item-question"><span className="question-numero">32</span>Selon vous, quel est le cout de la formation ?</h6>
                                                                <div className="ae-quizz-item-response-suggestion-container">
                                                                    <div className="row ae-quizz-item-response-suggestion-row">
                                                                        <div className="col-md-6 offset-md-3 ae-quizz-item-response-suggestion-col">
                                                                            <div className="ae-quizz-response-suggestion-item">
                                                                                <ul className="ae-quizz-reponse-option-ul">
                                                                                    <li>
                                                                                        <span className="ae-quizz-reponse-option-label">Option 2 : pour le master</span>
                                                                                        <ul className="ae-quizz-reponse-option-ul-second-level">
                                                                                            <li>
                                                                                                <span className="ae-quizz-reponse-option-label-second-level">Universités Publiques</span>
                                                                                                <div className="ae-quizz-reponse-option-label-second-level-suggestion">
                                                                                                    <label htmlFor="public_master_moins_1000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="- 1000 euros" name="master_universite_publique_cout_formation" id="public_master_moins_1000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">- 1000 euros</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_master_entre_1000_2000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 1000 et 2000" name="master_universite_publique_cout_formation" id="public_master_entre_1000_2000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 1000 et 2000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_master_entre_2000_3000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 2000 et 3000" name="master_universite_publique_cout_formation" id="public_master_entre_2000_3000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 2000 et 3000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_master_entre_3000_4000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 3000 et 4000" name="master_universite_publique_cout_formation" id="public_master_entre_3000_4000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 3000 et 4000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_master_4000euros_etplus">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4000 euros et plus" name="master_universite_publique_cout_formation" id="public_master_4000euros_etplus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">4000 euros et plus</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="public_master_je_ne_sais_pas">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne sais pas" name="master_universite_publique_cout_formation" id="public_master_je_ne_sais_pas" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Je ne sais pas</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span className="ae-quizz-reponse-option-label-second-level">Universités Privées</span>
                                                                                                <div className="ae-quizz-reponse-option-label-second-level-suggestion">
                                                                                                    <label htmlFor="privee_master_moins_1000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="- 1000 euros" name="master_universite_privee_cout_formation" id="master_moins_1000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">- 1000 euros</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_master_entre_1000_2000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 1000 et 2000" name="master_universite_privee_cout_formation" id="privee_master_entre_1000_2000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 1000 et 2000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_master_entre_2000_3000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 2000 et 3000" name="master_universite_privee_cout_formation" id="privee_master_entre_2000_3000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 2000 et 3000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_master_entre_3000_4000euros">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 3000 et 4000" name="master_universite_privee_cout_formation" id="privee_master_entre_3000_4000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Entre 3000 et 4000</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_master_4000euros_etplus">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="4000 euros et plus" name="master_universite_privee_cout_formation" id="privee_master_4000euros_etplus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">4000 euros et plus</span>
                                                                                                    </label>
                                                                                                    <label htmlFor="privee_master_je_ne_sais_pas">
                                                                                                        <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne sais pas" name="master_universite_privee_cout_formation" id="privee_master_je_ne_sais_pas" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                                        <span className="suggestion-label">Je ne sais pas</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="next-quizz-btn-container">
                                                                    <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                        Suivant
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="carousel-item" id="last_slide">
                                        <div className="ae-quizz-item-container">
                                            <div className="counter-countdown-container">
                                                <div className="counter-countdown-content">
                                                    <h2 style={{
                                                        color: counter <= 10 ? "red" : "#273375"
                                                    }}> { counter } </h2>
                                                </div>
                                            </div>
                                            <div className="ae-quizz-item-content-body">
                                                <div className="ae-quizz-item-row">
                                                    <div className="ae-quizz-item-question-container">
                                                        <h6 className="ae-quizz-item-question"><span className="question-numero">33</span>A combien estimez-vous les frais de vie pour une année d’études en France ? (Logement, déplacement, nourriture, santé…) </h6>
                                                        <div className="ae-quizz-item-response-suggestion-container">
                                                            <div className="row ae-quizz-item-response-suggestion-row">
                                                                <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                    <div className="ae-quizz-response-suggestion-item">
                                                                        <label htmlFor="cfv_moins_5000euros">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="– 5000 euros" name="frais_de_vie_annee_etude" id="cfv_moins_5000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">– 5000 euros</span>
                                                                        </label>
                                                                        <label htmlFor="cfv_entre_5000_7000euros">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 5000 et 7000" name="frais_de_vie_annee_etude" id="cfv_entre_5000_7000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Entre 5000 et 7000</span>
                                                                        </label>
                                                                        <label htmlFor="cfv_entre_7000_10000euros">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Entre 7000 et 10000" name="frais_de_vie_annee_etude" id="cfv_entre_7000_10000euros" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Entre 7000 et 10000</span>
                                                                        </label>
                                                                        <label htmlFor="cfv_10000euros_et_plus">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="10000 et plus" name="frais_de_vie_annee_etude" id="cfv_10000euros_et_plus" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">10000 et plus</span>
                                                                        </label>
                                                                        <label htmlFor="cfv_je_ne_sais_pas">
                                                                            <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Je ne sais pas" name="frais_de_vie_annee_etude" id="cfv_je_ne_sais_pas" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                            <span className="suggestion-label">Je ne sais pas</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="next-quizz-btn-container">
                                                            <a className="carousel-control-next" href="#auto-eval-quizz-carousel" role="button" data-slide="next">
                                                                Suivant
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        {(!showResult) ? (
                                            <div className="ae-quizz-item-container">
                                                <div className="counter-countdown-container">
                                                    <div className="counter-countdown-content">
                                                        <h2 style={{
                                                            color: counter <= 10 ? "red" : "#273375"
                                                        }}> { counter } </h2>
                                                    </div>
                                                </div>
                                                <div className="ae-quizz-item-content-body">
                                                    <div className="ae-quizz-item-row">
                                                        <div className="ae-quizz-item-question-container">
                                                            <h6 className="ae-quizz-item-question"><span className="question-numero">34</span>Comment comptez-vous financer vos études ? </h6>
                                                            <div className="ae-quizz-item-response-suggestion-container">
                                                                <div className="row ae-quizz-item-response-suggestion-row">
                                                                    <div className="col-md-4 offset-md-4 ae-quizz-item-response-suggestion-col">
                                                                        <div className="ae-quizz-response-suggestion-item">
                                                                            <label htmlFor="fe_parent_garant">
                                                                                <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Un parent/ Garant" name="financement_etude" id="fe_parent_garant" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                <span className="suggestion-label">Un parent/ Garant</span>
                                                                            </label>
                                                                            <label htmlFor="fond_perso">
                                                                                <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Fonds personnel" name="financement_etude" id="fond_perso" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                <span className="suggestion-label">Fonds personnel</span>
                                                                            </label>
                                                                            <label htmlFor="fin_bourse">
                                                                                <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Une bourse" name="financement_etude" id="fin_bourse" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                <span className="suggestion-label">Une bourse</span>
                                                                            </label>
                                                                            <label htmlFor="fin_travailler_fr">
                                                                                <input onChange={onHandleChangeProfilEtudiantResponse} type="radio" value="Travailler en France" name="financement_etude" id="fin_travailler_fr" className="ae-suggestion-radio-checkbox-form-control" /> &nbsp;
                                                                                <span className="suggestion-label">Travailler en France</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="next-quizz-btn-container">
                                                                {
                                                                    (sendResponseInProgress) ? (
                                                                        <button className="carousel-control-next">
                                                                            En Cours
                                                                        </button>
                                                                    ) : (!quizzIsDone) &&
                                                                    (
                                                                        <button type="button" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            onSubmitProfilEtudiantResponse();
                                                                        }} className="submit-btn">
                                                                            Envoyer
                                                                        </button>
                                                                    )
                                                                }
                                                                {
                                                                    quizzIsDone && (
                                                                        <button className="carousel-control-next nexty-last" data-target="#auto-eval-quizz-carousel" data-slide="next">
                                                                            Terminer
                                                                        </button>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {(score >= 65) && (
                                                    // <div className="carousel-item" id="drap_vert_slide">
                                                        <div className="ae-quizz-item-container">
                                                            <div className="ae-quizz-item-content-body">
                                                                <div className="row ae-quizz-resultat-item-row">
                                                                    <div className="col-md-4 ae-quizz-result-light-col">
                                                                        <div className="ae-quizz-result-light-image-container">
                                                                            <img src={LightVertFonce} alt="Drapeau Vert" className="quizz-result-image green" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8 ae-quizz-result-textual-col">
                                                                        <div className="ae-quizz-result-textual-container">
                                                                            <h2 className="fire-type">
                                                                                <span className="fire-label">Vous avez obtenu un </span>
                                                                                <span className="fire-color f-dark-green">Feu Vert Foncé</span>
                                                                            </h2>
                                                                            <h3>Cher(e) candidat(e)</h3>
                                                                            <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                            <div className="feedback-message-container">
                                                                                <p>
                                                                                    Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication.
                                                                                </p>
                                                                                <p>
                                                                                    Sur la base de vos déclarations, nous pensons que votre dossier est susceptible d’intéresser les établissements d’enseignement supérieur français. 
                                                                                </p>
                                                                                <p>
                                                                                    Un rapport détaillé, contenant des conseils et suggestions pour bonifier votre dossier, vient de vous être envoyé. 
                                                                                </p>
                                                                                <p>
                                                                                    Vous recevrez par la suite un lien personnel vous invitant, si vous le souhaitez, à prendre un <b>rendez-vous individuel d’orientation.</b>
                                                                                </p>
                                                                                <p className="reding-paragraph">
                                                                                    Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    // </div>
                                                )}

                                                {(score <= 64 && score >= 51) && (
                                                    // <div className="carousel-item" id="drap_vert_slide">
                                                        <div className="ae-quizz-item-container">
                                                            <div className="ae-quizz-item-content-body">
                                                                <div className="row ae-quizz-resultat-item-row">
                                                                    <div className="col-md-4 ae-quizz-result-light-col">
                                                                        <div className="ae-quizz-result-light-image-container">
                                                                            <img src={LightVertClair} alt="Drapeau Vert" className="quizz-result-image green" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8 ae-quizz-result-textual-col">
                                                                        <div className="ae-quizz-result-textual-container">
                                                                            <h2 className="fire-type">
                                                                                <span className="fire-label">Vous avez obtenu un </span>
                                                                                <span className="fire-color f-ligth-green">Feu Vert Clair</span>
                                                                            </h2>
                                                                            <h3>Cher(e) candidat(e)</h3>
                                                                            <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                            <div className="feedback-message-container">
                                                                                <p>
                                                                                    Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication.
                                                                                </p>
                                                                                <p>
                                                                                    Sur la base de vos déclarations, nous pensons que votre dossier est susceptible d’intéresser les établissements d’enseignement supérieur français. 
                                                                                </p>
                                                                                <p>
                                                                                    Un rapport détaillé, contenant des conseils et suggestions pour bonifier votre dossier, vient de vous être envoyé. 
                                                                                </p>
                                                                                <p>
                                                                                    Vous recevrez par la suite un lien individuel vous invitant, si vous le souhaitez, à prendre un rendez-vous à une <b>séance collective d’Aide à la construction du projet</b> dans nos bureaux.
                                                                                </p>
                                                                                <p className="reding-paragraph">
                                                                                    Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    // </div>
                                                )}

                                                {(score <= 50 && score >= 35) && (
                                                    // <div className="carousel-item" id="drap_orange_slide">
                                                        <div className="ae-quizz-item-container">
                                                            <div className="ae-quizz-item-content-body">
                                                                <div className="row ae-quizz-resultat-item-row">
                                                                    <div className="col-md-4 ae-quizz-result-light-col">
                                                                        <div className="ae-quizz-result-light-image-container">
                                                                            <img src={LightOrange} alt="Drapeau Orange" className="quizz-result-image orange" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8 ae-quizz-result-textual-col">
                                                                        <div className="ae-quizz-result-textual-container">
                                                                            <h2 className="fire-type">
                                                                                <span className="fire-label">Vous avez obtenu un </span>
                                                                                <span className="fire-color f-orange">Feu Orange</span>
                                                                            </h2>
                                                                            <h3>Cher(e) candidat(e)</h3>
                                                                            <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                            <div className="feedback-message-container">
                                                                                <p>
                                                                                    Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication.
                                                                                </p>
                                                                                <p>
                                                                                    Votre dossier contient des éléments qui fragilisent votre projet d’études en France. <b>Il doit être renforcé pour avoir plus de chances d’être accepté au sein d’un établissement d’enseignement supérieur français.</b>
                                                                                </p>
                                                                                <p>
                                                                                    Un rapport détaillé vient de vous être envoyé. Nous vous invitons à prendre le temps de bien le lire et suivre les conseils qui vous sont donnés.
                                                                                </p>
                                                                                <p className="reding-paragraph">
                                                                                    Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                                </p>
                                                                                <Link
                                                                                    className="btn talk-with-helper-btn"
                                                                                    to={{
                                                                                        pathname: "/",
                                                                                        state: { flag: "orange" }
                                                                                    }}
                                                                                >
                                                                                        Discuter avec un conseiller
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    // </div>
                                                )}

                                                {(score < 35) && (
                                                    // <div className="carousel-item" id="drap_rouge_slide">
                                                        <div className="ae-quizz-item-container">
                                                            <div className="ae-quizz-item-content-body">
                                                                <div className="row ae-quizz-resultat-item-row">
                                                                    <div className="col-md-4 ae-quizz-result-light-col">
                                                                        <div className="ae-quizz-result-light-image-container">
                                                                            <img src={LightRouge} alt="Drapeau Rouge" className="quizz-result-image orange" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-8 ae-quizz-result-textual-col">
                                                                        <div className="ae-quizz-result-textual-container">
                                                                            <h2 className="fire-type">
                                                                                <span className="fire-label">Vous avez obtenu un </span>
                                                                                <span className="fire-color f-red">Feu Rouge</span>
                                                                            </h2>
                                                                            <h3>Cher(e) candidat(e)</h3>
                                                                            <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                            <div className="feedback-message-container">
                                                                                <p>
                                                                                    Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication. Vous trouverez à la suite un rapport explicatif du résultat obtenu.
                                                                                </p>
                                                                                <p>
                                                                                    Vous avez obtenu un feu rouge qui ne veut pas dire que votre dossier est mauvais mais plutôt qu’en l’état il n’est pas assez solide et/ou valorisant pour vous garantir une acceptation au sein d’un établissement d’enseignement supérieur français.
                                                                                </p>
                                                                                <p>
                                                                                    Un rapport détaillé vient de vous être envoyé. Nous vous invitons à prendre le temps de bien le lire et suivre les conseils qui vous sont donnés.
                                                                                </p>
                                                                                <p className="reding-paragraph">
                                                                                    Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    // </div>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    {(score >= 65) && (
                                        <div className="carousel-item" id="drap_vert_slide">
                                            <div className="ae-quizz-item-container">
                                                <div className="ae-quizz-item-content-body">
                                                    <div className="row ae-quizz-resultat-item-row">
                                                        <div className="col-md-4 ae-quizz-result-light-col">
                                                            <div className="ae-quizz-result-light-image-container">
                                                                <img src={LightVertFonce} alt="Drapeau Vert" className="quizz-result-image green" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 ae-quizz-result-textual-col">
                                                            <div className="ae-quizz-result-textual-container">
                                                                <h2 className="fire-type">
                                                                    <span className="fire-label">Vous avez obtenu un </span>
                                                                    <span className="fire-color f-dark-green">Feu Vert Foncé</span>
                                                                </h2>
                                                                <h3>Cher(e) candidat(e)</h3>
                                                                <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                <div className="feedback-message-container">
                                                                    <p>
                                                                        Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication.
                                                                    </p>
                                                                    <p>
                                                                        Sur la base de vos déclarations, nous pensons que votre dossier est susceptible d’intéresser les établissements d’enseignement supérieur français. 
                                                                    </p>
                                                                    <p>
                                                                        Un rapport détaillé, contenant des conseils et suggestions pour bonifier votre dossier, vient de vous être envoyé. 
                                                                    </p>
                                                                    <p>
                                                                        Vous recevrez par la suite un lien personnel vous invitant, si vous le souhaitez, à prendre un <b>rendez-vous individuel d’orientation.</b>
                                                                    </p>
                                                                    <p className="reding-paragraph">
                                                                        Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {(score <= 64 && score >= 51) && (
                                        <div className="carousel-item" id="drap_vert_slide">
                                            <div className="ae-quizz-item-container">
                                                <div className="ae-quizz-item-content-body">
                                                    <div className="row ae-quizz-resultat-item-row">
                                                        <div className="col-md-4 ae-quizz-result-light-col">
                                                            <div className="ae-quizz-result-light-image-container">
                                                                <img src={LightVertClair} alt="Drapeau Vert" className="quizz-result-image green" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 ae-quizz-result-textual-col">
                                                            <div className="ae-quizz-result-textual-container">
                                                                <h2 className="fire-type">
                                                                    <span className="fire-label">Vous avez obtenu un </span>
                                                                    <span className="fire-color f-ligth-green">Feu Vert Clair</span>
                                                                </h2>
                                                                <h3>Cher(e) candidat(e)</h3>
                                                                <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                <div className="feedback-message-container">
                                                                    <p>
                                                                        Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication.
                                                                    </p>
                                                                    <p>
                                                                        Sur la base de vos déclarations, nous pensons que votre dossier est susceptible d’intéresser les établissements d’enseignement supérieur français. 
                                                                    </p>
                                                                    <p>
                                                                        Un rapport détaillé, contenant des conseils et suggestions pour bonifier votre dossier, vient de vous être envoyé. 
                                                                    </p>
                                                                    <p>
                                                                        Vous recevrez par la suite un lien individuel vous invitant, si vous le souhaitez, à prendre un rendez-vous à une <b>séance collective d’Aide à la construction du projet</b> dans nos bureaux.
                                                                    </p>
                                                                    <p className="reding-paragraph">
                                                                        Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {(score <= 50 && score >= 35) && (
                                        <div className="carousel-item" id="drap_orange_slide">
                                            <div className="ae-quizz-item-container">
                                                <div className="ae-quizz-item-content-body">
                                                    <div className="row ae-quizz-resultat-item-row">
                                                        <div className="col-md-4 ae-quizz-result-light-col">
                                                            <div className="ae-quizz-result-light-image-container">
                                                                <img src={LightOrange} alt="Drapeau Orange" className="quizz-result-image orange" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 ae-quizz-result-textual-col">
                                                            <div className="ae-quizz-result-textual-container">
                                                                <h2 className="fire-type">
                                                                    <span className="fire-label">Vous avez obtenu un </span>
                                                                    <span className="fire-color f-orange">Feu Orange</span>
                                                                </h2>
                                                                <h3>Cher(e) candidat(e)</h3>
                                                                <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                <div className="feedback-message-container">
                                                                    <p>
                                                                        Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication.
                                                                    </p>
                                                                    <p>
                                                                        Votre dossier contient des éléments qui fragilisent votre projet d’études en France. <b>Il doit être renforcé pour avoir plus de chances d’être accepté au sein d’un établissement d’enseignement supérieur français.</b>
                                                                    </p>
                                                                    <p>
                                                                        Un rapport détaillé vient de vous être envoyé. Nous vous invitons à prendre le temps de bien le lire et suivre les conseils qui vous sont donnés.
                                                                    </p>
                                                                    <p className="reding-paragraph">
                                                                        Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                    </p>
                                                                    <Link
                                                                        className="btn talk-with-helper-btn"
                                                                        to={{
                                                                            pathname: "/",
                                                                            state: { flag: "orange" }
                                                                        }}
                                                                    >
                                                                            Discuter avec un conseiller
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {(score < 35) && (
                                        <div className="carousel-item" id="drap_rouge_slide">
                                            <div className="ae-quizz-item-container">
                                                <div className="ae-quizz-item-content-body">
                                                    <div className="row ae-quizz-resultat-item-row">
                                                        <div className="col-md-4 ae-quizz-result-light-col">
                                                            <div className="ae-quizz-result-light-image-container">
                                                                <img src={LightRouge} alt="Drapeau Rouge" className="quizz-result-image orange" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8 ae-quizz-result-textual-col">
                                                            <div className="ae-quizz-result-textual-container">
                                                                <h2 className="fire-type">
                                                                    <span className="fire-label">Vous avez obtenu un </span>
                                                                    <span className="fire-color f-red">Feu Rouge</span>
                                                                </h2>
                                                                <h3>Cher(e) candidat(e)</h3>
                                                                <h4> <span>Retrouvez ici votre numéro de dossier : </span> { `${savedProfilEtudiant?.numero_dossier}` } </h4>
                                                                <div className="feedback-message-container">
                                                                    <p>
                                                                        Vous venez de terminer le test d’auto-évaluation de votre projet et nous vous félicitons de votre implication. Vous trouverez à la suite un rapport explicatif du résultat obtenu.
                                                                    </p>
                                                                    <p>
                                                                        Vous avez obtenu un feu rouge qui ne veut pas dire que votre dossier est mauvais mais plutôt qu’en l’état il n’est pas assez solide et/ou valorisant pour vous garantir une acceptation au sein d’un établissement d’enseignement supérieur français.
                                                                    </p>
                                                                    <p>
                                                                        Un rapport détaillé vient de vous être envoyé. Nous vous invitons à prendre le temps de bien le lire et suivre les conseils qui vous sont donnés.
                                                                    </p>
                                                                    <p className="reding-paragraph">
                                                                        Nous vous rappelons que Campus France a mis en place une liste de critères d’inéligibilités, assurez-vous de n’être dans aucun des cas listés.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FooterV2 />

            
        </div>
    )
}

export default AutoEvaluationQuizz;

export const RegionsAndDepartements = [
    {
        departement: "Dakar",
        region: "Dakar",
        antenne: "Dakar"
    },
    {
        departement: "Pikine",
        region: "Dakar",
        antenne: "Dakar"
    },
    {
        departement: "Guédiawaye",
        region: "Dakar",
        antenne: "Dakar"
    },
    {
        departement: "Keur Massar",
        region: "Dakar",
        antenne: "Dakar"
    },
    {
        departement: "Ziguinchor",
        region: "Ziguinchor",
        antenne: "Ziguinchor"
    },
    {
        departement: "Bignona",
        region: "Ziguinchor",
        antenne: "Ziguinchor"
    },
    {
        departement: "Oussouye",
        region: "Ziguinchor",
        antenne: "Ziguinchor"
    },
    {
        departement: "Diourbel",
        region: "Diourbel",
        antenne: "Kaolack"
    },
    {
        departement: "Mbacké",
        region: "Diourbel",
        antenne: "Kaolack"
    },
    {
        departement: "Bambey",
        region: "Diourbel",
        antenne: "Kaolack"
    },
    {
        departement: "Saint-Louis",
        region: "Saint-Louis",
        antenne: "Saint-Louis"
    },
    {
        departement: "Podor",
        region: "Saint-Louis",
        antenne: "Saint-Louis"
    },
    {
        departement: "Dagana",
        region: "Saint-Louis",
        antenne: "Saint-Louis"
    }, 
    {
        departement: "Tambacounda",
        region: "Tambacounda",
        antenne: "Ziguinchor"
    }, 
    {
        departement: "Bakel",
        region: "Tambacounda",
        antenne: "Ziguinchor"
    },
    {
        departement: "Goudiry",
        region: "Tambacounda",
        antenne: "Ziguinchor"
    },
    {
        departement: "Koumpentoum",
        region: "Tambacounda",
        antenne: "Ziguinchor"
    },
    {
        departement: "Kaolack",
        region: "Kaolack",
        antenne: "Kaolack"
    },
    {
        departement: "Nioro du Rip",
        region: "Kaolack",
        antenne: "Kaolack"
    },
    {
        departement: "Guinguinéo",
        region: "Kaolack",
        antenne: "Kaolack"
    },
    {
        departement: "Thiès",
        region: "Thiès",
        antenne: "Dakar"
    },
    {
        departement: "M'bour",
        region: "Thiès",
        antenne: "Dakar"
    },
    {
        departement: "Tivaouane",
        region: "Thiès",
        antenne: "Dakar"
    },
    {
        departement: "Louga",
        region: "Louga",
        antenne: "Saint-Louis"
    },
    {
        departement: "Linguère",
        region: "Louga",
        antenne: "Saint-Louis"
    },
    {
        departement: "Kébémer",
        region: "Louga",
        antenne: "Saint-Louis"
    },
    {
        departement: "Fatick",
        region: "Fatick",
        antenne: "Kaolack"
    },
    {
        departement: "Foundiougne",
        region: "Fatick",
        antenne: "Kaolack"
    },
    {
        departement: "Gossas",
        region: "Fatick",
        antenne: "Kaolack"
    },
    {
        departement: "Kolda",
        region: "Kolda",
        antenne: "Ziguinchor"
    },
    {
        departement: "Vélingara",
        region: "Kolda",
        antenne: "Ziguinchor"
    },
    {
        departement: "Médina Yoro Foulah",
        region: "Kolda",
        antenne: "Ziguinchor"
    },
    {
        departement: "Matam",
        region: "Matam",
        antenne: "Saint-Louis"
    },
    {
        departement: "Kanel",
        region: "Matam",
        antenne: "Saint-Louis"
    },
    {
        departement: "Ranérou",
        region: "Matam",
        antenne: "Saint-Louis"
    },
    {
        departement: "Kaffrine",
        region: "Kaffrine",
        antenne: "Saint-Louis"
    },
    {
        departement: "Birkelane",
        region: "Kaffrine",
        antenne: "Saint-Louis"
    },
    {
        departement: "Koungheul",
        region: "Kaffrine",
        antenne: "Saint-Louis"
    },
    {
        departement: "Malem-Hodar",
        region: "Kaffrine",
        antenne: "Saint-Louis"
    },
    {
        departement: "Kédougou",
        region: "Kédougou",
        antenne: "Ziguinchor"
    },
    {
        departement: "Salemata",
        region: "Kédougou",
        antenne: "Ziguinchor"
    },
    {
        departement: "Saraya",
        region: "Kédougou",
        antenne: "Ziguinchor"
    },
    {
        departement: "Sédhiou",
        region: "Sédhiou",
        antenne: "Ziguinchor"
    },
    {
        departement: "Bounkiling",
        region: "Sédhiou",
        antenne: "Ziguinchor"
    },
    {
        departement: "Goudomp",
        region: "Sédhiou",
        antenne: "Ziguinchor"
    },
]