import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import { useEffect, useState } from 'react';
import BackendBaseurl from '../../http/BackendBaseUrl';
import Skeleton from 'react-loading-skeleton';
import FeatherIcon from 'feather-icons-react';
import AEDecoImage from '../../img/v2/ae-btn-home-deco.svg';
import { Link } from 'react-router-dom';

const LandingPageCarouselV2 = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [sliders, setSliders] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [nowTimestamp, setNowTimestamp] = useState();

    const onGetSliders = () => {
        axios.get(ApiBaseUrl + 'active-sliders')
            .then(response => {
                setIsLoading(false);
                if(response.data.success){
                    setSliders(response.data.data);
                    if(response.data.count === 0){
                        setIsEmpty(true);
                    }
                    // console.log('data', response.data.data)
                }
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            })
    }
    useEffect(() => {
        setNowTimestamp(Math.floor(Date.now() / 1000))
        onGetSliders();
    }, []);

    console.log('nowTimestamp', nowTimestamp)


    return (
        <section id="v2-carousel-section">
            <div className="v2-carousel-container">
                {
                    isLoading ?
                    <Skeleton width={'100%'} height={'900px'} /> :
                    !isEmpty ?
                    <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        showStatus={false}
                    >
                        {
                            sliders?.map((slider, index) => {
                                return (
                                    <div className="mesp-carousel-item-container" key={'slider_' + index} style={{
                                        background: `url(${ BackendBaseurl + '/' + slider?.slider_image }) no-repeat center center fixed`,
                                        backgroundSize: 'cover'
                                    }}>
                                        <div className="row mesp-homepage-carousel-item-row">
                                            <div className="col-lg-6 mesp-homepage-carousel-item-col">
                                                <div className="mesp-carousel-item-legend-container">
                                                    <h1 className="mesp-carousel-item-legend-header">
                                                        { slider?.slider_primary_text }
                                                    </h1>
                                                    <h2 className="mesp-carousel-item-legend-slogan">
                                                        { slider?.slider_secondary_text }
                                                    </h2>
                                                    {(slider.action_btn_url !== null && slider.action_btn_url !== undefined) &&
                                                        // eslint-disable-next-line
                                                        <a href={slider?.action_btn_url} className="mesp-slide-item-action-btn" target="_blank" rel="noopener noreferrer"> Plus d'infos </a>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mesp-homepage-carousel-item-col mesp-ae-car-col">
                                                <div className='mesp-ae-home-btn-deco-container'>
                                                    {(nowTimestamp >= 1727740800) && (
                                                        <div className='mesp-ae-btn-deco-container'>
                                                            <img src={AEDecoImage} alt='Mon espace SN Campus France Sénégal' className='mesp-ae-btn-deco-image' />
                                                            <div className='mesp-ae-home-btn-textual-container'>
                                                                <h1 className='mesp-ae-home-intro-textual'>
                                                                    <span>Outil</span>&nbsp;
                                                                    <span>d'auto évaluation</span>
                                                                </h1>
                                                                <h2 className='mesp-ae-home-intro-textual-slogan'>
                                                                    Évaluez votre projet d'étude en France
                                                                </h2>
                                                                <Link to="/accueil-auto-evaluation" className="mesp-ae-home-new-btn">
                                                                    Commencer
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Carousel> :
                    <div className="container empty-card-container">
                        <div className="row empty-alert-row">
                            <div className="col-md-2 empty-icon-col">
                                <FeatherIcon
                                    icon="alert-circle"
                                    className="m-b-3 empty-alert-icon rotateIn"
                                />
                            </div>
                            <div className="col-md-10 empty-textual-col">
                                <p><strong>Aucune image n'est associée au Caroussel.</strong></p>
                            </div>
                        </div>
                    </div>
                }
                
            </div>

            
        </section>
    )
}

export default LandingPageCarouselV2