import React from 'react';
import LogoGroup from '../../img/v2/logo_group.png';
import UserIcon from '../../img/v2/icons/UserIcon.svg';

const LandingPageHeaderV2 = () => {
  return (
    <header>
        <nav className="v2 header-navbar navbar navbar-expand-lg">
            <div className="container-fluid v2-navbar-container">
                <div className="v2-navbar-body">
                    <div className="v2-logo-group-container">
                        <a className="v2-navbar-brand navbar-brand" href="/">
                            <img src={LogoGroup} className="logo_group_item" alt="Mon Espace Campus France SN" />
                        </a>
                    </div>
                    <div className="v2-user-login-btn-container">
                        <div className="v2-user-login-btn-item">
                            <a target="_blank" rel="noopener noreferrer" href="https://pastel.diplomatie.gouv.fr/etudesenfrance/dyn/public/login.html" className="btn btn-danger v2-user-login-btn" >
                                <span className="v2-user-login-contents">
                                    <span className="v2-user-login-icon-container">
                                        <img src={ UserIcon } className="login-user-icon" alt="Login User" />
                                    </span>
                                    <span className="v2-user-login-btn-label">
                                        Accéder à mon compte études en France
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
        </nav>
    </header>
  )
}

export default LandingPageHeaderV2;